import { fetchFromApi } from "./api";

export async function post_usage(event: string) {
  fetchFromApi(`/usage`, {
    method: 'POST',
    body: JSON.stringify({
      'event': event,
      'device_id': localStorage.getItem('cs2lens-deviceid'),
    }),
    headers: {
        'Content-Type': 'application/json'
    },
  });
}