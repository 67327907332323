import React, { useRef, useEffect, useState } from 'react';

interface SVGWeaponProps {
  item_path: string;
  size: number;
  color: number[];
  isPrimary: boolean;
}

const SVGWeapon: React.FC<SVGWeaponProps> = ({ item_path, size, color, isPrimary }) => {
  const [readyToRender, setReadyToRender] = useState(false);
  const pathRef = useRef<SVGPathElement | null>(null);

  useEffect(() => {
    if (pathRef.current) {
      const bbox = pathRef.current.getBBox();
      const translateX = -bbox.x;
      pathRef.current.setAttribute('transform', `translate(${translateX * size * (isPrimary ? 13.0 : 8.0)}, 0) scale(${size})`);
      setReadyToRender(true);
    }
  }, [isPrimary, item_path, size]);

  return (
    <path
      ref={pathRef}
      d={item_path}
      fill={`rgba(${color[0]}, ${color[1]}, ${color[2]}, ${readyToRender ? color[3] : 0})`}
    />
  );
}

export default SVGWeapon;
