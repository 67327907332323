import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import UpgradeInfo from '../components/UpgradeInfo';
import styles from './Info.module.css';

const CustomerInformation: React.FC = () => {
  return (
    <Container className={styles.container}>
      <Row style={{ marginBottom: 8 }}>
        <Col style={{ position: 'relative', left: '-8px' }}>
          <img src="/promo/cs2lens-logo-v2-icon-dark-transparent-128.png" style={{ width: 28, height: 28, marginLeft: '7px', marginRight: '6px' }} />
          <span className="logo-large logo-csgo">CS2</span>
          <span className="logo-large logo-lens">LENS</span>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <p className={styles.text}>CS2lens.com is a comprehensive Counter-Strike match analytics service.</p>
          <p className={styles.text}>Designed with coaches and players in mind, it enables in-depth analysis of matches to facilitate game strategy development and skill improvement.</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <h2 className={styles.subtitle}>Subscription</h2>
          <p className={styles.text}>The service is available in three tiers: Free, Premium, and Org.</p>
          <p className={styles.text}>Rounds 1-12 are always free for all users in every match. However, access to rounds 13 and beyond is only free after a 30-day waiting period. The Org tier offers unlimited seats for everyone in your organization, including academy teams.</p>
          <p className={styles.text}>For an <span className={styles.headerLabelOrg}><strong>organization</strong></span> license, please contact us at <a href="https://discord.gg/FdxpTYwtrV" target="_blank" rel="noreferrer">Discord</a> or <code>support@csgolens.com</code></p>
        </Col>
      </Row>
      <Row style={{ marginBottom: 30 }}>
        <UpgradeInfo showButton={false} maxWidth={800} showTerms={false} />
      </Row>
      <Row>
        <Col>
          <h2 className={styles.subtitle}>Payments & Cancellation</h2>
          <p className={styles.text}>
            Payment is required upfront at the start of each monthly period.
          </p>
          <p className={styles.text}>
            We use <a href="https://gumroad.com">Gumroad</a>, a secure and globally recognized payment service, to process all payments for our services.
            If you decide to cancel your subscription, it will remain active until the end
            of the current monthly period.
          </p>
          <p className={styles.text}>
            You can manage your subscription with the link provided in your latest invoice.
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <h2 className={styles.subtitle}>Company Information</h2>
          <p className={styles.text}>The cs2lens.com service is a product of Juhakiili Oy, a company registered and operating from Finland.</p>
          <p className={styles.text}>Juhakiili Oy<br />Välilausteentie 9, 21350 Ilmarinen, Finland<br />FIFPRO.2398119-8</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <h2 className={styles.subtitle}>Contact Information</h2>
          <p className={styles.text}>For any inquiries, please reach out to our <a href ="https://discord.gg/FdxpTYwtrV">Discord channel</a> or e-mail at <code>support@csgolens.com</code>. Our goal is to provide a prompt and satisfactory resolution to all queries.</p>
        </Col>
      </Row>
      <Row style={{marginBottom: 50}}>
        <Col>
          <h2 className={styles.subtitle}>Terms & Conditions</h2>
          <p className={styles.text}>Please refer to our <a href="/terms">Terms & Conditions</a> or our <a href="/policy.html">Privacy Policy</a>.</p>
        </Col>
      </Row>
    </Container>
  );
};

export default CustomerInformation;
