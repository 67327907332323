import { GrenadeData, PlayerFrameData } from "../utils/types";
import { useRef } from "react";
import { world_to_screen } from "../utils/math";
import { CachedProjectiles, Projectile } from "../utils/projectile";
import { FireInfo, SmokeInfo } from "./MapView";
import { mapInfoAnalyse } from "../utils/maps";

import { contourDensity } from 'd3-contour';
import { scaleSequential } from 'd3-scale';
import { interpolateInferno } from 'd3-scale-chromatic';
import { geoPath } from 'd3-geo';
import { geoTransform } from 'd3-geo';
import { rgb } from 'd3-color';

interface AnalyseHeatMapViewSVGProps {
  mapName: string,
  upperView: boolean,
  currentTick: number,
  currentTickRange: number[],
  playerFrames: PlayerFrameData[],
  projectiles: Projectile[],
  explosions: GrenadeData[],
  smokes: SmokeInfo[],
  fires: FireInfo[],
  smoothing: number,
}

const AnalyseHeatMapViewSVG = ({
  mapName,
  upperView,
  currentTick,
  currentTickRange,
  playerFrames,
  projectiles,
  explosions,
  smokes,
  fires,
  smoothing,
}: AnalyseHeatMapViewSVGProps) => {
  const svgRef = useRef<SVGSVGElement | null>(null);

  const playerDataPoints: [number, number][] = playerFrames
    .filter(frame => {
      if (!frame.isAlive) return false;
      const mInfo = mapInfoAnalyse[mapName as keyof typeof mapInfoAnalyse];
      const zOpacity = (frame.z - mInfo.minZ) / (mInfo.maxZ - mInfo.minZ);
      const opacity = Math.min(Math.max(upperView ? zOpacity : 1.0 - zOpacity, 0.05), 1.0);
      return opacity >= 0.5;
    })
    .map(frame => {
      const screenPixel = world_to_screen([frame.x, frame.y, frame.z], mapName);
      return [screenPixel[0] * 1.5, screenPixel[1] * 1.5] as [number, number];
    });

  // Generate the contours.
  // Generate the contours.
  const contours = contourDensity()
    .x(d => d[0])
    .y(d => d[1])
    .size([150, 150])
    .cellSize(1)
    .thresholds(24)
    .bandwidth(mapInfoAnalyse[mapName as keyof typeof mapInfoAnalyse].heatMapBandwidth * smoothing)
    (playerDataPoints);

  const color = scaleSequential(interpolateInferno)
    .domain([0, Math.max(...contours.map(d => d.value))]);

    const geoGenerator = geoPath().projection(
      geoTransform({
        point: function(x, y) {
          this.stream.point(x / 1.5, y / 1.5);
        }
      })
    );

  return (
    <svg className={`map-view-svg`} width="100%" height="100%" viewBox="0 0 100 100" ref={svgRef}>
      {contours.map((contour, i) => {
        // Generate the color as an RGB object.
        const colorObj = rgb(color(contour.value));

        // Get the maximum value from the contours.
        const maxValue = Math.max(...contours.map(d => d.value));

        // Compute the opacity using the square root function.
        // We then divide by the square root of the max value to normalize the values to between 0 and 1.
        colorObj.opacity = contour.value / maxValue;

        return (
          <path
            key={i}
            d={geoGenerator(contour) || undefined}
            fill={colorObj.toString()}  // Convert the RGB object back to a color string.
          />
        );
      })}
    </svg>
  );
};

export default AnalyseHeatMapViewSVG;