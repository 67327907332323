import { FrameData, RoundData } from "./types";

export function clean_url_name(name: string): string {
  return name.replace(/ /g, "-").toLowerCase().replace(/[^a-z0-9-]/gi, "");
}

export function team_names_equal(name1: string | undefined, name2: string | undefined): boolean {
  if (name1 === undefined || name2 === undefined) {
    return false;
  }

  const clean1 = clean_url_name(name1);
  const clean2 = clean_url_name(name2);

  return clean1.includes(clean2) || clean2.includes(clean1);
}

function pruneSet(set: Set<string>): Set<string> {
  let result: Set<string> = new Set();
  let map: { [key: string]: number } = {};

  set.forEach(str => {
    let prefix = "";
    for (let i = 0; i < str.length; i++) {
      prefix += str[i];
      if (map[prefix] == null) {
        map[prefix] = 1;
      } else {
        map[prefix]++;
      }
    }
  });

  set.forEach(str => {
    let prefix = "";
    for (let i = 0; i < str.length; i++) {
      prefix += str[i];
      if (map[prefix] == 1) {
        result.add(str);
        break;
      }
    }
  });

  return result;
}

function findRepeatingPrefixes(arr: string[]): Set<string> {
  const MIN_PREFIX_LENGTH = 2;
  const MIN_PREFIX_COUNT = 3;
  
  let prefixMap: { [key: string]: number } = {};
  let result: Set<string> = new Set();

  for (let i = 0; i < arr.length; i++) {
    let str = arr[i];
    for (let j = MIN_PREFIX_LENGTH; j <= str.length; j++) {
      let prefix = str.substring(0, j);
      if (prefixMap[prefix] == null) {
        prefixMap[prefix] = 1;
      } else {
        prefixMap[prefix]++;
        if (prefixMap[prefix] === MIN_PREFIX_COUNT) {
          result.add(prefix);
        }
      }
    }
  }

  return pruneSet(result);
}

export function generatePlayerShortNames(data: RoundData) {
  const plrs = data.frames[0].t.players.concat(data.frames[0].ct.players)
  const playerNames = plrs.map((p) => p.name);
  const prefixes = Array.from(findRepeatingPrefixes(playerNames));

  for(let frame of data.frames) {
    const players = frame.t.players.concat(frame.ct.players)
    for (let player of players) {
      let name = player.name;
      for (let prefix of prefixes) {
        if (name.startsWith(prefix)) {
          name = name.substring(prefix.length);
        }
      }
      player.shortName = name;
    }
  }
}

export function clearFaultyFrames(data: RoundData) {
  // Collect a set of frames to be removed
  let faultyFrames: Set<FrameData> = new Set();
  for(let frame of data.frames) {
    if(!frame.ct || !frame.t || !frame.ct.players || !frame.t.players) {
      faultyFrames.add(frame);
    }
  }
  //Remove the faulty frames
  data.frames = data.frames.filter((frame) => !faultyFrames.has(frame));
}

export function getShortPlayerName(playerName: string, allPlayerNames: string[]): string {
  // Find repeating prefixes in the array
  const prefixes = Array.from(findRepeatingPrefixes(allPlayerNames));

  // Shorten the player's name by removing common prefixes
  for (let prefix of prefixes) {
    if (playerName.startsWith(prefix)) {
      playerName = playerName.substring(prefix.length);
    }
  }

  return playerName;
}
