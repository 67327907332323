import { mapInfoAnalyse } from "../utils/maps";
import { GrenadeData, PlayerFrameData } from "../utils/types";
import { FireInfo, SmokeInfo } from "./MapView";
import { CachedProjectiles, Projectile } from "../utils/projectile";
import AnalyseHeatMapViewLayer from "./AnalyseHeatMapViewLayer";
import AnalyseGrenadesMapViewLayer from "./AnalyseGrenadesMapViewLayer";


interface AnalyseGrenadesMapViewProps {
  upperView: boolean,
  mapName: string,
  currentTick: number | undefined,
  currentTickRange: number[],
  playerFrames: PlayerFrameData[],
  players: number[],
  projectiles: Projectile[],
  projectileCache: CachedProjectiles,
  selectedProjectileCache: CachedProjectiles | undefined,
  hoveredProjectile: number | undefined,
  explosions: GrenadeData[],
  smokes: SmokeInfo[],
  fires: FireInfo[],
  rect: number[] | undefined,
  rectType: 'all' | 'landing' | undefined,
  rectChanged: (
    recordingRect: number[] | undefined,
    rectType: 'all' | 'landing' | undefined,
    dragStart: boolean,
    dragEnd: boolean,
  ) => void,
}

const AnalyseGrenadesMapView = ({
  upperView,
  mapName,
  currentTick,
  currentTickRange,
  playerFrames,
  players,
  projectiles,
  projectileCache,
  selectedProjectileCache,
  hoveredProjectile,
  explosions,
  smokes,
  fires,
  rect,
  rectType,
  rectChanged,
}: AnalyseGrenadesMapViewProps) => {
  const mapOffset = mapInfoAnalyse[mapName as keyof typeof mapInfoAnalyse].offset;
  const mapZoom = mapInfoAnalyse[mapName as keyof typeof mapInfoAnalyse].zoom;

  return (
    <AnalyseGrenadesMapViewLayer
      playerFrames={playerFrames}
      players={players}
      projectiles={projectiles}
      projectileCache={projectileCache}
      selectedProjectileCache={selectedProjectileCache}
      hoveredProjectile={hoveredProjectile}
      explosions={explosions}
      smokes={smokes}
      fires={fires}
      upperView={upperView}
      mapName={mapName}
      mapOffset={mapOffset}
      mapZoom={mapZoom}
      currentTick={currentTick}
      currentTickRange={currentTickRange}
      rect={rect}
      rectType={rectType}
      rectChanged={rectChanged}
    />
  );
}

export default AnalyseGrenadesMapView;