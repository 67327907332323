import React, { useEffect, useState } from 'react';
import { Table, Col, Container, Row, Button, Spinner, Modal, Form } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import CustomModalHeader from '../components/CustomModalHeader';
import { fetchFromApi } from '../utils/api';
import { cleanEmptyMatches, sortPlaylist } from '../utils/playlist';
import { Playlist, RoundMetadata, RoundsMetadata } from '../utils/types';
import styles from "./Playlists.module.css";

export interface PlaylistsState {
  [playlist_id: string]: StoredPlaylist;
}

export interface StoredPlaylist {
  playlist_id: string | undefined;
  name: string;
  owned_by_user: boolean;
  timestamp: number;
  items: Set<string>;
}

export interface OrgMember {
  user_id: string;
  email: string;
}
export interface AccountData {
  user_id: string;
  email: string;
  subscription: string;
  team: string;
  org_name: string;
  org_admin: boolean;
  org_members: OrgMember[];
}


const Playlists: React.FC = () => {
  const [account, setAccount] = useState<AccountData | undefined>(undefined);
  const [loadingPlaylists, setLoadingPlaylists] = useState(true);
  const [loadingRounds, setLoadingRounds] = useState<string | undefined>(undefined);
  const [playlists, setPlaylists] = useState<PlaylistsState>({});
  
  const [playlistToDelete, setPlaylistToDelete] = useState<string | null>(null);
  const [deletingPlaylist, setDeletingPlaylist] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [playlistToRename, setPlaylistToRename] = useState<StoredPlaylist | null>(null);
  const [renamingPlaylist, setRenamingPlaylist] = useState(false);
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [renamedPlaylistName, setRenamedPlaylistName] = useState<string>('');

  const navigate = useNavigate();

  function loadPlaylists() {
    setLoadingPlaylists(true);
    fetchFromApi('/playlist')
      .then((response) => response.json())
      .then((responseObject: StoredPlaylist[]) => {
        const playlistMap: PlaylistsState = responseObject.reduce<PlaylistsState>((acc, playlist) => {
          acc[playlist.playlist_id!] = {
            ...playlist,
            items: new Set(playlist.items)
          };
          return acc;
        }, {});
        setPlaylists(playlistMap);
        setLoadingPlaylists(false);
      });
  }

  function handleDeleteClick(playlistId: string) {
    setPlaylistToDelete(playlistId);
    setShowDeleteModal(true);
  }

  function handleReplayClick(storedPlaylist: StoredPlaylist) {
    setLoadingRounds(storedPlaylist.playlist_id);
    const url = '/playlist_rounds?playlist_id=' + storedPlaylist.playlist_id;
    fetchFromApi(url)
      .then((response) => response.json())
      .then((rounds: RoundsMetadata) => {
        const playlist: Playlist = {
          rounds: rounds,
          playlist_id: storedPlaylist.playlist_id,
          name: storedPlaylist.name,
          external: false,
        }
        const finalPlaylist = sortPlaylist(cleanEmptyMatches(playlist));
        const firstRound: RoundMetadata = Object.values(finalPlaylist.rounds)[0][0];
        sessionStorage.setItem('playlist', JSON.stringify(finalPlaylist));
        setLoadingRounds(undefined);
        if (firstRound) {
          navigate(`/match/${firstRound.match_id}/${firstRound.mapname}/${firstRound.roundnum}`);
        }
      });
  }

  function handleConfirmDelete() {
    if (playlistToDelete) {
      setDeletingPlaylist(true);
      // Call the API to delete the playlist (this assumes you have a delete function in your API utilities)
      fetchFromApi(`/playlist?playlist_id=${playlistToDelete}`, { method: 'DELETE' })
        .then(() => {
          loadPlaylists();
          setDeletingPlaylist(false);
          setShowDeleteModal(false);
        });
    }
  }

  function handleRenamePlaylist(playlist: StoredPlaylist | null, newName: string) {
    if (!playlist) {
      return;
    }
    if (playlist.playlist_id) {
      setRenamingPlaylist(true);
      const transformedPlaylist = {
        playlist_id: playlist.playlist_id,
        name: newName,
        items: Array.from(playlist.items)
      };
      fetchFromApi('/playlist', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(transformedPlaylist)
      })
        .then(() => {
          loadPlaylists();
          setRenamingPlaylist(false);
          setShowRenameModal(false);
        });
    }
  }

  function handlePlaylistClick(playlist: StoredPlaylist) {
    setPlaylistToRename(playlist);
    setRenamedPlaylistName(playlist.name);
    setShowRenameModal(true);
  }

  useEffect(() => {
    loadPlaylists();
  }, []);

  useEffect(() => {
    if (account === undefined) {
      fetchFromApi('/account')
        .then((response) => response.json() as Promise<AccountData>)
        .then((data) => {
          setAccount(data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [account]);

  const sortedPlaylists = Object.values(playlists).sort((a, b) => {
    if (a.owned_by_user === b.owned_by_user) {
      return b.timestamp - a.timestamp; // sorting by timestamp if owned_by_user property is the same
    }
    return a.owned_by_user ? -1 : 1; // sorting by owned_by_user property
  });

  return (
    <div className='container match-table-container' style={{ marginTop: '100px' }}>
      <Table className={styles.playlistTable}>
        <thead>
          <tr>
            <th style={{ width: '55%' }}>Playlist</th>
            <th>Last Modified</th>
            <th style={{ textAlign: 'center' }}>Rounds</th>
            <th style={{ minWidth: '60px' }}></th>
            <th style={{ minWidth: '30px', width: '30px' }}></th>
          </tr>
        </thead>
        <tbody>
          {loadingPlaylists && (
            <tr>
              <td colSpan={4} style={{ textAlign: 'center', padding: '20px', border: 'none' }}>
                <span><Spinner animation="border" /><span className="loading-label">Loading...</span></span>
              </td>
            </tr>
          )}
          {!loadingPlaylists && sortedPlaylists.length === 0 && (
            <tr>
              <td colSpan={4} style={{ textAlign: 'left', border: 'none' }}>
                <span>No playlists found.</span>
              </td>
            </tr>
          )}
          {!loadingPlaylists && sortedPlaylists.map((playlist) => (
            <tr key={playlist.playlist_id}>

              <td style={{ width: '55%', color: '#bbb' }}>
                <span className={styles.playlistName} onClick={() => handlePlaylistClick(playlist)}>
                  <i className="bi bi-star-fill" style={{ fontSize: 16, marginRight: '7px' }}></i>{playlist.name}
                  <i className={`bi bi-pencil ${styles.editPlaylistHoverIcon}`} style={{ color: '#888', marginLeft: '10px' }} />
                </span>
              </td>
              <td>
                {new Date(playlist.timestamp * 1000).toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })}
              </td>
              <td style={{ textAlign: 'center' }}>{playlist.items.size}</td>
              <td style={{ textAlign: 'right', minWidth: '60px' }}>
                <Button variant={'primary'} className="btn-sm" onClick={() => handleReplayClick(playlist)} disabled={playlist.items.size === 0}>
                  <span style={{ display: 'inline-flex' }}>
                    {loadingRounds !== playlist.playlist_id && <i className={"bi bi-play-fill"} style={{ fontSize: 14, marginRight: 7 }}></i>}
                    {loadingRounds === playlist.playlist_id && <Spinner animation="border" size="sm" style={{ marginRight: 7, width: 14, height: 14, position: 'relative', top: '3px' }} />}
                    Replay
                  </span>
                </Button>
              </td>
              <td style={{ textAlign: 'right', minWidth: '30px', width: '30px' }}>
                {(playlist.owned_by_user || account?.org_admin) && (
                  <Button variant={'danger'} className="btn-sm" onClick={() => handleDeleteClick(playlist.playlist_id!)}>
                    <span style={{ display: 'inline-flex' }}><i className="bi bi-trash" style={{ fontSize: 14 }}></i></span>
                  </Button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <CustomModalHeader />
        <Modal.Body>
          Are you sure you want to remove playlist "{playlists[playlistToDelete!]?.name}"?
        </Modal.Body>
        <Modal.Footer>
          {deletingPlaylist && <Spinner animation="border" size="sm" style={{ marginRight: 10 }} />}
          <Button variant="danger" onClick={handleConfirmDelete}>
            Remove
          </Button>
          <Button variant="outline-secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Renaming Modal */}
      <Modal show={showRenameModal} onHide={() => setShowDeleteModal(false)}>
          <div onKeyDown={(e) => e.stopPropagation()}>
            <CustomModalHeader />
            <Modal.Body>
              <Row>
                <Col sm={2} className="d-flex align-items-center">
                  <Form.Label column sm={3} style={{ whiteSpace: 'nowrap', fontWeight: 'bold', marginTop: 0, marginBottom: 0 }}>
                    Name
                  </Form.Label>
                </Col>
                <Col sm={10} className="d-flex align-items-center">
                  <Form.Control
                    min={1}
                    max={64}
                    value={renamedPlaylistName}
                    placeholder="E-mail of an existing user"
                    autoFocus={true}
                    onChange={(e) => {
                      setRenamedPlaylistName(e.target.value);
                    }}
                  />
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              {renamingPlaylist && <Spinner animation="border" size="sm" style={{ marginRight: 10 }} />}
              <Button variant="success" onClick={() => handleRenamePlaylist(playlistToRename, renamedPlaylistName)} disabled={renamingPlaylist || renamedPlaylistName.length < 1}>
                Rename
              </Button>
              <Button variant="outline-secondary" onClick={() => setShowRenameModal(false)}>
                Cancel
              </Button>
            </Modal.Footer>
          </div>
        </Modal>
    </div>
  );
};

export default Playlists;
