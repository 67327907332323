import React, { useState, useEffect } from "react";
import { Form, Dropdown } from "react-bootstrap";
import { EventData, EventsData } from "../pages/Matches";
import { fetchFromApi } from "../utils/api";
import SourceIcon from "./SourceIcon";


interface EventNameInputProps {
  className?: string;
  userEventsOnly: boolean;
  onChange?: (value: string) => void;
}

const EventNameInput: React.FC<EventNameInputProps> = ({ className, userEventsOnly, onChange }) => {
  const [eventName, setEventName] = useState("");
  const [events, setEvents] = useState<EventsData>(JSON.parse(sessionStorage.getItem('teamscache') || '{}'));
  const [suggestions, setSuggestions] = useState<EventsData>({});
  const [showDropdown, setShowDropdown] = useState(false);
  const [userTyped, setUserTyped] = useState(false);

  useEffect(() => {
    const url = `/events`;
    fetchFromApi(url)
      .then((response) => response.json())
      .then((data) => {
        //If userEventsOnly is true, filter out events that doesn't hase 'user-upload' in their key
        if(userEventsOnly) {
          const filteredData: EventsData = {};
          for(let eventKey in data) {
            if(eventKey.includes('user-upload')) {
              filteredData[eventKey] = data[eventKey];
            }
          }
          setEvents(filteredData);
        } else {
          setEvents(data);
        }
      })
      .catch((err) => {
        console.error(err.message);
      });
  }, []);

  useEffect(() => {
    if (eventName && userTyped) {
      const newSuggestions: EventsData = {};

      for (let eventKey in events) {
        if (events[eventKey].name.toLowerCase().startsWith(eventName.toLowerCase())) {
          newSuggestions[eventKey] = events[eventKey];
        }
      }

      setSuggestions(newSuggestions);
      setShowDropdown(true);
    } else {
      setSuggestions({});
      setShowDropdown(false);
    }
  }, [eventName, events, userTyped]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEventName(value);
    setUserTyped(true);

    if(onChange) {
      onChange(value);
    }
  };

  const handleSelect = (teamKey: string) => {
    const value = events[teamKey].name;
    setEventName(value);
    setShowDropdown(false);
    setUserTyped(false);

    if(onChange) {
      onChange(value);
    }
  };

  return (
    <Dropdown onToggle={() => {}} show={showDropdown && Object.keys(suggestions).length > 0}>
      <Form.Control 
        type="text" 
        onChange={handleChange} 
        value={eventName}
        className={className}
      />
      <Dropdown.Menu>
        {Object.keys(suggestions).map((eventKey) => (
            <Dropdown.Item key={`team_${eventKey}`} eventKey={eventKey} onClick={() => handleSelect(eventKey)}>
            <><SourceIcon size={14} eventKey={eventKey} /><span>{eventKey in events ? events[eventKey].name : ''}</span></>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default EventNameInput;