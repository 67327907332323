import { useEffect, useState } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { getHasSubscription } from '../utils/premium';
import styles from './FooterButton.module.css';
import UpgradeInfoModal from './UpgradeInfoModal';

type RecordingButtonProps = {
  isActive: boolean;
  isRecording: boolean;
  onToggle: () => void;
};

export default function RecordingButton(props: RecordingButtonProps): JSX.Element {
  const { isActive, onToggle } = props;
  const [premium, setPremium] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const checkPremium = async () => {
      const premium = await getHasSubscription();
      setPremium(premium);
    }
    setShowModal(false);
    checkPremium();
  }, []);
  const handleKeyDown = (event: { preventDefault: () => void }) => {
    event.preventDefault();
  };

  const buttonClasses = isActive
    ? `${styles.button} ${styles.buttonActivated}`
    : styles.button;

  return (
    <>
      <OverlayTrigger
        placement="top"
        delay={{ show: 300, hide: 0 }}
        overlay={
          <Tooltip id={`tooltip-record-gif`}>
            Record GIF
          </Tooltip>
        }
      >
        <Button
          variant={isActive ? 'secondary' : 'outline-secondary'}
          size="sm"
          className={buttonClasses}
          onClick={() => {
            if (premium) {
              onToggle();
            } else {
              setShowModal(true);
            }
          }}
          onKeyDown={handleKeyDown}
        >
          <i className="bi bi-image"></i>
        </Button>
      </OverlayTrigger>

      <UpgradeInfoModal show={showModal} handleCloseModal={() => setShowModal(false)} />
    </>
  );
}
