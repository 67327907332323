import React, { CSSProperties, useEffect, useRef, useState } from "react";
import { RoundData } from "../utils/types";
import { Timer } from "./Timer";
import styles from './Clock.module.css';

export const Clock = ({
  csVersion,
  bombPlantTick,
  startTick,
  currentTick,
  areaClock,
  area,
}: {
  csVersion: string,
  bombPlantTick: number | undefined,
  startTick: number,
  currentTick: number | undefined,
  areaClock: boolean,
  area: number[] | undefined,
}) => {
  const parentRef = useRef<HTMLDivElement>(null);
  const [size, setSize] = useState<number>(0);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        if (entry.contentBoxSize) {
          const { inlineSize, blockSize } = entry.contentBoxSize[0] || entry.contentBoxSize;
          const minSize = Math.min(inlineSize, blockSize);
          setSize(minSize);
        } else {
          const { width, height } = entry.contentRect;
          const minSize = Math.min(width, height);
          setSize(minSize);
        }
      }
    });

    if (parentRef.current) {
      resizeObserver.observe(parentRef.current);
    }

    return () => {
      if (parentRef.current) {
        resizeObserver.unobserve(parentRef.current);
      }
    };
  }, [parentRef]);

  const parentStyle: CSSProperties = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 2,
    pointerEvents: 'none',
  };
  const containerStyle: CSSProperties = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: size,
    height: size,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const inlineStyles: CSSProperties = area ? {
    position: 'absolute',
    left: `${area[0]}%`,
    top: `${area[1]}%`,
    width: `${area[2] - area[0]}%`,
    height: `${area[3] - area[1]}%`,
  } : {};

  return areaClock ? (
    <div ref={parentRef} style={parentStyle}>
      <div style={containerStyle}>
        <div style={inlineStyles}>
          <div
            className={`${styles.centered} ${styles.clock}`}
          >
            <Timer
              startTick={startTick}
              currentTick={currentTick || 0}
              bombPlantedTick={bombPlantTick}
              tickRate={csVersion === "cs2" ? 64 : 128}
            />
          </div>
        </div>
      </div>
    </div>
  ) : <div className={`${styles.topview} ${styles.clock}`}>
    <Timer
      startTick={startTick}
      currentTick={currentTick || 0}
      bombPlantedTick={bombPlantTick}
      tickRate={csVersion === "cs2" ? 64 : 128}
    />
  </div>;
};

export default Clock;
