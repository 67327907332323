import TeamIcon from "./TeamIcon";

const AnalyseTeamInfo = ({ teamKey, teamName, teamBuy, flag }: {
  teamKey: string,
  teamName: string,
  teamBuy: string,
  flag: string,
}) => {
  return <div className={`info team-info rounded shadow`}>
    <div className={'team-info-wrapper mx-1 my-auto'}>
      <div className="flex-grow-1">
        <TeamIcon size={20} name={teamKey} flag={flag} />
        {teamName.toUpperCase()}
        <i className="bi bi-search team-info-hover-icon" style={{ color: '#888', marginLeft: '10px' }}></i>
      </div>
      <div style={{color: '#888'}}>
        {teamBuy}
      </div>
    </div>
  </div>
}

export default AnalyseTeamInfo;