import { Button, OverlayTrigger, ToggleButton, ToggleButtonGroup, Tooltip } from "react-bootstrap";
import MapIcon from "./MapIcon";
import styles from './FooterButton.module.css';

const MapPicker = ({maps, selectedMapName, inRoundPicker, setSelectedMap} : {
  maps: string[],
  selectedMapName: string,
  inRoundPicker: boolean,
  setSelectedMap: (mapName: string) => void,
  }) => {

  function cleanMapName(name: string): string {
    return capitalizeFirstLetter(name.replace("de_", " "));
  }

  function capitalizeFirstLetter(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  const handleKeyDown = (event: { preventDefault: () => void; }) => {
    event.preventDefault();
  };

  return (
    <>
      {maps.map((value, index) =>
        <OverlayTrigger
          key={value}
          placement="top"
          overlay={
            <Tooltip id={`tooltip-${value}`}>
              {cleanMapName(value)}  {/* Displaying the cleaned map name as the tooltip */}
            </Tooltip>
          }
        >
          <Button 
            id={value}
            onKeyDown={handleKeyDown}
            value={value}
            variant={value === selectedMapName ? 'secondary' : 'outline-secondary'}
            size="sm"
            className={value === selectedMapName ? `${styles.button} ${styles.buttonActivated}` : styles.button}
            style={{ borderRadius: inRoundPicker ? '0px' : '2px' }}
            onClick={() => setSelectedMap(value)}
          >
            <MapIcon 
              name={value}
              size={18}
              margin={0}
            />
          </Button>
        </OverlayTrigger>
      )}
    </>
  );
  
};

export default MapPicker;