// terms.tsx
import React from 'react';
import { Container } from 'react-bootstrap';
import styles from './Terms.module.css';

const Terms = () => {
  return (
    <Container className={styles.termsContainer}>
      <h1 className={styles.title}>Terms and Conditions</h1>
      <p>
      Effective date: June 28, 2023
      </p>
      <p>
      These Terms of Service ("Terms") govern your access to and use of CSGOlens.com ("Service"), a comprehensive Counter-Strike match analytics service provided by Juhakiili Oy ("Company" or "we"), registered in Finland at Välilausteentie 9, 21350 Ilmarinen, FIFPRO.2398119-8.
      </p>
      <p>
      Please read these Terms carefully. By accessing or using the Service, you agree to be bound by these Terms, our <a href="/policy.html">Privacy Policy</a>, and any amendments to either. If you do not agree to these Terms, you must not use the Service.
      </p>
      <h2 className={styles.subtitle}>1. CSGOLens.com Account</h2>
      <p>To use our premium features, you must create a CSGOlens.com account ("Account"). By creating an Account, you agree that all provided information is accurate, current, and complete. You also agree to promptly notify us of any changes to your information. It's your responsibility to keep your Account information up to date. You agree that your use of the Service will comply with all applicable laws and regulations. Each user account is personal, and the sharing of login credentials is not permitted.</p>

      <h2 className={styles.subtitle}>2. Service Updates</h2>
      <p>The Service may be updated periodically, including the addition of new features. We cannot guarantee that these updates will be compatible with your existing Account. New features may apply to other product categories or newly launched products. Temporary beta-access may be granted for new features. You may switch or change subscriptions to access new features, but if you choose to downgrade, no refunds will be given for any remaining time on your previous subscription. If you decide to cancel your premium subscription, it will remain active until the end of the current monthly period.</p>

      <h2 className={styles.subtitle}>3. Copyright</h2>
      <p>You may not reproduce, communicate, or use any of the content on our website without written permission from us. The Service is intended for use by professional teams and for educational purposes, but you are not permitted to sell or commercialise any content from the Service.</p>

      <h2 className={styles.subtitle}>4. Subscription and Cancellation</h2>
      <p>Your subscription will be automatically billed at the beginning of each monthly period until you cancel. By agreeing to these Terms, you authorize us to charge your payment method for the next subscription term. You may cancel your subscription at any time, but we do not offer refunds for any remaining period. If you decide to cancel your subscription, it will remain active until the end of the current monthly period. If your Account becomes inactive, we reserve the right to delete your data.</p>

      <h2 className={styles.subtitle}>5. Fair Use and Abuse</h2>
      <p>You must use our Service in a way that does not disrupt the service for other users. If you do not adhere to our fair-use policy, we may terminate your Account.</p>

      <h2 className={styles.subtitle}>6. Passwords and Account Access</h2>
      <p>You are responsible for maintaining the security of your Account information, including your username and password. We may terminate your Account or place it on hold to protect you, or ourselves, from abuse.</p>

      <h2 className={styles.subtitle}>7. Exclusion of Warranties</h2>
      <p>The Service is provided "as is", without any warranties. Your use of the Service is at your own risk. We do not guarantee that the Service will meet your needs, or that its operation will be uninterrupted or error-free.</p>

      <h2 className={styles.subtitle}>8. Indemnification</h2>
      <p>You agree to indemnify and hold us harmless from any liability or costs related to your use of the Service or your breach of these Terms.</p>
    </Container>
  );
}

export default Terms;
