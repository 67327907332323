import React from 'react';
import { Modal } from 'react-bootstrap';

// Define props interface to include showCloseButton
interface CustomModalHeaderProps {
  hideCloseButton?: boolean;
}

const CustomModalHeader: React.FC<CustomModalHeaderProps> = ({ hideCloseButton = true }) => {
  return (
    <Modal.Header closeButton={!hideCloseButton} style={{ backgroundColor: '#222' }}>
      <Modal.Title>
        <div className="flex-grow-1" style={{ position: 'relative', top: '-3px' }}>
          <img src="/promo/cs2lens-logo-v2-icon-dark-transparent-128.png" style={{ width: 22, height: 22, marginRight: '4px', position: 'relative', top: '2px' }} />
          <span className="logo logo-csgo">CS2</span>
          <span className="logo logo-lens">LENS</span>
        </div>
      </Modal.Title>
    </Modal.Header>
  );
};

export default CustomModalHeader;
