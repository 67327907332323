import { DamageData, GrenadeData, KillData, PlayerFrameData, WeaponFireData } from "../utils/types";
import { sortPlayers, sortPlayersLower } from "./MapViewLayer";
import { useEffect, useRef, useState } from "react";
import { world_to_screen } from "../utils/math";
import AnalyseSVGPlayer from "./AnalyseSVGPlayer";
import { getExplosionTick, Projectile } from "../utils/projectile";
import { FireInfo, SmokeInfo } from "./MapView";
import { SVGProjectile } from "./SVGProjectile";
import { SVGSmoke } from "./SVGSmoke";
import { mapInfoAnalyse } from "../utils/maps";
import { SVGFire } from "./SVGFire";
import { MatchRound } from "./Analyse";
import SVGPlayer from "./SVGPlayer";
import SVGPlayerDetailed from "./SVGPlayerDetailed";
import AnalyseSVGKill from "./AnalyseSVGKill";
import AnalyseSVGDeath from "./AnalyseSVGDeath";
import AnalyseRectGizmo from "./AnalyseRectGizmo";


interface AnalyseMapViewSVGProps {
  mapName: string,
  upperView: boolean,
  currentTick: number,
  playerFrames: PlayerFrameData[],
  projectiles: Projectile[],
  explosions: GrenadeData[],
  smokes: SmokeInfo[],
  fires: FireInfo[],
  weaponFires: WeaponFireData[],
  bullets: DamageData[],
  damages: DamageData[],
  kills: KillData[],
  deaths: KillData[],
  bomb: { x: number, y: number, z: number } | null,
  bombPlanted: boolean,
  bombTimeLeft: number,
  bombDefusing: boolean,
  activePlayerSteamId: number | undefined,
  hoveredPlayerSteamId: number | undefined,
  activeRound: MatchRound | undefined,
  hoveredRound: MatchRound | undefined,
  mapZoom: number,
  playerColors: Map<number, string>,
  csVersion: string | undefined,
  onPlayerBeginHover: (matchID: string, mapName: string, roundNum: number, steamID: number) => void,
  onPlayerEndHover: (matchID: string, mapName: string, roundNum: number, steamID: number) => void,
  onPlayerClick: (matchID: string, mapName: string, roundNum: number, steamID: number) => void,
  rect: number[] | undefined,
  rectType: 'all' | 'landing' | undefined,
  rectChanged: (
    recordingRect: number[] | undefined,
    rectType: 'all' | 'landing' | undefined,
    dragStart: boolean,
    dragEnd: boolean,
  ) => void,
}

function playerInsideRect(rect: number[], player: PlayerFrameData, mapName: string): boolean {
  const screenPos = world_to_screen(
    [player.x, player.y, player.z],
    mapName,
  );
  if (screenPos) {
    const [x, y] = screenPos;
    return x >= rect[0] && x <= rect[2] && y >= rect[1] && y <= rect[3];
  }
  return false;
}

const AnalyseMapViewSVG = ({
  mapName,
  upperView,
  currentTick,
  playerFrames,
  projectiles,
  explosions,
  smokes,
  fires,
  weaponFires,
  bullets,
  damages,
  kills,
  deaths,
  bomb,
  bombPlanted,
  bombTimeLeft,
  bombDefusing,
  activePlayerSteamId,
  hoveredPlayerSteamId,
  activeRound,
  hoveredRound,
  mapZoom,
  playerColors,
  csVersion,
  onPlayerBeginHover,
  onPlayerEndHover,
  onPlayerClick,
  rect,
  rectType,
  rectChanged,
}: AnalyseMapViewSVGProps) => {
  const playerSorter = upperView ? sortPlayers : sortPlayersLower;
  const [scaleFactor, setScaleFactor] = useState(10);
  const [marqueeRect, setMarqueeRect] = useState<number[] | undefined>(undefined);
  const svgRef = useRef<SVGSVGElement | null>(null);

  useEffect(() => {
    const updateScaleFactor = () => {
      if (svgRef.current) {
        const svgWidth = svgRef.current.clientWidth;
        const svgHeight = svgRef.current.clientHeight;
        const viewBoxSize = 100;
        const newScaleFactor = Math.min(Math.max(Math.min(svgWidth, svgHeight) / (viewBoxSize * (1.0 / mapZoom)), 6.0), 15.0);
        setScaleFactor(newScaleFactor);
      }
    };

    updateScaleFactor();
    window.addEventListener('resize', updateScaleFactor);

    return () => {
      window.removeEventListener('resize', updateScaleFactor);
    };
  }, [mapName]);

  const rectGizmo = !activeRound ? <AnalyseRectGizmo
    key={'rect_gizmo'}
    rect={rect}
    rectChanged={(
      recordingRect: number[] | undefined,
      rectType: 'all' | 'landing' | undefined,
      dragStart: boolean,
      dragEnd: boolean,
    ) => {
      if (recordingRect !== undefined && !dragEnd) {
        const MIN_SIZE = 0.5;
        const width = Math.abs(recordingRect[2] - recordingRect[0]);
        const height = Math.abs(recordingRect[3] - recordingRect[1]);
        if (width > MIN_SIZE && height > MIN_SIZE) {
          setMarqueeRect(recordingRect);
        }
      } else {
        setMarqueeRect(undefined);
      }
      rectChanged(recordingRect, rectType, dragStart, dragEnd);
    }}
  /> : undefined;

  const svgPlayers = <svg key="playerframes">{playerFrames
    .sort((a, b) => {
      const aHovered = hoveredRound?.matchId === a.match_id && hoveredRound?.roundNum === a.roundNum;
      const bHovered = hoveredRound?.matchId === b.match_id && hoveredRound?.roundNum === b.roundNum;
      if (aHovered === bHovered) {
        return 0;
      } else if (aHovered && !bHovered) {
        return 1;
      } else {
        return -1;
      }
    })
    .filter((p) => p.isAlive)
    .map((p, i) =>
      <svg
        key={`svg_player_wrapper_${p.steamID}_${p.match_id}_${p.roundNum}`}
      >
        <AnalyseSVGPlayer
          key={`svg_player_${p.steamID}_${p.match_id}_${p.roundNum}`}
          data={p}
          scaleFactor={scaleFactor}
          currentTick={currentTick}
          mapName={mapName}
          upperView={upperView}
          invisible={false}
          playerColor={playerColors.get(p.steamID) ?? '#FFFFFF'}
          activePlayer={activeRound !== undefined && p.steamID === activePlayerSteamId}
          hoveredRound={activeRound === undefined && marqueeRect === undefined && hoveredRound?.matchId === p.match_id && hoveredRound?.roundNum === p.roundNum}
          marqueedPlayer={activeRound === undefined && marqueeRect !== undefined && playerInsideRect(marqueeRect, p, mapName)}
          nonMarqueedPlayer={activeRound === undefined && marqueeRect !== undefined && !playerInsideRect(marqueeRect, p, mapName)}
        />
      </svg>
    )}</svg>;

  return (
    <svg className={`map-view-svg`} width="100%" height="100%" viewBox="0 0 100 100" ref={svgRef}>
      {bullets.map((d, i) => {
        const t = 1.0 - (d.tick - currentTick) * 50 / d.distance;
        const x = d.victimX * t + d.attackerX * (1 - t);
        const y = d.victimY * t + d.attackerY * (1 - t);
        const z = d.victimZ * t + d.attackerZ * (1 - t);
        const position = world_to_screen([x, y, z], mapName);
        const angle = Math.atan2(d.attackerY - d.victimY, d.victimX - d.attackerX) * 180 / Math.PI;

        return (
          <svg key={`bullet_${d.tick}_${d.attackerSteamID}_${d.victimSteamID}`}>
            <defs>
              <radialGradient id={`bullet_gradient_${d.tick}_${d.attackerSteamID}_${d.victimSteamID}`} cx="10%" cy="50%" r="50%" fx="50%" fy="50%">
                <stop offset="0%" style={{ stopColor: `rgb(255,192,128)`, stopOpacity: 0.8 }} />
                <stop offset="10%" style={{ stopColor: `rgb(255,192,128)`, stopOpacity: 0.5 }} />
                <stop offset="20%" style={{ stopColor: `rgb(255,192,128)`, stopOpacity: 0.1 }} />
                <stop offset="50%" style={{ stopColor: `rgb(255,192,128)`, stopOpacity: 0.03 }} />
                <stop offset="100%" style={{ stopColor: `rgb(255,192,128)`, stopOpacity: 0.0 }} />
              </radialGradient>
            </defs>
            <ellipse
              key={`bullet_ellipse_${d.tick}_${d.attackerSteamID}_${d.victimSteamID}`}
              transform={`translate(${position[0]}, ${position[1]}) rotate(${angle}, 0, 0)`}
              rx={12.0 * t + 1.0}
              ry={0.5}
              fill={`url(#bullet_gradient_${d.tick}_${d.attackerSteamID}_${d.victimSteamID})`}
            />
          </svg>
        )
      })}
      {fires.map((fire, i) =>
        <SVGFire
          key={`fire_${i}`}
          data={fire}
          mapName={mapName}
          currentTick={currentTick}
          upperView={upperView}
          scaleFactor={scaleFactor}
          otherLevelOpacity={0.05}
          csVersion={csVersion}
        />
      )}
      {kills.map((p, i) =>
        <svg
          key={`svg_player_kill_${p.matchId}_${p.roundNum}_${p.attackerSteamID}_${p.victimSteamID}_${p.tick}`}
        >
          <AnalyseSVGKill
            key={`svg_player_kill_component_${p.matchId}_${p.roundNum}_${p.attackerSteamID}_${p.victimSteamID}_${p.tick}`}
            data={p}
            scaleFactor={scaleFactor}
            currentTick={currentTick}
            mapName={mapName}
            hovered={false}
            playerName={p.victimShortName ?? p.victimName}
            upperView={upperView}
            invisible={false}
          />
        </svg>
      )}
      {deaths.map((p, i) =>
        <svg
          key={`svg_player_death_${p.matchId}_${p.roundNum}_${p.attackerSteamID}_${p.victimSteamID}_${p.tick}`}
        >
          <AnalyseSVGDeath
            key={`svg_player_death_component_${p.matchId}_${p.roundNum}_${p.attackerSteamID}_${p.victimSteamID}_${p.tick}`}
            data={p}
            scaleFactor={scaleFactor}
            currentTick={currentTick}
            mapName={mapName}
            hovered={false}
            playerName={p.victimShortName ?? p.victimName}
            upperView={upperView}
            invisible={false}
          />
        </svg>
      )}
      {marqueeRect === undefined ?
        <>
          {rectGizmo}
          {svgPlayers}
        </> :
        <>
          {svgPlayers}
          {rectGizmo}
        </>
      }
      {activeRound !== undefined &&
        playerFrames.map((p, i) =>
          <svg
            key={`svg_player_wrapper_${p.steamID}_${p.match_id}_${p.roundNum}`}
          >
            <SVGPlayer
              key={`svg_dead_player_${p.steamID}`}
              playerName={p.shortName ?? p.name}
              data={p}
              scaleFactor={scaleFactor}
              currentTick={currentTick}
              mapName={mapName}
              damage={undefined}
              upperView={upperView}
              playerGizmos={"Default"}
              shadow={activeRound === undefined || p.steamID !== activePlayerSteamId}
              activePlayer={activeRound !== undefined && p.steamID === activePlayerSteamId}
            />
          </svg>
        )}
      {activeRound !== undefined && playerFrames.sort(playerSorter).map((p, i) =>
        <svg
          key={`svg_player_detailed_wrapper_${p.steamID}`}
        >
          <SVGPlayerDetailed
            key={`svg_player_detailed_${p.steamID}`}
            playerName={p.shortName ?? p.name}
            data={p}
            scaleFactor={scaleFactor}
            currentTick={currentTick}
            mapName={mapName}
            damage={damages.filter((d) => d.victimSteamID === p.steamID).sort((a, b) => b.tick - a.tick).shift()}
            upperView={upperView}
            highlight={false}
            invisible={false}
          />
        </svg>
      )}
      {hoveredRound !== undefined && playerFrames.filter((p) => p.match_id === hoveredRound.matchId && p.roundNum === hoveredRound.roundNum).map((p, i) =>
        <svg
          key={`svg_player_detailed_wrapper_${p.steamID}`}
        >
          <SVGPlayerDetailed
            key={`svg_player_detailed_${p.steamID}`}
            playerName={p.shortName ?? p.name}
            data={p}
            scaleFactor={scaleFactor}
            currentTick={currentTick}
            mapName={mapName}
            damage={damages.filter((d) => d.victimSteamID === p.steamID).sort((a, b) => b.tick - a.tick).shift()}
            upperView={upperView}
            highlight={false}
            invisible={false}
          />
        </svg>
      )}
      {projectiles.map((p, i) =>
        <SVGProjectile
          key={p.data.uniqueID}
          data={p.data}
          throwerPosition={p.throwerPosition}
          positions={p.positions}
          visualPositions={p.visualPositions}
          mapName={mapName}
          mapInfo={mapInfoAnalyse}
          upperView={upperView}
          minZ={p.minZ}
          maxZ={p.maxZ}
          endZ={p.endZ}
          scaleFactor={scaleFactor}
          startTick={p.startTick}
          endTick={p.endTick}
          angularVelocity={p.angularVelocity}
          startAngle={p.startAngle}
          side={p.side}
          currentTick={currentTick}
          currentFrameIndex={p.currentFrameIndex}
          otherLevelOpacity={0.05}
        />
      )}
      {smokes.map((smoke, i) =>
        <SVGSmoke
          key={`smoke_${i}`}
          data={smoke}
          mapName={mapName}
          currentTick={currentTick}
          upperView={upperView}
          scaleFactor={scaleFactor}
          otherLevelOpacity={0.05}
          csVersion={csVersion}
        />
      )}
      {explosions.map((p, i) => {
        const position = world_to_screen([p.grenadeX, p.grenadeY, p.grenadeZ], mapName);
        const explosionTick = getExplosionTick(p, csVersion);
        const t = currentTick - explosionTick;
        const tt = 1.0 - t / 30;
        const a = Math.max(tt, 0);
        const r = Math.max(tt * 3.0, 0);
        let color = p.grenadeType === 'Flashbang' ? [255, 255, 255, a] : [255, 64, 0, a];

        const isBelow = p.grenadeZ < mapInfoAnalyse[mapName as keyof typeof mapInfoAnalyse].splitZ;
        const isDimmed = upperView ? isBelow : !isBelow;

        if (isDimmed) {
          color = [color[0], color[1], color[2], 0.1];
        }

        return (
          <circle
            key={`explosion_${i}`}
            transform={`translate(${position[0]}, ${position[1]})`}
            r={r}
            fill={`rgb(${color[0]}, ${color[1]}, ${color[2]}, ${color[3]})`}
          />
        )
      })}
      {marqueeRect === undefined && kills.map((p, i) =>
        <svg
          key={`svg_player_kill_hovertarget_${p.matchId}_${p.roundNum}_${p.attackerSteamID}_${p.victimSteamID}_${p.tick}`}
          onMouseEnter={(event) => onPlayerBeginHover(p.matchId || 'undefined', mapName, p.roundNum || -1, p.victimSteamID)}
          onMouseLeave={(event) => onPlayerEndHover(p.matchId || 'undefined', mapName, p.roundNum || -1, p.victimSteamID)}
          onClick={(event) => {
            event.stopPropagation();
            onPlayerClick(p.matchId || 'undefined', mapName, p.roundNum || -1, p.victimSteamID);
          }}
          style={{ cursor: activeRound === undefined || activePlayerSteamId === p.victimSteamID ? 'pointer' : 'default' }}
        >
          <AnalyseSVGKill
            key={`svg_player_kill_hovertarget_component_${p.matchId}_${p.roundNum}_${p.attackerSteamID}_${p.victimSteamID}_${p.tick}`}
            data={p}
            scaleFactor={scaleFactor}
            currentTick={currentTick}
            mapName={mapName}
            hovered={hoveredRound?.matchId === p.matchId && hoveredRound?.roundNum === p.roundNum && hoveredPlayerSteamId === p.victimSteamID}
            playerName={p.victimShortName ?? p.victimName}
            upperView={upperView}
            invisible={true}
          />
        </svg>
      )}
      {marqueeRect === undefined && deaths.map((p, i) =>
        <svg
          key={`svg_player_death_hovertarget_${p.matchId}_${p.roundNum}_${p.attackerSteamID}_${p.victimSteamID}_${p.tick}`}
          onMouseEnter={(event) => onPlayerBeginHover(p.matchId || 'undefined', mapName, p.roundNum || -1, p.victimSteamID)}
          onMouseLeave={(event) => onPlayerEndHover(p.matchId || 'undefined', mapName, p.roundNum || -1, p.victimSteamID)}
          onClick={(event) => {
            event.stopPropagation();
            onPlayerClick(p.matchId || 'undefined', mapName, p.roundNum || -1, p.victimSteamID);
          }}
          style={{ cursor: activeRound === undefined || activePlayerSteamId === p.victimSteamID ? 'pointer' : 'default' }}
        >
          <AnalyseSVGDeath
            key={`svg_player_death_hovertarget_component_${p.matchId}_${p.roundNum}_${p.attackerSteamID}_${p.victimSteamID}_${p.tick}`}
            data={p}
            scaleFactor={scaleFactor}
            currentTick={currentTick}
            mapName={mapName}
            hovered={activeRound === undefined && marqueeRect !== undefined && hoveredRound?.matchId === p.matchId && hoveredRound?.roundNum === p.roundNum && hoveredPlayerSteamId === p.victimSteamID}
            playerName={p.victimShortName ?? p.victimName}
            upperView={upperView}
            invisible={true}
          />
        </svg>
      )}
      {marqueeRect === undefined && playerFrames.filter((p) => p.isAlive).map((p, i) =>
        <svg
          key={`svg_player_hovertarget_wrapper_${p.steamID}_${p.match_id}_${p.roundNum}`}
          onMouseEnter={(event) => onPlayerBeginHover(p.match_id || 'undefined', mapName, p.roundNum || -1, p.steamID)}
          onMouseLeave={(event) => onPlayerEndHover(p.match_id || 'undefined', mapName, p.roundNum || -1, p.steamID)}
          onClick={(event) => {
            event.stopPropagation();
            onPlayerClick(p.match_id || 'undefined', mapName, p.roundNum || -1, p.steamID);
          }}
          style={{ cursor: activeRound === undefined || activePlayerSteamId === p.steamID ? 'pointer' : 'default' }}
        >
          <AnalyseSVGPlayer
            key={`svg_player_hovertarget_${p.steamID}_${p.match_id}_${p.roundNum}`}
            data={p}
            scaleFactor={scaleFactor}
            currentTick={currentTick}
            mapName={mapName}
            upperView={upperView}
            invisible={true}
            activePlayer={false}
            hoveredRound={false}
            marqueedPlayer={false}
            nonMarqueedPlayer={false}
            playerColor={playerColors.get(p.steamID) ?? '#FFFFFF'}
          />
        </svg>
      )}
      {activeRound !== undefined && marqueeRect === undefined && playerFrames.filter((p) => p.isAlive).sort(playerSorter).map((p, i) =>
        <svg
          key={`svg_player_hovertarget_detailed_wrapper_${p.steamID}`}
          onMouseEnter={(event) => onPlayerBeginHover(p.match_id || 'undefined', mapName, p.roundNum || -1, p.steamID)}
          onMouseLeave={(event) => onPlayerEndHover(p.match_id || 'undefined', mapName, p.roundNum || -1, p.steamID)}
          onClick={(event) => {
            event.stopPropagation();
            onPlayerClick(p.match_id || 'undefined', mapName, p.roundNum || -1, p.steamID);
          }}
        >
          <SVGPlayerDetailed
            key={`svg_player_hovertarget_detailed_${p.steamID}`}
            playerName={p.shortName ?? p.name}
            data={p}
            scaleFactor={scaleFactor}
            currentTick={currentTick}
            mapName={mapName}
            damage={damages.filter((d) => d.victimSteamID === p.steamID).sort((a, b) => b.tick - a.tick).shift()}
            upperView={upperView}
            highlight={false}
            invisible={true}
          />
        </svg>
      )}
    </svg>
  );
}

export default AnalyseMapViewSVG;