import { GrenadeData, PlayerFrameData, ProjectileData } from "../utils/types";
import { useEffect, useRef, useState } from "react";
import { world_to_screen } from "../utils/math";
import { CachedProjectiles, Projectile, ProjectileInfo, isProjectileInRange } from "../utils/projectile";
import { FireInfo, SmokeInfo } from "./MapView";
import { mapInfoAnalyse } from "../utils/maps";

import { SVGGrenadeAnalysisTrail } from "./SVGGrenadeAnalysisTrail";
import { SVGAnalysisProjectile } from "./SVGAnalysisProjectile";
import AnalyseRectGizmo from "./AnalyseRectGizmo";

interface AnalyseGrenadesMapViewSVGProps {
  mapName: string,
  upperView: boolean,
  currentTick: number,
  currentTickRange: number[],
  playerFrames: PlayerFrameData[],
  players: number[],
  projectiles: Projectile[],
  projectileCache: CachedProjectiles,
  selectedProjectileCache: CachedProjectiles | undefined,
  hoveredProjectile: number | undefined,
  explosions: GrenadeData[],
  smokes: SmokeInfo[],
  fires: FireInfo[],
  mapZoom: number,
  rect: number[] | undefined,
  rectType: 'all' | 'landing' | undefined,
  rectChanged: (
    recordingRect: number[] | undefined,
    rectType: 'all' | 'landing' | undefined,
    dragStart: boolean,
    dragEnd: boolean,
  ) => void,
}

const AnalyseGrenadesMapViewSVG = ({
  mapName,
  upperView,
  currentTick,
  currentTickRange,
  playerFrames,
  players,
  projectiles,
  projectileCache,
  selectedProjectileCache,
  hoveredProjectile,
  explosions,
  smokes,
  fires,
  mapZoom,
  rect,
  rectType,
  rectChanged,
}: AnalyseGrenadesMapViewSVGProps) => {
  const svgRef = useRef<SVGSVGElement | null>(null);
  const [scaleFactor, setScaleFactor] = useState(10);

  useEffect(() => {
    const updateScaleFactor = () => {
      if (svgRef.current) {
        const svgWidth = svgRef.current.clientWidth;
        const svgHeight = svgRef.current.clientHeight;
        const viewBoxSize = 100;
        const newScaleFactor = Math.min(Math.max(Math.min(svgWidth, svgHeight) / (viewBoxSize * (1.0 / mapZoom)), 6.0), 15.0);
        setScaleFactor(newScaleFactor);
      }
    };

    updateScaleFactor();
    window.addEventListener('resize', updateScaleFactor);

    return () => {
      window.removeEventListener('resize', updateScaleFactor);
    };
  }, [mapName]);

  return (
    <svg className={`map-view-svg`} width="100%" height="100%" viewBox="0 0 100 100" ref={svgRef}>
      {Object.entries(projectileCache)
        .filter(([_, projectileInfo]) => players.includes(projectileInfo.thrower.steamID) || 
            selectedProjectileCache !== undefined && projectileInfo.uniqueID in selectedProjectileCache
        )
        .map(([key, obj]) => {
          const p: ProjectileInfo = obj as ProjectileInfo;
          return <SVGGrenadeAnalysisTrail
            key={p.uniqueID + 'analysis'}
            throwerPosition={p.throwerPosition}
            landingPosition={p.endPosition}
            positions={p.positions}
            visualPositions={p.visualPositionsFlat}
            mapName={mapName}
            mapInfo={mapInfoAnalyse}
            upperView={upperView}
            minZ={p.minZ}
            maxZ={p.maxZ}
            endZ={p.endZ}
            scaleFactor={scaleFactor}
            startTick={p.startTick}
            endTick={p.endTick}
            angularVelocity={p.angularVelocity}
            startAngle={p.startAngle}
            side={p.side}
            currentTick={currentTick}
            otherLevelOpacity={0.05}
            uniqueID={p.uniqueID}
            active={selectedProjectileCache !== undefined && (p.uniqueID in selectedProjectileCache)}
            hovered={hoveredProjectile === p.uniqueID}
            disabled={rectType !== undefined && !(selectedProjectileCache !== undefined && p.uniqueID in selectedProjectileCache)}
            selectionType={rectType}
          />
        })}
      <AnalyseRectGizmo
        rect={rect}
        rectChanged={rectChanged}
      />
    </svg>
  );
};

export default AnalyseGrenadesMapViewSVG;