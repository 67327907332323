import { world_to_screen } from "../utils/math";
import { projectileShapes } from "../utils/projectile";
import { ProjectileData, Side } from "../utils/types";

interface SVGGrenadeAnalysisTrailProps {
  throwerPosition: [number, number, number],
  landingPosition: [number, number, number],
  positions: [number | undefined, number | undefined][],
  visualPositions: [number | undefined, number | undefined][],
  mapName: string,
  mapInfo: { [key: string]: any },
  upperView: boolean,
  minZ: number,
  maxZ: number,
  endZ: number,
  scaleFactor: number,
  startTick: number,
  endTick: number,
  angularVelocity: number,
  startAngle: number,
  side: Side,
  currentTick: number,
  otherLevelOpacity: number,
  uniqueID: number,
  active: boolean,
  hovered: boolean,
  disabled: boolean,
  selectionType: 'all' | 'landing' | undefined,
}

export const SVGGrenadeAnalysisTrail = ({ throwerPosition, landingPosition, positions, visualPositions, mapName, mapInfo, upperView, minZ, maxZ, endZ, scaleFactor, startTick, endTick, angularVelocity, startAngle, side, currentTick, otherLevelOpacity, uniqueID, active, hovered, disabled, selectionType }: SVGGrenadeAnalysisTrailProps) => {
  const a = active ? 1 : 0.5;
  let color = active ? [224, 224, 224, a] : [192, 192, 192, a];
  let throwerColor = selectionType === 'all' ? color : [192, 192, 192, a];
  let landingColor = selectionType === 'landing' ? color : [192, 192, 192, a];

  const hoverColor = [128,236,128,a];

  if (hovered) {
    color = hoverColor;
  }
  if(selectionType === 'all' && active) {
    throwerColor = [128,236,128,a];
    landingColor = [128,236,128,a];
  }
  if(selectionType === 'landing' && active) {
    landingColor = [128,236,128,a];
  }
  if (hovered) {
    throwerColor = hoverColor;
    landingColor = hoverColor;
  }
  let trailColor: number[] = [color[0], color[1], color[2], a];

  const throwerScreenPosition = world_to_screen([throwerPosition[0], throwerPosition[1], throwerPosition[2]], mapName);
  const trail: [[number, number]] = [[throwerScreenPosition[0], throwerScreenPosition[1]]];
  for (const position of visualPositions) {
    if (position[0] !== undefined && position[1] !== undefined) {
      trail.push([position[0] as number, position[1] as number]);
    }
  }
  const landingScreenPosition = world_to_screen([landingPosition[0], landingPosition[1], landingPosition[2]], mapName);
  trail.push([landingScreenPosition[0], landingScreenPosition[1]]);

  const strokeWidth = hovered ? 0.4 : 0.15;
  const strokeDasharray = hovered ? undefined : "0.5, 0.25";

  const landingScreenPos = trail[trail.length - 1];
  const r = 30.0 / scaleFactor;
  const throwerScreenPos = world_to_screen([throwerPosition[0], throwerPosition[1], throwerPosition[2]], mapName);

  const trailData = trail.length > 1 ? `M${trail[0][0]},${trail[0][1]} ${trail
    .slice(1)
    .map((p) => `L${p[0]},${p[1]}`)
    .join(' ')
    }` : undefined;

  return (
    <svg>
      <g key={`projectile_analysis_trace_${uniqueID}`}>
        {!disabled && <path
          d={trailData}
          fill="none"
          stroke={`rgba(${trailColor[0]}, ${trailColor[1]}, ${trailColor[2]}, ${trailColor[3]})`}
          strokeWidth={strokeWidth}
          strokeDasharray={strokeDasharray}
          strokeDashoffset="0px"
        />}
        {(selectionType !== 'landing' || !disabled) && <circle
          transform={`translate(${throwerScreenPos[0]}, ${throwerScreenPos[1]})`}
          r={r * 0.17}
          strokeWidth={r * 0.04 * (selectionType === 'all' && active ? 1.5 : 0.75)}
          fill={'rgb(0,0,0,0)'}
          stroke={`rgb(${throwerColor[0]}, ${throwerColor[1]}, ${throwerColor[2]}, ${throwerColor[3]})`}
        />}
        {<>
        <line
          x1={landingScreenPos[0] - r * 0.15}
          y1={landingScreenPos[1] - r * 0.15}
          x2={landingScreenPos[0] + r * 0.15}
          y2={landingScreenPos[1] + r * 0.15}
          strokeWidth={r * 0.06 * (active ? 1.5 : 0.85)}
          stroke={`rgb(${landingColor[0]}, ${landingColor[1]}, ${landingColor[2]}, ${landingColor[3]})`}
        />
        <line
          x1={landingScreenPos[0] + r * 0.15}
          y1={landingScreenPos[1] - r * 0.15}
          x2={landingScreenPos[0] - r * 0.15}
          y2={landingScreenPos[1] + r * 0.15}
          strokeWidth={r * 0.06 * (active ? 1.5 : 0.85)}
          stroke={`rgb(${landingColor[0]}, ${landingColor[1]}, ${landingColor[2]}, ${landingColor[3]})`}
        /></>}
      </g>
    </svg>
  );
}
