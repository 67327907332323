import { useEffect, useRef, useState } from "react";
import { item_paths } from "../utils/item_paths";
import { mapInfo } from "../utils/maps";
import { world_to_screen } from "../utils/math";
import { primaryWeaponsSvg, secondaryWeaponsSvg } from "../utils/weapons";
import { DamageData, PlayerFrameData } from "../utils/types";
import SVGWeapon from "./SVGWeapon";


const SVGPlayerDetailed = ({ data, playerName, currentTick, scaleFactor, damage, mapName, upperView, highlight, invisible }: {
  data: PlayerFrameData,
  playerName: string,
  currentTick: number,
  scaleFactor: number,
  damage: DamageData | undefined,
  mapName: string,
  upperView: boolean,
  highlight: boolean,
  invisible: boolean,
}) => {

  const text = playerName;
  let innerText: string | undefined = undefined;

  const [boxSize, setBoxSize] = useState({ width: 0, height: 0 });

  const textRef = useRef<SVGTextElement>(null);

  useEffect(() => {
    if (textRef.current) {
      const bbox = textRef.current.getBBox();
      setBoxSize({ width: bbox.width + 10, height: bbox.height + 2 });
    }
  }, [textRef]);

  const weapons = data.inventory ? data.inventory.filter(item => item.weaponName in primaryWeaponsSvg || item.weaponName in secondaryWeaponsSvg) : [];
  const primaryWeapon = data.inventory ? data.inventory.find(item => item.weaponName in primaryWeaponsSvg)?.weaponName : undefined;

  let weapon: string | undefined = data.activeWeapon;
  if (weapons.findIndex((item) => item.weaponName === data.activeWeapon) >= 0) {
    weapon = data.activeWeapon;
  } else {
    weapon = primaryWeapon;
  }
  const weaponSvg = weapon !== undefined ? (weapon in primaryWeaponsSvg ? primaryWeaponsSvg[weapon] : secondaryWeaponsSvg[weapon]) : undefined;
  const isPrimary = weapon !== undefined ? weapon in primaryWeaponsSvg : false;

  const position = world_to_screen([data.x, data.y, data.z], mapName);

  const mInfo = mapInfo[mapName as keyof typeof mapInfo];
  const zOpacity = (data.z - mInfo.minZ) / (mInfo.maxZ - mInfo.minZ);
  const opacity = invisible ? 0.0 : Math.min(Math.max(upperView ? zOpacity : 1.0 - zOpacity, 0.2), 1.0);

  let shadow = [0, 0, 0, 0.15 * opacity];
  let color = data.side === "T" ? [255, 157, 16, 0.45 * opacity] : [17, 199, 256, 0.45 * opacity];
  let damageCoeff = 0;
  let textColor = [0, 0, 0, opacity];
  let weaponColor = [192, 192, 192, opacity * 0.32];

  if (!data.isAlive) {
    color = color.map((c) => c * 0.2 + 48);
  }
  let stroke: number[] = data.isAlive ? [255, 255, 255, opacity] : [64, 64, 64, opacity];
  if (data.isBlinded && data.isAlive) {
    color = data.side === "T" ? [255, 240, 180, color[3]] : [180, 240, 256, color[3]];
    textColor = [88, 88, 88, opacity];
    innerText = data.blindedForTicks > 0 ? Math.ceil(data.blindedForTicks / 128).toString() : undefined;
  }
  if (data.hasBomb && data.isAlive && data.side === "T") {
    stroke = [255, 255, 255, stroke[3]];
    if (!data.isBlinded) {
      textColor = [255, 255, 255, opacity];
      color = [200, 100, 64, color[3]]
    }
  }
  if (damage !== undefined) {
    const t = currentTick - damage.tick;
    damageCoeff = Math.min(Math.max((50 - t * 1.0) / 50, 0), 1);
    const m = damageCoeff;
    stroke = [192 * m + stroke[0] * (1 - m), 0 * m + stroke[1] * (1 - m), 0 * m + stroke[2] * (1 - m), opacity * m + stroke[3] * (1 - m)];
    color = [192 * m + color[0] * (1 - m), 0 * m + color[1] * (1 - m), 0 * m + color[2] * (1 - m), opacity * m + color[3] * (1 - m)];
  }

  if (highlight) {
    color = [color[0], color[1], color[2], 1.0];
  }

  const bgColor = [color[0], color[1], color[2], color[3] * 0.66];
  const hpratio = data.hp / 100.0;

  const size = 9.0 / scaleFactor;
  const borderRadius = size * 6;

  return (
    <>
      <g transform={`translate(${position[0]}, ${position[1] - size * 5.3}) scale(${1 / scaleFactor})`}>
        <SVGWeapon
          item_path={item_paths[weaponSvg ? weaponSvg : "default"]}
          size={0.35}
          color={weaponColor}
          isPrimary={isPrimary}
        />
      </g>
      <g transform={`translate(${position[0]}, ${position[1] - size * 2.6}) scale(${1 / scaleFactor})`}>
        <rect
          x={`${-boxSize.width / 2 + 3}`}
          y={`${-boxSize.height / 2 + 3}`}
          width={boxSize.width}
          height={boxSize.height}
          fill={`rgba(${shadow[0]}, ${shadow[1]}, ${shadow[2]}, ${shadow[3]})`}
          style={{ filter: "blur(2px)" }}
          rx={borderRadius}
          ry={borderRadius}
        />
        <rect
          x={`-${boxSize.width / 2}`}
          y={`-${boxSize.height / 2}`}
          width={boxSize.width}
          height={boxSize.height}
          fill={`rgba(${bgColor[0]}, ${bgColor[1]}, ${bgColor[2]}, ${bgColor[3]})`}
          rx={borderRadius}
          ry={borderRadius}
        />
        <rect
          x={`-${boxSize.width / 2}`}
          y={`-${boxSize.height / 2}`}
          width={boxSize.width * hpratio}
          height={boxSize.height}
          fill={`rgba(${color[0]}, ${color[1]}, ${color[2]}, ${color[3]})`}
          rx={borderRadius}
          ry={borderRadius}
        />
        <text
          ref={textRef}
          dominantBaseline="middle"
          textAnchor="middle"
          fontSize={9.0}
          fill={`rgba(${textColor[0]}, ${textColor[1]}, ${textColor[2]}, ${textColor[3]})`}
          x={0}
          y={0.5}
        >
          {text}
        </text>
      </g>
      <g transform={`translate(${position[0] + size * 0.05}, ${position[1] + size * 0.05}) scale(${1 / scaleFactor})`}>
        <text
          dominantBaseline="middle"
          textAnchor="middle"
          fontSize={10.0}
          fill={`rgba(0,0,0,1)`}
          x={0}
          y={0}
        >
          {innerText}
        </text>
      </g>
    </>
  )
};

export default SVGPlayerDetailed;