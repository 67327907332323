import { Button } from "react-bootstrap";
import RangeSlider from 'react-bootstrap-range-slider';
import { useState } from "react";

import 'rc-slider/assets/index.css';
import { Timer } from "./Timer";
import Range from 'rc-slider';

const AnalyseTimeline = ({
  currentTick,
  currentTickRange,
  autoPlay,
  playbackSpeed,
  endTick,
  bombPlantedTick,
  csVersion,
  type,
  setCurrentTick,
  setCurrentTickRange,
  setAutoPlay,
  togglePlaybackSpeed,
}: {
  currentTick: number | undefined,
  currentTickRange: number[],
  autoPlay: boolean,
  playbackSpeed: number,
  endTick: number | undefined,
  bombPlantedTick: number | undefined,
  csVersion: string,
  type: "default" | "range",
  setCurrentTick: (currentTick: number) => void
  setCurrentTickRange: (currentTickRange: number[]) => void
  setAutoPlay: (autoPlay: boolean) => void
  togglePlaybackSpeed: () => void
}) => {
  const [autoPlayOnMouseDown, setAutoPlayOnMouseDown] = useState(false);

  const handleKeyDown = (event: { preventDefault: () => void; }) => {
    event.preventDefault();
  };

  const renderSlider = () => {
    return (
      <RangeSlider
        value={currentTick ? currentTick : 0}
        onMouseUp={(event: any) => {
          setCurrentTick(parseInt(event.target.value))
          setAutoPlay(autoPlayOnMouseDown);
        }}
        onMouseDown={(event: any) => {
          setAutoPlayOnMouseDown(autoPlay)
          setAutoPlay(false);
        }}
        onChange={changeEvent => setCurrentTick(parseInt(changeEvent.target.value))}
        min={0}
        max={endTick}
        tooltip="off"
        variant="info"
      />
    );
  };

  const renderRangeSlider = () => {
    const handleStyle = {
      borderColor: '#5ab',
      backgroundColor: '#5ab',
      opacity: 1,
      width: '20px',
      height: '20px',
      marginTop: '-8px'
    };
    return <div style={{ width: '100%', height: '38px', marginLeft: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Range
        range
        value={[currentTickRange[0], currentTickRange[1]]}
        onChange={(value: number | number[]) => {
          if (Array.isArray(value)) {
            if (value[0] === currentTickRange[0]) {
              setCurrentTick(value[1]);
            } else {
              setCurrentTick(value[0]);
            }
            setCurrentTickRange(value);
          }
        }}
        min={0}
        max={endTick}
        allowCross={false}
        trackStyle={[{ backgroundColor: 'lightgray' }]}
        handleStyle={[handleStyle, handleStyle]}
        railStyle={{ backgroundColor: '#345' }}
      />
    </div>;
  }

  return (
    <>
      <div className="d-flex w-100">
        <Button variant={"outline-secondary"} className={"btn playButton"} onClick={() => setAutoPlay(!autoPlay)} onKeyDown={handleKeyDown} >
          {autoPlay ? <i className="bi bi-pause"></i> : <i className="bi bi-play"></i>}
        </Button>
        <Button variant={"outline-secondary"} className={"btn speedButton"} onClick={togglePlaybackSpeed} onKeyDown={handleKeyDown} >
          x{playbackSpeed}
        </Button>
        {type === 'default' ? renderSlider() : renderRangeSlider()}
        <div className="round-timer" style={{ marginLeft: '15px', marginRight: '10px' }}>
          <Timer
            startTick={0}
            currentTick={currentTick || 0}
            bombPlantedTick={bombPlantedTick}
            tickRate={csVersion === "cs2" ? 64 : 128}
          />
        </div>
      </div>
    </>
  );
};

export default AnalyseTimeline;