export const hltvTeams = [
  "ence",
  "faze",
  "g2",
  "heroic",
  "mouz",
  "natus-vincere",
  "spirit",
  "vitality",
  "cloud9",
  "complexity",
  "virtus-pro",
  "apeks",
  "astralis",
  "eternal-fire",
  "falcons",
  "furia",
  "gamerlegion",
  "liquid",
  "monte",
  "ninjas-in-pyjamas",
  "3dmax",
  "9ine",
  "9z",
  "bad-news-eagles",
  "betboom",
  "big",
  "flyquest",
  "fnatic",
  "forze",
  "imperial",
  "into-the-breach",
  "mibr",
  "movistar-riders",
  "og",
  "pain",
  "saw",
  "the-mongolz",
  "themongolz",
  "00nation",
  "1win",
  "416olsk",
  "500",
  "5yclone",
  "9-pandas",
  "acme",
  "adalyamigos",
  "addice",
  "ago",
  "alliance",
  "alternate-attax",
  "amkal",
  "anonymo",
  "apogee",
  "arcred",
  "arctic-raptors",
  "astralis-talent",
  "atk",
  "atox",
  "aurora",
  "aurora-young-blud",
  "b8",
  "bad-news-kangaroos",
  "bestia",
  "betera",
  "bhop",
  "bleed",
  "boss",
  "bounty-hunters",
  "bravado",
  "canon-event",
  "case",
  "catevil",
  "claymakers",
  "copenhagen-flames",
  "corinthians",
  "cybershoke",
  "dms",
  "domination",
  "dusty",
  "dusty-roots",
  "ecf",
  "eclot",
  "ecstatic",
  "eg-black",
  "elevate",
  "ence-academy",
  "endpoint",
  "enterprise",
  "entropiq",
  "eros",
  "espionage",
  "evil-geniuses",
  "ex-anonymo",
  "ex-copenhagen-flames",
  "ex-ination",
  "ex-viperio",
  "eyeballers",
  "flamengo",
  "fluffy-aimers",
  "fluxo",
  "forsaken",
  "ftw",
  "furia-academy",
  "g3",
  "gaimin-gladiators",
  "galorys",
  "genone",
  "geud",
  "godsent",
  "grayhound",
  "grindas",
  "gtz",
  "gucci-academy",
  "guild-eagles",
  "gun5",
  "havu",
  "hawks",
  "heet",
  "hotu",
  "hype",
  "ikla",
  "illuminar",
  "ination",
  "insanity",
  "insilio",
  "iron-branch",
  "jano",
  "johnny-speeds",
  "knasigt",
  "koi",
  "kr-",
  "kzg",
  "legacy",
  "looking-for-org",
  "looking4org",
  "los-kogutos",
  "lyg",
  "lynn-vision",
  "m80",
  "mb",
  "meta",
  "metizport",
  "mindfreak",
  "mouz-nxt",
  "mungyu",
  "mythic",
  "navity",
  "nemiga",
  "nexus",
  "nkt",
  "nouns",
  "nrg",
  "oddik",
  "orks",
  "parivision",
  "party-astronauts",
  "passion-ua",
  "pera",
  "permitta",
  "pge-turow",
  "pompa",
  "preasy",
  "qiang",
  "rare-atom",
  "rebels",
  "red-canids",
  "redpack",
  "revenant",
  "revenge-nation",
  "rooster",
  "rounds",
  "ruby",
  "rush-b",
  "sampi",
  "sangal",
  "sashi",
  "selva",
  "sharks",
  "sinners",
  "sojoga",
  "solid",
  "space",
  "spirit-academy",
  "sprout",
  "straykids",
  "strife",
  "synck",
  "system5",
  "take-flyte",
  "the-chosen-few",
  "the-huns",
  "the-jungle",
  "thunderflash",
  "timaceta",
  "timbermen",
  "true-rippers",
  "tsm",
  "tyloo",
  "vertex",
  "victory-zigzag",
  "viperio",
  "vitavi",
  "w7m",
  "wildcard",
  "wings-up",
  "without-warning",
  "x13",
  "young-ninjas",
  "zenith",
  "zero-tenacity",
  "-72c",
  "-benched",
  "-conquer",
  "1-jin",
  "100tag",
  "101est",
  "11-minutos-tarde",
  "15-average",
  "15-average-gang",
  "1adl",
  "1win-academy",
  "1win-gang",
  "2-dollar-spaghetti",
  "2ez",
  "2game",
  "30-seconds-to-win",
  "359",
  "4ormula",
  "4wb",
  "500-rush",
  "5fyctimz",
  "5w",
  "618-ks",
  "727",
  "777",
  "7am",
  "7am-na",
  "7sea",
  "9-pandas-fearless",
  "9ine-academy",
  "9z-academy",
  "above-the-rest",
  "abt",
  "abyss",
  "aces",
  "adepts",
  "aether",
  "after-hours",
  "age",
  "agency",
  "aiming-is-difficult",
  "aimmers",
  "airlya",
  "ajf",
  "ak-bars",
  "akatsuki-fe",
  "akimbo",
  "akmuo",
  "al-ittihad",
  "al-qatrao",
  "albania",
  "all-in",
  "allinners",
  "allstars",
  "aloha",
  "alter-ego",
  "alter-iron",
  "ambush",
  "amigos",
  "amigos-de-t2m4ss",
  "amigosi",
  "amped",
  "amped-red",
  "antenna-man",
  "antic",
  "anxi5tys",
  "apeks-rebels",
  "aravt",
  "arcade",
  "arcadia",
  "arctic",
  "arf-squad-fe",
  "argentina-fe",
  "arrow",
  "asian-kings",
  "astatiqs",
  "astralis-fe",
  "astralis-w",
  "astronuts",
  "atlantic",
  "atputies",
  "atrix",
  "aura",
  "aurora-yb",
  "australia",
  "australia-fe",
  "austria",
  "avangar",
  "avez",
  "aware-fe",
  "awesome-academy",
  "awesome-arcade",
  "ay",
  "b4-fe",
  "bad-news-bears",
  "bad-news-bricks",
  "bad-news-chickens",
  "badass",
  "baxter",
  "bc-game",
  "bebold",
  "bee",
  "beers",
  "belarus",
  "belfast-storm",
  "belgium",
  "believe",
  "benched",
  "benched-heroes",
  "bezformbi",
  "big-academy",
  "big-equipa",
  "biskvitki-zakuska",
  "bit",
  "black-dragons-fe",
  "blessed",
  "blink",
  "blitz",
  "blockkstar",
  "bluds",
  "blue-otter-karma",
  "bluejays",
  "bluekillers",
  "boca-juniors",
  "bogatyri",
  "boring-player",
  "boston-crab",
  "brasil-storm-fe",
  "brazylijski-luz",
  "briosa",
  "bro",
  "bromo",
  "brute",
  "bsmpeek",
  "bubibabu",
  "buhawi",
  "built-for-greatness",
  "bulgaria",
  "bullpeek",
  "byte",
  "c0hesion",
  "cafeina",
  "camaro-peek",
  "capcarap",
  "capivaras",
  "carecas",
  "carnival",
  "carpe-diem",
  "cartel-terraza",
  "censored-fe",
  "cepter",
  "cheesy-champs",
  "cherry-bombs",
  "chetz",
  "chinggis-warriors",
  "choccy-milk",
  "choppers",
  "cioud-10",
  "classless",
  "cleanup-crew-fe",
  "clg-red",
  "cloud-10",
  "clutch",
  "coalesce",
  "cobro-s-cobros",
  "come-on-now-dawg",
  "comedy-club",
  "complicated",
  "coven",
  "cph-wolves",
  "cr",
  "crescent-fe",
  "cringexe",
  "croatia",
  "cryptova",
  "cspojat",
  "cube",
  "cyber",
  "cyberman",
  "czech-republic",
  "dangerous",
  "danviet",
  "darkside",
  "dash",
  "datejust",
  "daugava",
  "davenport-university",
  "deac",
  "deadweight",
  "deathrow",
  "defuse",
  "demesis",
  "depo",
  "desenchantee",
  "desorganizados",
  "detonate",
  "detonate-sparx",
  "deviance",
  "dewa-united",
  "disciples",
  "divina",
  "divina-fe",
  "divine-vendetta",
  "dna",
  "dnmk",
  "do-not-disturb-la",
  "dofg",
  "dream-catchers-fe",
  "dreamcatchers-fe",
  "dreams-to-legends",
  "drillas",
  "dripmen",
  "drippy-lab",
  "duck-duck-goose",
  "dxa",
  "e-lemon-ators",
  "e-xolos-lazer",
  "e9",
  "ec-banga",
  "ec-brugge",
  "ec-kyiv",
  "eeriness",
  "eg-gold",
  "ego",
  "ehawks",
  "ek-violet",
  "elo-throwers",
  "elusiveza-s-party",
  "emasters",
  "embapeenvape",
  "empire-goblins",
  "en-av-de-lette",
  "ence-athena",
  "encore",
  "endgame",
  "eneida",
  "energyultra",
  "enhanced",
  "enigma",
  "entropiq-prague",
  "entropy",
  "epic-dudes",
  "eruption",
  "esc",
  "esca",
  "escape-arena",
  "escolinha-do-gugu",
  "esmagab",
  "esprots",
  "estellar",
  "esuba",
  "eternal-fire-academy",
  "evo-cyber",
  "evolution",
  "evolve",
  "ex-2game",
  "ex-avangar",
  "ex-bhop",
  "ex-black-dragons-fe",
  "ex-catevil",
  "ex-clg-red",
  "ex-coalesce",
  "ex-corinthians",
  "ex-corinthians-academy",
  "ex-divina-fe",
  "ex-domination",
  "ex-eneida",
  "ex-enterprise",
  "ex-flames-ascent",
  "ex-forze-ladies",
  "ex-genk",
  "ex-guild-eagles",
  "ex-guild-fe",
  "ex-honoris",
  "ex-hydra",
  "ex-k23",
  "ex-lrv",
  "ex-mad-lions",
  "ex-partizan",
  "ex-pera",
  "ex-preasy",
  "ex-raptors-ec",
  "ex-rune-eaters",
  "ex-snakes-den",
  "ex-sprout",
  "ex-synck",
  "ex-thunderflash",
  "ex-thundr",
  "ex-vibe",
  "ex-wildcard",
  "exdhq",
  "exo",
  "exzentriq-united",
  "ez4ence",
  "f4ceit4contract",
  "fa-white",
  "faceaters",
  "factor-ruby",
  "fake-dos-cebolas",
  "fake-natty",
  "fakemythic",
  "falke",
  "farmism",
  "fathers-and-sons",
  "favbet",
  "fcottond",
  "fearless-cheetahs",
  "fengda",
  "fightclub",
  "filhos-de-d10s",
  "final-form",
  "fire-flux",
  "firedup",
  "fisher-college",
  "fisher-tsunami",
  "fishing-the-bait",
  "five5",
  "flames-shieldmaidens",
  "floki",
  "fluffy-gangsters",
  "fluffy-mafia",
  "fluxo-demons",
  "flyingh1rsch",
  "flyquest-red",
  "for-fun",
  "forgone",
  "fort-arena",
  "forward",
  "forze-ladies",
  "forze-reload",
  "forze-yng",
  "fourteen",
  "fp1-e-amigos",
  "fpl-friends",
  "fragmatic",
  "france-fe",
  "francesinha",
  "fraud5",
  "freaky-saturday",
  "freeespi",
  "fresh-food-people",
  "freshfood-people",
  "friforsatan",
  "frogs",
  "furia-fe",
  "furious",
  "fusc-o-1500",
  "fusion",
  "fyr",
  "g2-ares",
  "g2-oya",
  "galaxy",
  "garaza",
  "genei-ryodan",
  "genk",
  "genkid4m4",
  "gentlefolk",
  "germany",
  "get-bdog-d",
  "gfg123321",
  "ggp",
  "ggpr-remastered",
  "ghoulsw",
  "ghr",
  "gimmik-pearls",
  "gl-academy",
  "gl-prism",
  "glamour",
  "glore",
  "goated",
  "gods-reign",
  "golf-club",
  "goliath",
  "gr",
  "grannys-knockers",
  "grayfox",
  "grdx",
  "green-tea-party",
  "grillska-orebro",
  "guild",
  "guild-fe",
  "halal5",
  "havens",
  "heartless",
  "heimo",
  "heizers",
  "homyno",
  "honoris",
  "honv-d",
  "hotline",
  "hound",
  "housebets",
  "hsg-fe",
  "huge-sweaty",
  "hui-tu-zhuan-sheng",
  "huituzhuansheng",
  "hungary",
  "hydra",
  "hyper5",
  "hyperion",
  "hypewrld",
  "idestructive",
  "ignis-serpens",
  "ihc",
  "ikari",
  "ikla-ua",
  "ilin",
  "illyrians",
  "im-apartments",
  "imp-pact-fe",
  "imperial-fe",
  "imperium-nexus",
  "incheon-on-sla2ers",
  "incontrol",
  "indonesia",
  "infinite",
  "infurity",
  "inglorious",
  "insanity-fe",
  "insilio-fe",
  "insomnia",
  "intense",
  "into-the-bin",
  "into-the-win",
  "intz",
  "invictum",
  "invictus-international",
  "ipon",
  "israel",
  "jack3d",
  "jahsdnmasjdm",
  "jake-bube",
  "jb8d",
  "jeeves",
  "jefet",
  "jijiehao",
  "joga-de-terno",
  "john-industries",
  "juggernauts",
  "k10",
  "k10-ashes",
  "k23",
  "k27",
  "kaos-fe",
  "kappa-bar",
  "kari",
  "karma",
  "katuna",
  "kg-fe",
  "kidisgra-a",
  "kingpins",
  "kingzzz",
  "kinship",
  "kitaec-4",
  "kitsune",
  "kloogarand",
  "knaeskydd",
  "komsomolsk",
  "kono",
  "kosovo",
  "kr",
  "kraken",
  "kronjyllands",
  "ks",
  "kubix",
  "kungi",
  "kz5",
  "l-g",
  "la-rugoneta",
  "lachampionsliga",
  "lag",
  "lajtbitexe",
  "last-dance",
  "latvia",
  "lausanne-se",
  "lazer-cats",
  "lbsnkpg",
  "ldlc",
  "le-lux",
  "lebanon",
  "legacy-in",
  "legends-old",
  "lemondogs",
  "leon",
  "let-her-cook",
  "let-s-go-golfing",
  "let-us-cook",
  "letusqualify",
  "lewandownskie",
  "lfo",
  "lgg-snakes-den",
  "lilmix",
  "limitless",
  "limitless-angels",
  "linx-legacy",
  "little-boy-s",
  "ll-madagaskar",
  "lm68",
  "loading",
  "lock-in",
  "locke-s-kittens",
  "lodis",
  "lord-rabbit",
  "lore",
  "los",
  "los-alpacas",
  "los-grandes",
  "lot",
  "lotus",
  "lotus-fe",
  "lrv",
  "lrv-fe",
  "lt5",
  "lumen-fe",
  "m1x-ks",
  "m1xx",
  "mach-5",
  "madness",
  "mag",
  "magiclight",
  "major-abusers",
  "make-your-destiny",
  "making-history",
  "mansion",
  "mansland",
  "mantra",
  "marca-registrada",
  "marcos",
  "marskalk",
  "masonic",
  "mastermix123",
  "maximum-ego",
  "mcs",
  "megazord-fe",
  "megoshort",
  "melbet",
  "melly-madness",
  "memo",
  "meteor",
  "metizport-x",
  "mibr-academy",
  "mibr-fe",
  "might",
  "mightywolves",
  "mirai",
  "mission-possible",
  "mitos",
  "mmm",
  "mocreias",
  "moldova",
  "molotov",
  "mongolia",
  "monte-gen",
  "moon",
  "more-whiskey",
  "mouse-4-aimers",
  "msklaners",
  "my-life-be-like",
  "myinsanity",
  "myskill",
  "mystique",
  "n00rg",
  "na-savers",
  "nakama",
  "navi-javelins",
  "navi-junior",
  "navi-youth",
  "nctdk",
  "ne",
  "nemesis-fe",
  "netherlands-fe",
  "neverplay",
  "newballs",
  "newhappy",
  "next-in-line",
  "nf0",
  "nibble",
  "nigeria-96",
  "nightmare",
  "nigma-galaxy",
  "niise",
  "ninjas-in-kandooras",
  "niory",
  "nip-impact",
  "nirvana",
  "nitro",
  "nixuh",
  "no-impact",
  "no-name",
  "nochance",
  "nom",
  "nomads",
  "noobs-but-diligent",
  "nordix",
  "north-macedonia",
  "norway",
  "nouns-fe",
  "nova",
  "novum",
  "ntk",
  "nutorious",
  "nwnw",
  "o-plano",
  "odg",
  "offdream",
  "og3od",
  "old-school",
  "omit",
  "one-more",
  "oneday-fe",
  "oni",
  "onliners5",
  "onyx",
  "onyx-ravens",
  "oreshkins",
  "orion",
  "over30",
  "overfrag",
  "overpowered",
  "owl",
  "ozone",
  "pact",
  "padaem-holodnie",
  "pain-academy",
  "palestine",
  "paloma",
  "panelinha",
  "pantsu",
  "papa-yada",
  "paquet-",
  "parkers-money-crew",
  "partizan",
  "patatipatata",
  "patins-da-ferrari",
  "peak-fe",
  "peep",
  "penance",
  "permitta-w",
  "perroflautas",
  "perseverance",
  "phoenix",
  "pigeons",
  "pikejagarna",
  "pixel",
  "players",
  "plusw",
  "plusw-ks",
  "pneuma",
  "poggerz",
  "poland",
  "portugal",
  "post-mortem",
  "premdesant",
  "primate",
  "priority",
  "prizyvanet",
  "project-g",
  "prospects",
  "pubg-aim",
  "puggers",
  "punishers",
  "qm",
  "qm-fe",
  "quackquack",
  "quazar",
  "quem-sao-elas",
  "question-mark",
  "quixal",
  "qwerto17dar",
  "rage",
  "random-5",
  "raptors-ec",
  "ravens",
  "razdva",
  "real-gamers",
  "real-madrid",
  "really-weird",
  "reason",
  "red-demon",
  "regain",
  "rehl",
  "reign",
  "reign-supreme",
  "resilience",
  "retaliation",
  "retirement-home",
  "reveal",
  "revel",
  "revenge",
  "rgw",
  "rhyno",
  "river-plate",
  "rkon",
  "rocket",
  "roflan-squad",
  "rog-academy-in",
  "romania",
  "rosomaha",
  "rota",
  "roundsgg",
  "roux",
  "royal-signals",
  "royals",
  "royalty",
  "rubik",
  "rugratz",
  "rum-bumpers",
  "run-kill",
  "runitdown",
  "runngun",
  "rur",
  "rustec",
  "sacrifice",
  "saga",
  "saints",
  "sakana123",
  "salao-do-corte",
  "saligogerds",
  "samaloyod",
  "sangrija",
  "sans-vergogne",
  "sashi-academy",
  "sashi-mix",
  "saudi-arabia",
  "scavengers",
  "sd-invicta",
  "seaw",
  "secret",
  "sele-o-do-bt0",
  "selecao",
  "semperfi",
  "sensei",
  "serbia",
  "sggt",
  "shanghai-sharks",
  "shapito",
  "sharks-youngsters",
  "sheer-conquer",
  "shimmer",
  "shinden",
  "shinigami",
  "shoo7ers",
  "showmakerz",
  "shpl",
  "shrekas",
  "shush",
  "singularity",
  "sinners-academy",
  "sissi-state-punks",
  "skade",
  "skrill-ogres",
  "skyfury",
  "slnt",
  "slovakia",
  "slugy-s-shekel-squad",
  "smaek",
  "smoke",
  "smoke-fe",
  "snakes-den",
  "snogard-dragons",
  "soda",
  "souls-heart",
  "southern5",
  "spain",
  "spate",
  "spectre",
  "spirit-fe",
  "spirit-hu",
  "sport",
  "spotless",
  "sprout-academy",
  "squad",
  "squids",
  "sr-nacague",
  "srk",
  "ssp",
  "st4rboys",
  "stacks-of-cash",
  "staffan-gymnasiet",
  "stand-on-business",
  "stanley-s-harem",
  "starfish",
  "stealth",
  "steel-helmet",
  "stirlzs-angels",
  "stockholm-city",
  "storm",
  "stormzz",
  "strikex",
  "stuck-in-iceland",
  "subutai",
  "sunday-school",
  "sundogs",
  "sunshine",
  "super-sangre-joven",
  "superfkrs",
  "superior",
  "supernova",
  "supresse",
  "sweden",
  "sweet-aim-bro",
  "sympa-la-force",
  "t-r",
  "taipan",
  "talon",
  "taubate",
  "team-adren",
  "team-aunkere",
  "team-boombl4",
  "team-dosia",
  "team-flamie",
  "team-get-right",
  "team-lobanjica",
  "team-nadeking",
  "team-olofmeister",
  "team-pimp",
  "teamone",
  "teamwork",
  "temp",
  "tempest",
  "the",
  "the-art-of-war",
  "the-big-dogs",
  "the-free",
  "the-last-dance",
  "the-last-resort",
  "the-lizards",
  "the-neighbours",
  "the-nomads",
  "the-prodigies",
  "the-prodigies-fr",
  "the-prodigies-se",
  "the-qube",
  "the-suspect",
  "the-union",
  "the-witchers",
  "thedice",
  "thekillaz-fe",
  "tmvg",
  "tnl",
  "tog",
  "torch",
  "torqued",
  "totsugeki",
  "toxic",
  "traffic-tashkent",
  "tricked",
  "tropicaos",
  "trouble-makers",
  "tseg-taslal",
  "tsetsegchid",
  "tsm-impact",
  "tsm-shimmer",
  "tsunami-sirens",
  "turkey",
  "tuste-chopaki",
  "tutel",
  "twisted-minds",
  "typuctbl",
  "uganda",
  "uhka",
  "ukraine",
  "undefined",
  "underground",
  "ungentium",
  "union88",
  "unity",
  "unjustified",
  "unlucky-much-",
  "unluckymuch-",
  "unluko5",
  "uno-mille",
  "unpaid",
  "untouchables",
  "upcoming-galimi",
  "upnext",
  "usa-fe",
  "uzbekistan",
  "v1dar",
  "vagrants",
  "vanir",
  "vantage",
  "vaseline-worms",
  "vc",
  "vele-mostar",
  "velox",
  "vendetta",
  "veni-vidi-vici",
  "venom",
  "verdant",
  "versager-trupp",
  "verygold",
  "vex-dragons",
  "vexx",
  "vibe",
  "victores-sumus",
  "victorum",
  "vietnam",
  "vikings-kr",
  "villainous",
  "violet",
  "visu",
  "vnt",
  "vp-angels",
  "vp-prodigy",
  "vybn",
  "w7m-academy",
  "w7m-fe",
  "walkover",
  "wanted-goons",
  "washington-5m",
  "wdnmd",
  "websterz",
  "wg-bandits",
  "wheel-chair",
  "wicked",
  "wild",
  "windingo",
  "winter",
  "wisby-gymnasi1",
  "withoutwarning",
  "wizard",
  "wolsung",
  "wolves",
  "woofwoof",
  "wopa",
  "wu-tang",
  "ww",
  "xbl",
  "xgod",
  "xi",
  "xiao-pang",
  "xiaoma",
  "xmplfy",
  "xnl",
  "xvg-miracle",
  "yawara",
  "yeyo",
  "ynt",
  "yologang420",
  "young-gods",
  "yur",
  "zen",
  "zerovariant",
  "zespollah",
  "zeus",
  "zomblers",
  "zomblers-academy",
  "zuria"
];