import { BombData, PlayerFrameData, ProjectileData } from "./types";
import { mapInfo } from "./maps";

export function world_to_screen(pos: number[], mapName: string): number[] {
  const m = mapInfo[mapName as keyof typeof mapInfo];
  return [(pos[0] - m.x) * m.scale / m.resolution, (m.y - pos[1]) * m.scale / m.resolution, pos[2]];
}

function screen_to_world(screenPos: number[], mapName: string): number[] {
  const m = mapInfo[mapName as keyof typeof mapInfo];
  return [(screenPos[0] * m.resolution / m.scale) + m.x, (m.y - (screenPos[1] * m.resolution / m.scale)), screenPos[2]];
}

export function world_to_screen_size(size: number, mapName: string): number {
  const m = mapInfo[mapName as keyof typeof mapInfo];
  return size * m.scale / m.resolution;
}

export function interpolateBombFramesLinear(frameStart: BombData, frameEnd: BombData, t: number) {
  const p1 = {x: frameStart.x, y: frameStart.y, z: frameStart.z};
  const p2 = {x: frameEnd.x, y: frameEnd.y, z: frameEnd.z};

  const x = (1 - t) * p1.x + t * p2.x;
  const y = (1 - t) * p1.y + t * p2.y;
  const z = (1 - t) * p1.z + t * p2.z;

  return {...frameStart, 
    x, 
    y,
    z,
  }
}

export function interpolatePlayerFramesLinear(frameStart: PlayerFrameData, frameEnd: PlayerFrameData, t: number) {
  const p1 = {x: frameStart.x, y: frameStart.y, z: frameStart.z, viewX: frameStart.viewX};
  const p2 = {x: frameEnd.x, y: frameEnd.y, z: frameEnd.z, viewX: frameEnd.viewX};

  const x = (1 - t) * p1.x + t * p2.x;
  const y = (1 - t) * p1.y + t * p2.y;
  const z = (1 - t) * p1.z + t * p2.z;

  let start = (frameStart.viewX % 360 + 360) % 360;
  let end = (frameEnd.viewX % 360 + 360) % 360;
  if (Math.abs(end - start) > 180) {
      if (end > start) {
          start += 360;
      } else {
          end += 360;
      }
  }
  const viewX = (start + t * (end - start)) % 360;

  return {...frameStart, 
    x, 
    y,
    z,
    viewX,
  }
}

export function interpolateProjectileFramesLinear(frameStart: ProjectileData, frameEnd: ProjectileData, t: number) {
  const p1 = {x: frameStart.x, y: frameStart.y, z: frameStart.z};
  const p2 = {x: frameEnd.x, y: frameEnd.y, z: frameEnd.z};

  const x = (1 - t) * p1.x + t * p2.x;
  const y = (1 - t) * p1.y + t * p2.y;
  const z = (1 - t) * p1.z + t * p2.z;

  return {...frameStart, 
    x, 
    y,
    z,
  }
}

export function extrapolateProjectileFramesLinear(frameStart: ProjectileData, frameEnd: ProjectileData, t: number) {
  const velocity = {
    x: frameEnd.x - frameStart.x,
    y: frameEnd.y - frameStart.y,
    z: frameEnd.z - frameStart.z,
  };
  const p1 = {x: frameEnd.x, y: frameEnd.y, z: frameEnd.z};
  const p2 = {x: p1.x + velocity.x * t, y: p1.y + velocity.y * t, z: p1.z + velocity.z * t};

  return {...frameEnd, 
    x: p2.x, 
    y: p2.y,
    z: p2.z,
  };
}

function calculateRemainingTime(currentTick: number, startTick: number, tickRate: number) {
  const elapsedTicks = currentTick - startTick;
  const elapsedSeconds = Math.floor(elapsedTicks / tickRate);
  const remainingTime = Math.max(0, 115 - elapsedSeconds); // Assuming a fixed duration of 115 seconds
  return remainingTime;
}


export function getFormattedTime(currentTick: number, startTick: number, tickRate: number) {
  const formatTime = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60).toString().padStart(2, '0');
    const remainingSeconds = (seconds % 60).toString().padStart(2, '0');
    return `${minutes}:${remainingSeconds}`;
  };
  return formatTime(calculateRemainingTime(currentTick, startTick, tickRate));
}

export function calculateDistance(x1: number, y1: number, x2: number, y2: number) {
  return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
}

export function getColorByPercentage(percentage: number): string {
  if (percentage < 0 || percentage > 100) {
    return 'rgb(255, 255, 255)';
  }

  // Convert percentage to a value between 0 and 1
  const ratio = percentage / 100;

  // Interpolate the red and green values and make them lighter
  const red = Math.round(255 * (1 - ratio * 0.5)); // Reduced impact of ratio on red
  const green = Math.round(255 * (ratio * 0.5 + 0.5)); // Start from midway brightness and increase
  const blue = Math.round(255 * (0.5 - Math.abs(0.5 - ratio))); // Adds blue component in the middle of the transition

  // Return the color in RGB format
  return `rgb(${red}, ${green}, ${blue})`;
}
