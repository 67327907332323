import React from 'react';
import { mapInfo } from "../utils/maps";
import { world_to_screen, world_to_screen_size } from "../utils/math";
import { FireInfo } from "./MapView";


interface SVGFireProps {
  data: FireInfo;
  mapName: string;
  currentTick: number;
  upperView: boolean;
  scaleFactor: number;
  otherLevelOpacity: number;
  csVersion: string | undefined;
}

const flamePath = "m-0.0019382 0.48368c0.19991 0 0.36194-0.12065 0.36194-0.33178 0-0.090485-0.030162-0.24129-0.15081-0.36194 0.015081 0.090485-0.075404 0.12065-0.075404 0.12065 0.045243-0.15081-0.075404-0.36194-0.25637-0.3921 0.021535 0.12065 0.030162 0.24129-0.12065 0.36194-0.075404 0.060323-0.12065 0.16462-0.12065 0.27146 0 0.21113 0.16203 0.33178 0.36194 0.33178z";

export const SVGFire = ({data, mapName, currentTick, upperView, scaleFactor, otherLevelOpacity, csVersion}: SVGFireProps) => {
  const tickRate = csVersion === "cs2" ? 64 : 128;
  const position = world_to_screen([data.position[0], data.position[1], data.position[2]], mapName);

  const mInfo = mapInfo[mapName as keyof typeof mapInfo];
  const zOpacity = (data.position[2] - mInfo.minZ) / (mInfo.maxZ - mInfo.minZ);
  const opacity = Math.min(Math.max(upperView ? zOpacity : 1.0 - zOpacity, otherLevelOpacity), 1.0);

  const t = Math.max(Math.min((currentTick - data.startTick) / (data.endTick - data.startTick), 1.0), 0.0);
  const r = world_to_screen_size(115, mapName);
  const a = opacity * 0.2 + Math.sin(currentTick / 70) / 30;
  const progressAlpha = 0.5 * opacity;

  let color: number[] = [255, 64, 32, a];
  let iconColor = [255, 64, 32, opacity * 0.8];

  let progressColor = data.side === "T" ? [255, 157, 16, progressAlpha] : [17, 199, 256, progressAlpha];

  const progressIndicatorRadius = r*0.99;
  const circumference = 2 * Math.PI * progressIndicatorRadius;
  const strokeDasharray = `${(1.0 - t) * circumference} ${circumference}`;
  const iconScale = 21.0 / scaleFactor;
  const strokeWidth = 1.5 / scaleFactor;
  let secondsLeft = Math.min(Math.round((data.endTick - currentTick) / tickRate + 0.5), 7);
  let textColor = [64, 0, 0, opacity * 0.6]

  return (
    <>
      <circle
        key={`fire_${data.uniqueID}`}
        transform={`translate(${position[0]}, ${position[1]})`}
        r={r}
        fill={`rgb(${color[0]}, ${color[1]}, ${color[2]}, ${color[3]})`}
      />
      <circle
        key={`fire_indicator_${data.uniqueID}`}
        transform={`translate(${position[0]}, ${position[1]}) rotate(-90)`}
        r={progressIndicatorRadius}
        fill="none"
        stroke={`rgb(${progressColor[0]}, ${progressColor[1]}, ${progressColor[2]}, ${progressColor[3]})`}
        strokeWidth={strokeWidth}
        strokeDasharray={strokeDasharray}
        strokeLinecap="round"
      />
      <path
        key={`fire_icon_${data.uniqueID}`}
        transform={`translate(${position[0]}, ${position[1]}) scale(${iconScale})`}
        fill={`rgb(${iconColor[0]}, ${iconColor[1]}, ${iconColor[2]}, ${iconColor[3]})`}
        d={flamePath}
      />
      <text
        dominantBaseline="middle"
        textAnchor="middle"
        fontSize={10.0 / scaleFactor}
        y={4 / scaleFactor}
        fill={`rgba(${textColor[0]}, ${textColor[1]}, ${textColor[2]}, ${textColor[3]})`}
        transform={`translate(${position[0]}, ${position[1]})`}
      >
        {secondsLeft}
    </text>
    </>
  )
}