import { KillData } from "../utils/types";
import { allItemsSvg } from "../utils/weapons";

const Killfeed = ({data, currentTick} : {
  data: KillData[] | undefined,
  currentTick: number | undefined,
}) => {
  if (data !== undefined && currentTick !== undefined) {
    const maxTicks = 2000;
    const fadeoutTicks = 100;
    const kills: KillData[] = data.filter((k) => k.tick <= currentTick && k.tick > currentTick - maxTicks);    
    return <div className="kill-feed-wrapper">
      <table className="kill-feed-table">
        <tbody>
            {kills.slice(-4).map((kill) => 
              <tr key={`kill-${kill.attackerSteamID}-${kill.victimSteamID}`} style={{opacity: currentTick - kill.tick < maxTicks - fadeoutTicks ? "1.0" : 1.0 - (currentTick - kill.tick - (maxTicks - fadeoutTicks)) / fadeoutTicks}}>
              <td className={`kill-feed-player-${kill.attackerSide}`} style={{ textAlign: 'right' }}>{kill.attackerName}</td>
              <td style={{ textAlign: 'center' }}><img src={`${process.env.PUBLIC_URL}/items/${allItemsSvg[kill.weapon]}`} alt={kill.weapon} className={`kill-feed-weapon-icon`} /></td>
              <td className={`kill-feed-player-${kill.victimSide}`}>{kill.victimName}</td>
              </tr>
            )}
        </tbody>
      </table>
    </div>
  }
  return <></>;
};

export default Killfeed;