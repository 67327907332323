import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { TeamsData } from "../pages/Matches";
import { fetchFromApi } from "../utils/api";
import { team_names_equal } from "../utils/teams";
import { RoundMetadata, Side } from "../utils/types";
import TeamIcon from "./TeamIcon";

const TeamInfo = ({roundMetadata, side, minimal} : {
  roundMetadata: RoundMetadata | undefined,
  side: Side,
  minimal: boolean,
  }) => {

    if(minimal) {
      return null;
    }

    if(roundMetadata !== undefined) {
      const teamKey = side === Side.CT ? roundMetadata.ct_team_key : roundMetadata.t_team_key;
      const teamName = side === Side.CT ? roundMetadata.ct_team : roundMetadata.t_team;
      const teamScore = side === Side.CT ? roundMetadata.ct_score : roundMetadata.t_score;
      return (
        <div className={`info team-info rounded shadow`}>
          <div className={'team-info-wrapper mx-1 my-auto'}>
            <div className="flex-grow-1">
              <a href={`/filter?team=${teamKey}&mapname=${roundMetadata.mapname}`} className='team-info-link'>
                <TeamIcon size={20} name={teamKey} flag={undefined} />
                {teamName.toUpperCase()}
                <i className="bi bi-search team-info-hover-icon" style={{color: '#888', marginLeft: '10px'}}></i>
              </a>
            </div>
            <div>
              {teamScore}
            </div>
          </div>
        </div>
      )
    }
    return <div className={`info team-info rounded shadow`}>
      <div className={'team-info-wrapper m-1 mx-auto my-auto'}>
        <Spinner animation="border" style={{color: "#666"}}/>
      </div>
    </div>
  }

export default TeamInfo;