import React, { useState } from 'react';
import { Dropdown, FormControl, Spinner } from 'react-bootstrap';
import SourceIcon from './SourceIcon';

interface EventData {
  name: string;
  timestamp: number;
}

interface EventsData {
  [eventKey: string]: EventData;
}

interface EventPickerProps {
  isOpen: boolean;
  selectedEvent: string;
  events: EventsData;
  wideMode: boolean;
  isAuthenticated: boolean;
  onToggle: () => void;
  onSelect: (eventKey: string) => void;
}

const EventPicker: React.FC<EventPickerProps> = ({
  isOpen,
  selectedEvent,
  events,
  wideMode,
  isAuthenticated,
  onToggle,
  onSelect,
}) => {
  const [searchText, setSearchText] = useState("");
  const [filteredEvents, setFilteredEvents] = useState<EventsData | undefined>(undefined);

  const handleEventSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
    setFilteredEvents(
      Object.keys(events)
        .filter(eventName => eventName.toLowerCase().includes(event.target.value.toLowerCase()))
        .reduce((filtered, eventKey) => {
          filtered[eventKey] = events[eventKey];
          return filtered;
        }, {} as EventsData)
    );
  };

  const buttonClassName = wideMode ? 'matches-filter-button' : 'matches-filter-button-sm';

  return (
    <Dropdown
      className="events-dropdown"
      onToggle={onToggle}
      show={isOpen}
      onSelect={(eventKey: string | null) => onSelect(eventKey as string)}
    >
      <Dropdown.Toggle variant="secondary" id="dropdown-basic" className={buttonClassName} size="sm">
        {
          selectedEvent === 'Any' ? 'Events' :
            selectedEvent === 'hltv' ? <><SourceIcon eventKey={selectedEvent} size={14} />{'hltv.org'}</> :
              selectedEvent === 'esea' ? <><SourceIcon eventKey={'esea-league'} size={14} />{'ESEA'}</> :
                selectedEvent === 'user-upload' ? <><SourceIcon eventKey={selectedEvent} size={14} />{'My uploads'}</> :
                  selectedEvent in events ? <span><SourceIcon eventKey={selectedEvent} size={14} />{events[selectedEvent].name}</span> : <><span><Spinner animation="border" size="sm" style={{ marginRight: 15, color: "#555" }} />Loading...</span></>
        }
      </Dropdown.Toggle>
      <Dropdown.Menu className='events-dropdown-menu'>
        <FormControl
          type="text"
          placeholder="Search event"
          className="mb-2 events-search-input"
          onChange={handleEventSearch}
          value={searchText}
        />
        <Dropdown.Divider />
        <Dropdown.Item eventKey="Any">Any</Dropdown.Item>
        <Dropdown.Divider />
        {isAuthenticated && <>
          <Dropdown.Item eventKey="user-upload"><SourceIcon eventKey="user-upload" size={14} />My uploads</Dropdown.Item>
          <Dropdown.Divider />
        </>}
        <Dropdown.Item eventKey="hltv"><SourceIcon eventKey="hltv" size={14} />hltv.org</Dropdown.Item>
        <Dropdown.Item eventKey="esea"><SourceIcon eventKey="esea-league" size={14} />ESEA</Dropdown.Item>
        <Dropdown.Divider />
        {
          (filteredEvents ? Object.keys(filteredEvents) : Object.keys(events))
            .sort((a, b) => events[b].timestamp - events[a].timestamp)
            .map((event) => (
              <Dropdown.Item key={`event_${event}`} eventKey={event}>
                <SourceIcon eventKey={event} size={14} /><span>{events[event].name}</span>
              </Dropdown.Item>
            ))
        }
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default EventPicker;
