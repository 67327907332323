import React from 'react';
import { mapInfo } from "../utils/maps";
import { world_to_screen, world_to_screen_size } from "../utils/math";
import { SmokeInfo } from "./MapView";


interface SVGSmokeProps {
  data: SmokeInfo;
  mapName: string;
  currentTick: number;
  upperView: boolean;
  scaleFactor: number;
  otherLevelOpacity: number;
  csVersion: string | undefined;
}

export const SVGSmoke = ({data, mapName, currentTick, upperView, scaleFactor, otherLevelOpacity, csVersion}: SVGSmokeProps) => {
  const tickRate = csVersion === "cs2" ? 64 : 128;
  const position = world_to_screen([data.position[0], data.position[1], data.position[2]], mapName);

  const mInfo = mapInfo[mapName as keyof typeof mapInfo];
  const zOpacity = (data.position[2] - mInfo.minZ) / (mInfo.maxZ - mInfo.minZ);
  const opacity = Math.min(Math.max(upperView ? zOpacity : 1.0 - zOpacity, otherLevelOpacity), 1.0);

  const a = 0.7 * opacity + Math.sin(currentTick / 50) / 20;
  const r = world_to_screen_size(158, mapName);
  const t = Math.max(Math.min((currentTick - data.startTick) / (data.endTick - data.startTick), 1.0), 0.0);
  let color: number[] = [128, 128, 128, a];
  let progressColor = data.side === "T" ? [255, 157, 16, 0.7 * opacity] : [17, 199, 256, 0.7 * opacity];
  let textColor = [256, 256, 256, opacity * 0.25]
  let secondsLeft = Math.round((data.endTick - currentTick) / tickRate + 0.5);

  const progressIndicatorRadius = r*0.99;
  const circumference = 2 * Math.PI * progressIndicatorRadius;
  const strokeDasharray = `${(1.0 - t) * circumference} ${circumference}`;
  const strokeWidth = 1.5 / scaleFactor;

  return (
    <>
      <circle
        key={`smoke_${data.uniqueID}`}
        transform={`translate(${position[0]}, ${position[1]})`}
        r={r}
        fill={`rgb(${color[0]}, ${color[1]}, ${color[2]}, ${color[3]})`}
      />
      <circle
        key={`smoke_indicator_${data.uniqueID}`}
        transform={`translate(${position[0]}, ${position[1]}) rotate(-90)`}
        r={progressIndicatorRadius}
        fill="none"
        stroke={`rgb(${progressColor[0]}, ${progressColor[1]}, ${progressColor[2]}, ${progressColor[3]})`}
        strokeWidth={strokeWidth}
        strokeDasharray={strokeDasharray}
        strokeLinecap="round"
      />
      <text
        dominantBaseline="middle"
        textAnchor="middle"
        fontSize={12.0 / scaleFactor}
        y={2 / scaleFactor}
        fill={`rgba(${textColor[0]}, ${textColor[1]}, ${textColor[2]}, ${textColor[3]})`}
        transform={`translate(${position[0]}, ${position[1]})`}
      >
        {secondsLeft}
    </text>
    </>
  )
}