export class SeededRandom {
  private seed: number;

  constructor(seed: number) {
      this.seed = seed;
  }

  next(): number {
      // Simple pseudo-random function
      this.seed = (this.seed * 9301 + 49297) % 233280;
      return this.seed / 233280;
  }
}
