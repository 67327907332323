import { FrameData, PlayerFrameData, RoundData, RoundMetadata, Side } from "../utils/types";
import Player from "./Player";
import TeamInfo from "./TeamInfo";


const Team = ({ roundData, roundMetaData, currentTick, side, minimal, hoveredPlayer, onPlayerBeginHover, onPlayerEndHover }: {
  roundData: RoundData | undefined,
  roundMetaData: RoundMetadata | undefined,
  currentTick: number | undefined,
  side: Side,
  minimal: boolean,
  hoveredPlayer: number | undefined,
  onPlayerBeginHover: (steamID: number) => void,
  onPlayerEndHover: (steamID: number) => void,
}) => {  if (roundData !== undefined && currentTick !== undefined) {
    const startTick = roundData.frames[0].tick;
    const endTick = roundData.frames[roundData.frames.length - 1].tick;
    const tickStart: number = Math.max(...roundData.frames.map((f) => f.tick).filter((t) => t <= currentTick && t >= startTick && t < endTick));
    const frameStart: FrameData = roundData.frames.find((f) => f.tick === tickStart)!;
    const players = roundData.frames[0][side === Side.CT ? "ct" : "t"].players.sort((a, b) => a !== null && b !== null ? a.name.localeCompare(b.name) : 0)

    if (frameStart !== undefined) {
      return (
        <div className="w-100">
          {minimal ? null :
          <TeamInfo
            roundMetadata={roundMetaData}
            side={side}
            minimal={minimal}
          />
          }
          {players.map((player) => {
            const frameTeam = (side === Side.CT ? frameStart.ct : frameStart.t);
            const playerData = frameTeam.players.find((p: PlayerFrameData) => p.steamID === player.steamID);
            return <div
              key={`teaminfo_player_wrapper_${player.steamID}`}
              onMouseEnter={(event) => onPlayerBeginHover(player.steamID)}
              onMouseLeave={(event) => onPlayerEndHover(player.steamID)}
            >
              <Player key={`teaminfo_player_${player.steamID}`} data={playerData} side={side} minimal={minimal} hovered={hoveredPlayer === player.steamID} csVersion={roundData?.cs_version} />
            </div>
          })}
        </div>
      )
    }
  }

  return <div className="w-100" style={{paddingTop: '10px', borderTop: minimal ? '1px dashed rgb(64,64,64)' : ''}}>
    <TeamInfo
      roundMetadata={roundMetaData}
      side={side}
      minimal={minimal}
    />
    {[0, 1, 2, 3, 4].map((player) => {
      return <Player key={`player_${player}`} data={undefined} side={side} minimal={minimal} hovered={false} csVersion={roundData?.cs_version} />
    })}
  </div>
};

export default Team;