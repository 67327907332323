import React, { useState, useEffect, useRef } from "react";
import { Form, Dropdown } from "react-bootstrap";
import { TeamData, TeamsData } from "../pages/Matches";
import { fetchTeams } from "../utils/team_cache";
import TeamIcon from "./TeamIcon";

interface TeamNameInputProps {
  className?: string;
  value: string;
  disabled?: boolean;
  onChange?: (value: string) => void;
}

const TeamNameInput: React.FC<TeamNameInputProps> = ({ className, value, disabled, onChange }) => {
  const [teamName, setTeamName] = useState(value);
  const [teams, setTeams] = useState<TeamsData>(JSON.parse(sessionStorage.getItem('teamscache') || '{}'));
  const [suggestions, setSuggestions] = useState<TeamsData>({});
  const [showDropdown, setShowDropdown] = useState(false);
  const [userTyped, setUserTyped] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null); // added ref for the dropdown menu

  useEffect(() => {
    if (!sessionStorage.getItem('teamscache')) {
      fetchTeams().then((data) => {setTeams(data);})
    }
  }, []);

  useEffect(() => {
    if (teamName && userTyped) {
      const newSuggestions: TeamsData = {};

      for (let teamID in teams) {
        if (teams[teamID].name.toLowerCase().startsWith(teamName.toLowerCase())) {
          newSuggestions[teamID] = teams[teamID];
        }
      }

      setSuggestions(newSuggestions);
      setShowDropdown(true);
    } else {
      setSuggestions({});
      setShowDropdown(false);
    }
  }, [teamName, teams, userTyped]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setTeamName(value);
    setUserTyped(true);

    if(onChange) {
      onChange(value);
    }
  };

  const handleSelect = (teamKey: string) => {
    const value = teams[teamKey].name;
    setTeamName(value);
    setShowDropdown(false);
    setUserTyped(false);

    if(onChange) {
      onChange(value);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // if the down arrow key is pressed
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      if (dropdownRef.current && dropdownRef.current.firstChild) {
        (dropdownRef.current.firstChild as HTMLElement).focus(); // move focus to first item
      }
    }

    // if the enter key is pressed
    if (e.key === 'Enter') {
      e.preventDefault();
      setShowDropdown(false);
    }
  };

  return (
    <Dropdown onToggle={() => {}} show={showDropdown && Object.keys(suggestions).length > 0}>
      <Form.Control 
        type="text" 
        onChange={handleChange}
        onKeyDown={handleKeyDown} // handle key down events 
        value={teamName}
        className={className}
        placeholder="Please enter a team name"
        disabled={disabled}
      />
      <Dropdown.Menu ref={dropdownRef} style={{ minWidth: "285px" }}>
        {Object.keys(suggestions).map((teamKey) => (
            <Dropdown.Item key={`team_${teamKey}`} eventKey={teamKey} onClick={() => handleSelect(teamKey)}>
            <><TeamIcon size={14} name={teamKey} flag={teams[teamKey].flag} iconMargin={15} /><span>{teams[teamKey].name}</span></>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default TeamNameInput;
