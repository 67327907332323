import { useEffect, useState, useRef } from "react";
import { Dropdown, FormControl, Spinner } from "react-bootstrap";
import { TeamsData } from "../pages/Matches";
import { fetchTeams } from "../utils/team_cache";
import TeamIcon from "./TeamIcon";
import { hltvTeams } from "../utils/teams_hltv";
import { debounce } from 'lodash';
import styles from './TeamPicker.module.css';

const TeamPicker = ({ isOpen, selectedTeam, wideMode, canSelectAny, label, btnClassName, btnClassNameWide, onToggle, onSelect }: {
  isOpen: boolean,
  selectedTeam: string | undefined,
  wideMode: boolean,
  btnClassName: string,
  btnClassNameWide: string,
  canSelectAny: boolean,
  label: string,
  onToggle: (isOpen: boolean) => void,
  onSelect: (team: string) => void
}) => {

  const [teams, setTeams] = useState<TeamsData>(JSON.parse(sessionStorage.getItem('teamscache') || '{}'));
  const [searchText, setSearchText] = useState("");
  const [filteredTeams, setFilteredTeams] = useState<TeamsData | undefined>(undefined);
  const [isFiltering, setIsFiltering] = useState(false);
  const [recentlySelectedTeams, setRecentlySelectedTeams] = useState<string[]>([]);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [abortController, setAbortController] = useState<AbortController | null>(null);

  useEffect(() => {
    if (!sessionStorage.getItem('teamscache')) {
      fetchTeams().then((data) => { setTeams(data); })
    }
    if (isOpen) {
      setTimeout(() => searchInputRef.current?.focus(), 100);
    }
    // Load recently selected teams from local storage with the new key
    const storedRecentTeams = localStorage.getItem('cs2lens-recently-selected-teams');
    if (storedRecentTeams) {
      setRecentlySelectedTeams(JSON.parse(storedRecentTeams));
    }
  }, [isOpen]);

  const handleTeamSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = event.target.value.toLowerCase();
    setSearchText(searchValue);
    setIsFiltering(true);

    // Cancel the previous search if it exists
    if (abortController) {
      abortController.abort();
    }

    // Create a new AbortController for this search
    const newAbortController = new AbortController();
    setAbortController(newAbortController);

    const filterTeams = debounce(() => {
      if (searchValue === "") {
        setFilteredTeams(undefined);
      } else {
        // Define an array of high-ranking teams (adjust as needed)
        const sortedTeams = Object.keys(teams)
          .sort((a, b) => {
            const nameA = teams[a].name.toLowerCase();
            const nameB = teams[b].name.toLowerCase();

            // Priority 1: Starts with search string
            if (nameA.startsWith(searchValue) && !nameB.startsWith(searchValue)) return -1;
            if (nameB.startsWith(searchValue) && !nameA.startsWith(searchValue)) return 1;

            // Priority 2: Word starts with search string
            const wordsStartA = nameA.split(' ').some(word => word.startsWith(searchValue));
            const wordsStartB = nameB.split(' ').some(word => word.startsWith(searchValue));
            if (wordsStartA && !wordsStartB) return -1;
            if (wordsStartB && !wordsStartA) return 1;

            // Priority 3: High ranking teams
            const rankA = hltvTeams.indexOf(a);
            const rankB = hltvTeams.indexOf(b);
            if (rankA !== -1 && rankB === -1) return -1;
            if (rankB !== -1 && rankA === -1) return 1;
            if (rankA !== -1 && rankB !== -1) return rankA - rankB;

            // Default: Alphabetical order
            return nameA.localeCompare(nameB);
          })
          .filter(team => teams[team].name.toLowerCase().includes(searchValue))
          .slice(0, 100); // Limit to 100 results

        setFilteredTeams(
          sortedTeams.reduce((filtered, team) => {
            filtered[team] = teams[team];
            return filtered;
          }, {} as TeamsData)
        );
      }
      setIsFiltering(false);
    }, 200);

    filterTeams();

    // If aborted, cancel the debounced function
    newAbortController.signal.addEventListener('abort', () => {
      filterTeams.cancel();
    });
  };

  const handleTeamSelect = (team: string) => {
    onSelect(team);
    // Update recently selected teams, excluding "Any" and empty values
    if (team && team !== "Any") {
      const updatedRecentTeams = [team, ...recentlySelectedTeams.filter(t => t !== team)].slice(0, 16);
      setRecentlySelectedTeams(updatedRecentTeams);
      // Save to local storage with the new key
      localStorage.setItem('cs2lens-recently-selected-teams', JSON.stringify(updatedRecentTeams));
    }
  };

  const buttonClassName = wideMode ? btnClassNameWide : btnClassName;

  const handleRemoveRecentTeam = (teamToRemove: string, event: React.MouseEvent) => {
    event.stopPropagation();
    const updatedRecentTeams = recentlySelectedTeams.filter(team => team !== teamToRemove);
    setRecentlySelectedTeams(updatedRecentTeams);
    localStorage.setItem('cs2lens-recently-selected-teams', JSON.stringify(updatedRecentTeams));
  };

  return (
    <Dropdown
      className="teams-dropdown"
      onToggle={onToggle}
      show={isOpen}
      onSelect={(eventKey: any, event: Object) => handleTeamSelect(eventKey)}
      key="teams-dropdown"
    >
      <Dropdown.Toggle variant="secondary" id="dropdown-basic" className={buttonClassName} size={"sm"}>
        {selectedTeam && selectedTeam !== 'Any' ? (
          <>
            {selectedTeam in teams ? (
              <TeamIcon 
                size={14} 
                name={selectedTeam} 
                flag={selectedTeam in teams ? teams[selectedTeam].flag : undefined} 
              />
            ) : <TeamIcon 
            size={14} 
            name={''} 
            flag={undefined} 
          />}
            <span>
              {Object.keys(teams).length === 0 ? (
                <>
                  <Spinner 
                    animation="border" 
                    size="sm" 
                    style={{ marginRight: 15, color: "#555" }} 
                  />
                  Loading...
                </>
              ) : (
                selectedTeam in teams ? teams[selectedTeam].name : selectedTeam
              )}
            </span>
          </>
        ) : label}
      </Dropdown.Toggle>
      <Dropdown.Menu className='teams-dropdown-menu'>
        <FormControl
          type="text"
          placeholder="Search team"
          className="mb-2 teams-search-input"
          onChange={handleTeamSearch}
          value={searchText}
          ref={searchInputRef}
        />
        <Dropdown.Divider />
        {canSelectAny &&
          <>
            <Dropdown.Item key={`team_Any`} eventKey={"Any"}>Any</Dropdown.Item>
            <Dropdown.Divider />
          </>
        }
        {isFiltering && <div className="small mb-2 m-3" style={{color: '#777'}}><Spinner size="sm" style={{marginRight: '10px'}}/>Loading...</div>}
        {searchText === "" && !isFiltering && (
          <>
            {recentlySelectedTeams.length > 0 ? (
              <>
                <div className="small mb-2 m-3" style={{color: '#777'}}>Recently selected</div>
                {recentlySelectedTeams.map((team) => (
                  <Dropdown.Item key={`recent_${team}`} eventKey={team} className="d-flex justify-content-between align-items-center">
                    <div>
                      <TeamIcon size={14} name={team} flag={teams[team]?.flag} iconMargin={15} />
                      <span>{teams[team]?.name}</span>
                    </div>
                    <i
                      className={`bi bi-x ${styles.removeIcon}`}
                      onClick={(e) => handleRemoveRecentTeam(team, e)}
                    />
                  </Dropdown.Item>
                ))}
              </>
            ) : (
              <div className="small mb-2 m-3" style={{color: '#777'}}>Type to search teams</div>
            )}
          </>
        )}
        {(filteredTeams && !isFiltering ? Object.keys(filteredTeams) : []).map((team) => (
          <Dropdown.Item key={`team_${team}`} eventKey={team}>
            <><TeamIcon size={14} name={team} flag={teams[team].flag} iconMargin={15} /><span>{teams[team].name}</span></>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default TeamPicker;
