import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { Button, Modal, Spinner, Form, Alert } from 'react-bootstrap';
import CustomModalHeader from './CustomModalHeader';
import styles from './CooldownPopup.module.css';
import { fetchFromApi } from '../utils/api';
import { post_usage } from '../utils/usage';

const CooldownPopup = () => {
  const [submitting, setSubmitting] = useState(false);
  const [seconds, setSeconds] = useState(59);
  const [cooldownOver, setCooldownOver] = useState(false); // State to track if cooldown is over
  const [show, setShow] = useState(true);

  useEffect(() => {
    post_usage(`cooldown_popup`);
  }, []);

  useEffect(() => {
    if (seconds > 0) {
      const timer = setTimeout(() => setSeconds(seconds - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      setCooldownOver(true); // Set cooldownOver to true once countdown finishes
    }
  }, [seconds]);

  const handleSubmit = async (event: React.FormEvent) => {
    setSubmitting(true);
    post_usage(`cooldown_continue`);
    fetchFromApi(`/cooldown`, {
      method: 'POST',
      body: JSON.stringify({
        device_id: localStorage.getItem('cs2lens-deviceid'),
      }),
    })
    .then((response) => {
      setSubmitting(false);
      setShow(false);
    })
    .catch((error) => {
      console.error('Error:', error);
      setSubmitting(false);
      setShow(false);
    });
  };


  const handleLogout = async (event: React.FormEvent) => {
    try {
      post_usage(`cooldown_logout`);
      await Auth.signOut();
      window.location.href = "/";
    } catch (error) {
      console.log('Error signing out: ', error);
    }
  }

  return (
    <Modal show={show}>
      <CustomModalHeader hideCloseButton={true} />
      <div style={{ margin: '25px' }} className={styles.content}>
        <p>Hello there! 👋</p>
        <p>Your account has been recently used from another device.</p>
        <p>It's absolutely fine to use your account across multiple devices; however, sharing an account goes against our policy. You'll be able to continue from this device after a brief pause.</p>
        <p>We offer an <span style={{ color: '#87b1ff', fontWeight: 'bold' }}>organizational license</span> for $49/mo (+VAT) with unlimited user accounts and <span style={{color: '#f8c', fontWeight: '700'}}>voice comms</span> integration.</p>
        <p>If you're interested, please visit our <a href="/subscribe/org">organization subscription page</a> for more information.</p>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginLeft: '25px', marginRight: '25px', marginBottom: '25px' }}>
        {
          cooldownOver ? (
            <Button
              variant="success"
              onClick={handleSubmit} // Adjust as needed to handle the "Continue" action
            >
              {submitting ? <Spinner animation="border" size="sm" style={{ marginRight: 10 }} /> : <i className="bi bi-arrow-right" style={{ marginRight: 10 }} />}Continue
            </Button>
          ) : (
            <span style={{ position: 'relative', top: '5px', color: '#bbb' }}>Please continue after <span style={{ color: '#fff' }}>{seconds}</span> seconds.</span>
          )
        }
        <Button
          variant="primary"
          onClick={handleLogout}
        >
          {<i className="bi bi-door-closed" style={{ marginRight: 10 }} />}Logout
        </Button>
      </div>
    </Modal>
  );
}
export default CooldownPopup;

