import { mapInfo } from "../utils/maps";
import { world_to_screen } from "../utils/math";
import { projectileShapes } from "../utils/projectile";
import { DamageData, PlayerFrameData, PlayerGizmos } from "../utils/types";


const SVGPlayer = ({ data, playerName, currentTick, scaleFactor, damage, mapName, upperView, playerGizmos, shadow, activePlayer }: {
  data: PlayerFrameData,
  playerName: string,
  currentTick: number,
  scaleFactor: number,
  damage: DamageData | undefined,
  mapName: string,
  upperView: boolean,
  playerGizmos: PlayerGizmos,
  shadow: boolean,
  activePlayer?: boolean | undefined,
}) => {
  const angle = -data.viewX + 90;
  const position = world_to_screen([data.x, data.y, data.z], mapName);

  const mInfo = mapInfo[mapName as keyof typeof mapInfo];
  const zOpacity = (data.z - mInfo.minZ) / (mInfo.maxZ - mInfo.minZ);
  const opacity = Math.min(Math.max(upperView ? zOpacity : 1.0 - zOpacity, 0.2), 1.0);

  let shadowColor = [0, 0, 0, 0.33 * opacity];
  let color = data.side === "T" ? [255, 157, 16, opacity] : [14, 170, 225, opacity];
  let damageCoeff = 0;
  let textColor = data.isAlive ? [0, 0, 0, opacity] : [0, 0, 0, opacity * 0.2];

  if (!data.isAlive) {
    color = color.map((c) => c * 0.2 + 48);
  }
  let stroke: number[] = data.isAlive ? [255, 255, 255, opacity] : [64, 64, 64, opacity];
  if (data.isBlinded && data.isAlive) {
    color = data.side === "T" ? [250, 240, 200, color[3]] : [180, 240, 256, color[3]];
    textColor = [180, 180, 180, opacity];
  }
  if (data.hasBomb && data.isAlive && data.side === "T") {
    stroke = [255, 255, 255, stroke[3]];
    if (!data.isBlinded) {
      textColor = [255, 255, 255, 1];
      color = [200, 100, 64, color[3]]
    }
  }

  var projectileContent = undefined;
  if (data.isAlive && data.activeWeapon in projectileShapes && (data.prevProjectileThrowTick === undefined || data.prevProjectileThrowTick < currentTick - 50)) {
    const projectileShape = projectileShapes[data.activeWeapon];
    var size = 650.0 / scaleFactor;
    
    const maxSwingAngle = 40; // Maximum deviation from the original angle during the swing
    const swingDuration = 20;  // Number of ticks over which the swing happens. Adjust as needed.
    var projectileAngle = 20;
    var projectileOffset = [size * 0.01, size * -0.005];
    if (data.nextProjectileThrowTick !== undefined && data.nextProjectileThrowTick - currentTick <= swingDuration) {
        const progress = 1.0 - (data.nextProjectileThrowTick - currentTick) / (swingDuration * 2.0);
        const swing = maxSwingAngle * Math.sin(Math.PI * progress * progress);
        projectileAngle += swing;
        size *= 1.0 - 0.15 * progress;
    }

    const z = 0;
    const s = 0.1;
    const r = Math.min(Math.max(0.025 + (z / 50000), 0), 0.05) * size;
    const rs = Math.min(Math.max(0.025 + (z / 25000), 0), 0.1) * size;
    const extraMultiplier = data.side === "T" ? 1.5 : 10.0;
    const projectileColor = data.side === "T" ? [255, 157, 16, opacity] : [17, 199, 256, opacity];
    const projectileStroke: number[] = [color[0] * s, color[1] * s, color[2] * s, color[3]];
        
    const projectilePosition = [position[0] + projectileOffset[0], position[1] + projectileOffset[1]];
    const extraColor = [color[0] * extraMultiplier, color[1] * extraMultiplier, color[2] * extraMultiplier, color[3]];
    const height = size * 0.003;

    let shadow = [0, 0, 0, 0.25];
    projectileContent = <>
      <path
        transform={`translate(${projectilePosition[0]}, ${projectilePosition[1]}) scale(${rs}, ${rs}) translate(0, ${height}) rotate(${projectileAngle}, 0.0, 0.0) translate(0, ${-height})`}
        d={projectileShape['main']}
        fill={`rgba(${shadow[0]}, ${shadow[1]}, ${shadow[2]}, ${shadow[3]})`}
        style={{ filter: `blur(${z / 5000}px)` }}
      />
      <path
        transform={`translate(${projectilePosition[0]}, ${projectilePosition[1]}) scale(${r}, ${r}) translate(0, ${height}) rotate(${projectileAngle}, 0.0, 0.0) translate(0, ${-height})`}
        d={projectileShape['main']}
        fill={'none'}
        stroke={`rgba(${projectileStroke[0]}, ${projectileStroke[1]}, ${projectileStroke[2]}, ${projectileStroke[3]})`}
        strokeWidth={(0.1)}
      />
      <path
        transform={`translate(${projectilePosition[0]}, ${projectilePosition[1]}) scale(${r}, ${r}) translate(0, ${height}) rotate(${projectileAngle}, 0.0, 0.0) translate(0, ${-height})`}
        d={projectileShape['main']}
        fill={`rgba(${projectileColor[0]}, ${projectileColor[1]}, ${projectileColor[2]}, ${projectileColor[3]})`}
      />
      <path
        transform={`translate(${projectilePosition[0]}, ${projectilePosition[1]}) scale(${r}, ${r}) translate(0, ${height}) rotate(${projectileAngle}, 0.0, 0.0) translate(0, ${-height})`}
        d={projectileShape['extra']}
        fill={`rgba(${extraColor[0]}, ${extraColor[1]}, ${extraColor[2]}, ${extraColor[3]})`}
      />
    </>
  }

  if (damage !== undefined) {
    const t = currentTick - damage.tick;
    damageCoeff = Math.min(Math.max((50 - t * 1.0) / 50, 0), 1);
    const m = damageCoeff;
    stroke = [192 * m + stroke[0] * (1 - m), 0 * m + stroke[1] * (1 - m), 0 * m + stroke[2] * (1 - m), opacity];
  }

  const showArmor = data.isAlive && !data.isBlinded && playerGizmos === 'Default';
  let armor = "FULL";
  if (data.isAlive && !data.hasHelmet && data.armor === 0) {
    armor = "NONE";
    //color = color.map((c) => c * 0.1 + 96);
  }
  else if (data.isAlive && !data.hasHelmet && data.armor > 0) {
    armor = "KEVLAR";
    //color = color.map((c) => c * 0.3 + 64);
  }

  const strokePath = "m0.33405 0.001171c0 0.18569-0.15053 0.33622-0.33622 0.33622-0.18569 0-0.33622-0.15053-0.33622-0.33622-1.1466e-4 -0.21045 0.064978-0.26849 0.33672-0.50122 0.26497 0.23207 0.33244 0.28608 0.33572 0.50122z";
  const insidePath = "m0.26765 0.001171a0.26982 0.26982 0 0 1-0.26982 0.26982 0.26982 0.26982 0 0 1-0.26982-0.26982 0.26982 0.26982 0 0 1 0.26982-0.26982 0.26982 0.26982 0 0 1 0.26982 0.26982z";
  const r = 27.0 / scaleFactor;
  const shadowDistance = 0.1 * r;

  if(activePlayer) {
    color = [0, 160, 0, opacity];
  }

  return (
    <>
      {data.isAlive ?
        <g id={`player_${playerName}`}>
          {shadow && <path
            id={`player_${playerName}_shadow`}
            style={{ fill: `rgb(${shadowColor[0]}, ${shadowColor[1]}, ${shadowColor[2]}, ${shadowColor[3]})` }}
            transform={`translate(${position[0] + shadowDistance}, ${position[1] + shadowDistance}) rotate(${angle}, 0.0, 0.0) scale(${r})`}
            d={strokePath} />
          }
          <path
            id={`player_${playerName}_stroke`}
            style={{ fill: `rgb(${stroke[0]}, ${stroke[1]}, ${stroke[2]}, ${stroke[3]})` }}
            transform={`translate(${position[0]}, ${position[1]}) rotate(${angle}, 0.0, 0.0) scale(${r})`}
            d={strokePath} />
          <path
            id={`player_${playerName}_inside`}
            style={{ fill: `rgb(${color[0]}, ${color[1]}, ${color[2]}, ${color[3]})` }}
            transform={`translate(${position[0]}, ${position[1]}) rotate(${angle}, 0.0, 0.0) scale(${r})`}
            d={insidePath} />
          {showArmor && armor === "KEVLAR" && <circle
            id={`player_${playerName}_armor_kevlar`}
            stroke={`rgb(0, 0, 0, ${opacity * 0.4})`}
            fill={'none'}
            strokeWidth={0.1}
            transform={`translate(${position[0]}, ${position[1]})`}
            r={r * 0.12} />}
          {showArmor && armor === "FULL" && <circle
            id={`player_${playerName}_armor_none`}
            fill={`rgb(255, 255, 255, ${opacity * 0.75})`}
            strokeWidth={0.1}
            transform={`translate(${position[0]}, ${position[1]})`}
            r={r * 0.12} />}
          {playerGizmos === 'Minimal' ?
            <text
              x={`${position[0]}`}
              y={`${position[1] + 0.092 * r}`}
              fontSize={r * 0.27}
              fontWeight={600}
              fill={`rgb(${textColor[0]}, ${textColor[1]}, ${textColor[2]}, ${textColor[3]})`}
              textAnchor="middle"
              style={{ userSelect: 'none' }}
            >
              {playerName.charAt(0).toUpperCase()}{playerName.charAt(1).toUpperCase()}
            </text> : null}
          {projectileContent}
        </g> :
        <circle
          transform={`translate(${position[0]}, ${position[1]})`}
          r={r * 0.25}
          stroke={`rgb(${stroke[0]}, ${stroke[1]}, ${stroke[2]}, ${stroke[3]})`}
          strokeWidth={r * 0.04}
          fill={`rgb(${color[0]}, ${color[1]}, ${color[2]}, 1)`}
        />}
    </>
  )
};

export default SVGPlayer;