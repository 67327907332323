import React, { MouseEventHandler, useState } from 'react';
import styles from './AnalyseProjectilesList.module.css'; // Adjust the import path to your CSS module
import { getColorByPercentage, getFormattedTime } from '../utils/math';
import { CachedProjectiles } from '../utils/projectile';
import { utilityItemsSvg } from '../utils/weapons';
import TeamIcon from './TeamIcon';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Playlist, RoundsMetadata } from '../utils/types';

interface AnalyseProjectilesListProps {
  selectedProjectiles: CachedProjectiles | undefined;
  currentPlaylist: Playlist,
  totalRoundCount: number,
  mapName: string;
  csVersion: string;
  onRemoveProjectile: (key: number) => void;
  onHoverProjectile: (key: number | undefined) => void;
  onClickProjectile: (key: number, roundId: string, throwerSteamId: number) => void;
}

const zeroPad = (num: number, places: number) => String(num).padStart(places, '0')

const AnalyseProjectilesList: React.FC<AnalyseProjectilesListProps> = ({ selectedProjectiles, currentPlaylist, totalRoundCount, mapName, csVersion, onRemoveProjectile, onHoverProjectile, onClickProjectile }) => {
  const navigate = useNavigate();
  const [hoveredProjectile, setHoveredProjectile] = useState<number | undefined>(undefined);

  if (!selectedProjectiles || Object.keys(selectedProjectiles).length === 0) {
    return null;
  }

  function onHandleReplayClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    if (selectedProjectiles) {
      // Step 1: Find unique matchId + roundNum combinations from selectedProjectiles
      const uniqueMatches = new Set();
      for (const projectileInfo of Object.values(selectedProjectiles)) {
        const matchRoundCombo = `${projectileInfo.matchId}-${projectileInfo.roundNum}`;
        uniqueMatches.add(matchRoundCombo);
      }

      // Step 2: Filter currentPlaylist based on uniqueMatches
      const newRoundsMetadata: RoundsMetadata = {};
      for (const [matchId, rounds] of Object.entries(currentPlaylist.rounds)) {
        newRoundsMetadata[matchId] = rounds.filter(round =>
          uniqueMatches.has(`${matchId}-${round.roundnum}`)
        );
      }

      // Step 3: Create newPlaylist with filtered data
      const newPlaylist = {
        rounds: newRoundsMetadata,
        external: false
      };

      sessionStorage.setItem('playlist', JSON.stringify(newPlaylist));

      e.preventDefault(); // Prevent the default action once, outside the loop to cover all cases

      // Find the first match with a valid round
      let foundMatchId = null;
      let foundRoundNum = undefined;

      for (const matchId of Object.keys(newPlaylist.rounds)) {
        const rounds = newPlaylist.rounds[matchId];
        if (rounds && rounds.length > 0) {
          foundMatchId = matchId;
          foundRoundNum = rounds[0].roundnum;
          break; // Exit the loop once a valid match and round are found
        }
      }

      // Navigate to the match and round if found
      if (foundMatchId && foundRoundNum !== undefined) {
        window.open(`/match/${foundMatchId}/${mapName}/${foundRoundNum}`, '_blank');
      }
    }
  }

  // Calculate unique combos for matchId, mapname, roundnum
  function countUniqueRounds(projectiles: CachedProjectiles): number {
    const uniqueRounds = new Set<string>();

    for (const projectileId in projectiles) {
      const projectile = projectiles[projectileId];
      const uniqueRoundKey = `${projectile.matchId}-${projectile.mapName}-${projectile.roundNum}`;
      uniqueRounds.add(uniqueRoundKey);
    }

    return uniqueRounds.size;
  }

  // Calculate unique combos for matchId, mapname, roundnum
  function percentageOfRounds(projectiles: CachedProjectiles): number {
    const uniqueRounds = new Set<string>();

    for (const projectileId in projectiles) {
      const projectile = projectiles[projectileId];
      const uniqueRoundKey = `${projectile.matchId}-${projectile.mapName}-${projectile.roundNum}`;
      uniqueRounds.add(uniqueRoundKey);
    }

    return Math.round(uniqueRounds.size / totalRoundCount * 100);
  }

  const handleClickProjectile = (key: number, ctrlKey: boolean, altKey: boolean) => {
    const projectileInfo = selectedProjectiles[key];
    if (ctrlKey) {
      window.open(`/match/${projectileInfo.matchId}/${mapName}/${projectileInfo.roundNum}`, '_blank')
    } else if (altKey) {
      onRemoveProjectile(key);
      setHoveredProjectile(undefined);
    } else {
      onClickProjectile(key, `${projectileInfo.matchId}.${projectileInfo.mapName}.${projectileInfo.roundNum}`, projectileInfo.thrower.steamID);
    }
  }

  const handleHoverProjectile = (key: number | undefined) => {
    setHoveredProjectile(key);
    onHoverProjectile(key);
  }

  // Convert object keys to an array, then sort based on startTick
  const sortedKeys = Object.keys(selectedProjectiles)
    .map(key => selectedProjectiles[Number(key)])
    .sort((a, b) => a.startTick - b.startTick)
    .map(projectile => projectile.uniqueID); // Assuming uniqueID is the same as the key

  return (
    <div className={styles.scrollableContainer}>
      <table className={styles.projectileTable}>
        <thead>
          <tr>
            <th>Player</th>
            <th>Time</th>
            <th>Rnd</th>
            <th>Vs</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {sortedKeys.map((key) => {
            const projectileInfo = selectedProjectiles[Number(key)];
            if (projectileInfo) {
              return (
                <tr key={key}
                  onMouseUp={(e) => handleClickProjectile(key, e.ctrlKey, e.altKey)}
                  onMouseEnter={() => handleHoverProjectile(key)}
                  onMouseLeave={() => handleHoverProjectile(undefined)}
                  className={styles.projectileRow}
                >
                  <td className={styles.playerCell}>
                    <div>
                      <TeamIcon
                        name={projectileInfo.teamKey}
                        size={16}
                      />
                      {projectileInfo.thrower.name}
                    </div>
                  </td>
                  <td className={styles.centeredCell}>{getFormattedTime(projectileInfo.startTick, 0, csVersion === "csgo" ? 128 : 64)}</td>
                  <td className={styles.centeredCell}>{zeroPad(projectileInfo.roundNum, 2)}</td>
                  <td className={styles.centeredCell}><TeamIcon
                    name={projectileInfo.opponentKey}
                    size={16}
                  />
                  </td>
                  <td className={styles.centeredCell}>
                    <img
                      src={`${process.env.PUBLIC_URL}/items/${utilityItemsSvg[projectileInfo.projectileType]}`}
                      alt={projectileInfo.projectileType}
                      className={`${styles.weaponIcon} ${projectileInfo.thrower.side === "T" ? styles.weaponIconT : styles.weaponIconCT}`}
                    />
                  </td>
                </tr>
              );
            }
            return null;
          })}
          <tr key={"counts-projectile-table"}>
            <td colSpan={5} className={styles.totalCountsRow}>
              <span className={styles.countNumber}>{Object.keys(selectedProjectiles).length}</span> throws, <span className={styles.countNumber}>{countUniqueRounds(selectedProjectiles)}</span> rounds (<span style={{ color: getColorByPercentage(percentageOfRounds(selectedProjectiles)) }} className={styles.percentageNumber}>{percentageOfRounds(selectedProjectiles)}%</span>)
            </td>
          </tr>
          <tr key={"replay-button-for-projectile-table"} className={styles.infoFooter}>
            <td className={styles.replayButtonRow} colSpan={5}>
              <Button
                style={{
                  position: "relative",
                  height: 38,
                  paddingLeft: '20px',
                  paddingRight: '26px',
                }}
                className="btn-sm"
                onClick={onHandleReplayClick}
              >
                <i className="bi bi-play-fill" style={{ marginRight: '4px' }}></i>Replay all
              </Button>
            </td>
          </tr>
          <tr key={"info-for-projectile-table"} className={styles.infoFooter}>
            <td className={styles.infoFooter} colSpan={5} style={{ paddingTop: '20px' }}>
              <div>
                ALT + click to remove an item
              </div>
            </td>
          </tr>
          <tr key={"info-for-projectile-table-3"} className={styles.infoFooter}>
            <td className={styles.infoFooter} colSpan={5} style={{ paddingTop: '20px' }}>
              <div>
                Tip: Switch to Default or Heatmap mode to analyse the selected rounds only.
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default AnalyseProjectilesList;