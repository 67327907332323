import { Col, Container, Row } from "react-bootstrap";
import styles from "./Checkout.module.css";

const CheckoutCancel = () => {
  return (
    <div className={`container ${styles.checkoutContainer}`}>
      <Container fluid>
        <Row>
          <Col>
            <h1><i className="bi bi-x" style={{color: '#d00'}}></i> Checkout Cancelled!</h1>
          </Col>
        </Row>
        <hr />
        <Row className={styles.myrow}>
          <Col>
            <p>We noticed that you cancelled your checkout process before completing your subscription.</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CheckoutCancel;