import { countryFlags } from "../utils/country_flags";
import { team_logos } from "../utils/team_logos";
import Icon from "./Icon";

const TeamIcon = ({name, flag, size, flagMargin, iconMargin, opacity} : {
    name: string,
    flag?: string,
    size: number,
    flagMargin?: number,
    iconMargin?: number,
    opacity?: number,
  }) => {
  if(!flag && team_logos.includes(name)) {
    return <Icon 
      name={name}
      size={size}
      url={`https://cs2lens-team-logos.s3.eu-west-1.amazonaws.com/${name}.png`}
      shadowStrength={0.8}
      shadowDistance={1}
      margin={iconMargin ?? 10}
      opacity={opacity ?? 1}
    />;
  }
  else {
    if (flag && flag in countryFlags) {
      return <span style={{marginRight: flagMargin ?? 10, opacity: opacity ?? 1}}>{countryFlags[flag]}</span>
    }
    return <Icon 
      prefix={""}
      name={"question-mark.png"}
      postfix={""}
      size={size}
      shadowStrength={0.2}
      shadowDistance={1}
      margin={iconMargin ?? 10}
  />
  }
}

export default TeamIcon;