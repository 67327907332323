import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { Button, Modal, Form, Alert } from 'react-bootstrap';
import CustomModalHeader from './CustomModalHeader';

type SignUpButtonProps = {
  onSignUp: () => void;
  onClick?: () => void;
  fullWidth?: boolean;
  label?: string;
};

const SignUpButton: React.FC<SignUpButtonProps> = ({ onSignUp, onClick, fullWidth }) => {
  const [showSignUp, setShowSignUp] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [teamName, setTeamName] = useState("");
  const [confirmationCode, setConfirmationCode] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [signUpError, setSignUpError] = useState("");
  const [confirmationError, setConfirmationError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [sendButtonText, setSendButtonText] = useState('Send again');
  const [confirmationMessage, setConfirmationMessage] = useState('');

  const handleSignUp = async () => {
    if (password !== confirmPassword) {
      setPasswordError("Passwords do not match");
      return;
    }

    try {
      const { user } = await Auth.signUp({
        username: email,
        password,
        attributes: {
          email,
          'custom:team': teamName,
        }
      });

      console.log('User signed up successfully', user);
      setShowSignUp(false);
      setShowConfirmation(true);
    } catch (error: any) {
      console.log('Error signing up', error);
      setSignUpError(error.message);
    }
  };

  const handleConfirmation = async () => {
    try {
      await Auth.confirmSignUp(email, confirmationCode);
      setConfirmationError("");
      setConfirmationMessage('Email confirmed!');
  
      // Automatically sign in the user after a successful confirmation
      try {
        const { user } = await Auth.signIn(email, password);
        console.log('User signed in automatically', user);
        onSignUp();
      } catch (signInError: any) {
        console.log('Error signing in', signInError);
      }
  
      // Close the modal after 3 seconds
      setTimeout(() => {
        setShowConfirmation(false);
        setConfirmationMessage(''); // Clear the confirmation message
      }, 3000);
    } catch (error: any) {
      console.log('Error confirming account', error);
      setConfirmationMessage('Error confirming account');
      setConfirmationError(error.message);
    }
  };

  const handleModalClose = () => {
    setShowSignUp(false);
    setShowConfirmation(false);
    setSignUpError("");
  };

  const handleModalOpen = () => {
    if(onClick) {
      onClick();
    }
    setShowSignUp(true);
  };

  const handleSendConfirmationCodeAgain = async () => {
    try {
      setIsLoading(true);
      setSendButtonText('Sent');
      await Auth.resendSignUp(email);
      setTimeout(() => {
        setIsLoading(false);
        setSendButtonText('Send again');
      }, 3000); // Change label back after 3 seconds
    } catch (error: any) {
      setIsLoading(false);
      setSendButtonText('Send again');
      console.log('Error resending confirmation code', error);
    }
  }; 

  return (
    <>
      <Button variant="success" size="sm" onClick={handleModalOpen} style={{width: fullWidth ? '100%' : ''}}>
      <i className='bi bi-person' style={{marginRight: '3px'}} /> Sign Up
      </Button>

      <Modal show={showSignUp} onHide={handleModalClose}>
        <CustomModalHeader />
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" placeholder="Enter email" onChange={(e) => setEmail(e.target.value)} autoFocus />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Team / Organization</Form.Label>
              <Form.Control type="text" placeholder="Enter team name" onChange={(e) => setTeamName(e.target.value)} />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control type="password" placeholder="Confirm Password" onChange={(e) => setConfirmPassword(e.target.value)} />
            </Form.Group>

            {passwordError && <Alert variant="danger">{passwordError}</Alert>}
            {signUpError && <Alert variant="danger">{signUpError}</Alert>}

            <Button variant="primary" onClick={handleSignUp} style={{width: '100%'}}>
              <i className='bi bi-person' style={{marginRight: '3px'}} /> Create account
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={showConfirmation} onHide={handleModalClose}>
        <CustomModalHeader />
        <Modal.Body>
          <Form>
            <div style={{marginBottom: '10px'}}>
              We've sent the confirmation code to:<br /><code>{email}</code>
            </div>
            <Form.Group className="mb-3">
              <Form.Control
                type="text"
                placeholder="Enter confirmation code"
                onChange={(e) => setConfirmationCode(e.target.value)}
              />
            </Form.Group>
            {confirmationMessage && <Alert variant={confirmationError ? "danger" : "success"}>{confirmationMessage}</Alert>}
            {confirmationError && <Alert variant="danger">{confirmationError}</Alert>}
            <Button variant="primary" onClick={handleConfirmation}>
              Confirm
            </Button>
            {email && (
              <Button 
                variant="outline-secondary" 
                onClick={handleSendConfirmationCodeAgain}
                disabled={isLoading}
                className="ml-2 mx-2"
              >
                {sendButtonText}
              </Button>
            )}
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default SignUpButton;
