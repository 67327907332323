import { mapInfo } from "../utils/maps";
import { world_to_screen } from "../utils/math";
import { PlayerFrameData, WeaponFireData } from "../utils/types";


const SVGPlayerEffects = ({ data, currentTick, weaponFire, scaleFactor, mapName, highlight, upperView }: {
  data: PlayerFrameData,
  currentTick: number,
  weaponFire: WeaponFireData | undefined,
  scaleFactor: number,
  mapName: string,
  highlight: boolean,
  upperView: boolean,
}) => {

  const position = world_to_screen([data.x, data.y, data.z], mapName);
  const angle = -data.viewX;
  const isBelow = data.z < mapInfo[mapName as keyof typeof mapInfo].splitZ;
  const isDimmed = upperView ? isBelow : !isBelow;

  const fontSize = 15 / scaleFactor;
  let color = data.side === "T" ? [204, 121, 12, 255] : [13, 153, 214, 255];
  let weaponFireCoeff = 0;
  let weaponFireAngle = 0;
  if (!data.isAlive) {
    color = color.map((c) => c * 0.5);
  }

  if (weaponFire !== undefined) {
    const t = currentTick - weaponFire.tick;
    weaponFireCoeff = Math.min(Math.max((50 - t * 3.0) / 50, 0), 1);
    const m = weaponFireCoeff + 1;
    color = [color[0] * m, color[1] * m, color[2] * m, color[3]];
    weaponFireAngle = -weaponFire.playerViewX
  }

  const highlightSvg = highlight ? <svg>
    <circle
      transform={`translate(${position[0]}, ${position[1]})`}
      r={70 / scaleFactor}
      fill={`rgb(0,0,0,0.2)`}
      stroke={`rgb(255,255,255,0.2)`}
      strokeWidth="0.01em"
      strokeDasharray={"0.5, 0.25"}
    />
    <text
      x={`${position[0] + 1.0 / scaleFactor}`}
      y={`${position[1] - 25.0 / scaleFactor}`}
      fontSize={fontSize}
      fontWeight={800}
      fill={`rgb(0,0,0,1)`}
      textAnchor="middle"
    >
      {data.name}
    </text>
    <text
      x={`${position[0]}`}
      y={`${position[1] - 25.0 / scaleFactor}`}
      fontSize={fontSize}
      fontWeight={800}
      fill={`rgb(${color[0] * 2.0}, ${color[1] * 1.5}, ${color[2] * 1.5}, ${color[3]})`}
      textAnchor="middle"
      stroke="black"
      strokeWidth="0.02em"
      strokeLinejoin="bevel"
    >
      {data.name}
    </text>
  </svg> : null;

  return (
    <>
      {weaponFire !== undefined && weaponFireCoeff > 0 ?
        <svg id={`weaponFlash_wrapper_${data.steamID}`}>
          <defs>
            <radialGradient id={`weaponFlash_${data.steamID}`} cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
              <stop offset="0%" style={{ stopColor: `rgb(255,192,128)`, stopOpacity: isDimmed ? 0.2 : 1 }} />
              <stop offset="100%" style={{ stopColor: `rgb(255,192,128)`, stopOpacity: isDimmed ? 0.1 : 0.5 }} />
            </radialGradient>
          </defs>
          <circle
            transform={`translate(${position[0]}, ${position[1]}) rotate(${weaponFireAngle}, 0, 0) translate(${10 / scaleFactor}, 0.0)`}
            r={weaponFireCoeff * 25 / scaleFactor}
            fill={`url(#weaponFlash_${data.steamID})`}
          />
        </svg> : null
      }
      {highlightSvg}
    </>
  )
};

export default SVGPlayerEffects;

