import React, { useState, useEffect, useRef } from 'react';
import { Spinner } from 'react-bootstrap';
import styles from './SubscribeOrg.module.css';
import { fetchFromApi } from '../utils/api';

export interface OrgMember {
  user_id: string;
  email: string;
}

export interface AccountData {
  user_id: string;
  email: string;
  subscription: string;
  team: string;
  org_name: string;
  org_admin: boolean;
  org_sub_status: string;
  org_sub_email: string;
  org_members: OrgMember[];
}

const SubscribeOrg: React.FC = () => {
  const [account, setAccount] = useState<AccountData | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showForm, setShowForm] = useState(true);
  const [paymentStatus, setPaymentStatus] = useState<'processing' | 'active' | null>(null);

  useEffect(() => {
    const fetchAccountData = () => {
      fetchFromApi('/account')
        .then((response) => response.json() as Promise<AccountData>)
        .then((data) => {
          setAccount(data);
          setIsLoading(false);
          if (data.org_sub_status === 'ACTIVE') {
            setPaymentStatus('active');
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          setIsLoading(false);
        });
    };

    fetchAccountData();

    // Poll account data every 10 seconds if payment is processing
    let intervalId: NodeJS.Timeout;
    if (paymentStatus === 'processing') {
      intervalId = setInterval(fetchAccountData, 10000);
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [paymentStatus]);

  const [orgName, setOrgName] = useState('');
  const [paymentsEmail, setPaymentsEmail] = useState('');
  const orgNameInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // Focus on the organization name input when the component mounts
    orgNameInputRef.current?.focus();
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);

    const adminEmail = account?.email;
    const finalPaymentsEmail = paymentsEmail || adminEmail || 'error';

    // Clean the organization name
    const cleanedOrgName = orgName
      .toLowerCase() // Convert to lowercase
      .replace(/[^a-z0-9]+/g, '-') // Replace non-alphanumeric characters with dash
      .replace(/-{2,}/g, '-') // Remove consecutive dashes
      .replace(/^-|-$/g, ''); // Remove leading and trailing dashes

    try {
      const response = await fetchFromApi('/subscribe_org', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          org_name: cleanedOrgName, // Use the cleaned organization name
          admin_email: adminEmail,
          payments_email: finalPaymentsEmail,
        }),
      });

      const data = await response.json();

      if (data.success) {
        const gumroadUrl = `https://juhakiili.gumroad.com/l/csgolens-org?wanted=true&email=${encodeURIComponent(finalPaymentsEmail)}`;
        window.open(gumroadUrl, '_blank');
        
        // Wait for 3 seconds, then hide form and show processing message
        setTimeout(() => {
          setShowForm(false);
          setPaymentStatus('processing');
        }, 3000);
      } else {
        setError('Subscription failed. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      setError('An error occurred. Please try again later.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const isOrgNameValid = orgName.trim().length > 0;

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>
        <i className="bi bi-people"></i> Organization Subscription
      </h1>
      {showForm ? (
        <>
          <div className={styles.explanation}>
            <p>
              The subscription costs <strong className={styles.price}>$49/mo (+VAT)</strong> and covers the entire organization, including academy teams.
            </p>
            <p>
              The organization admin will be able to add and remove unlimited user accounts. 
              Every user in the organization will have access to all the demo and voice comms uploads within the organization.
            </p>
          </div>
          {isLoading ? (
            <div className={styles.spinnerContainer}>
              <Spinner animation="border" role="status" />
              <span className={styles.loadingText}>Loading...</span>
            </div>
          ) : (
            <form onSubmit={handleSubmit} className={styles.form}>
              <div className={styles.formGroup}>
                <label htmlFor="orgName">Organization Name:</label>
                <input
                  type="text"
                  id="orgName"
                  value={orgName}
                  onChange={(e) => setOrgName(e.target.value.slice(0, 64))}
                  maxLength={32}
                  required
                  ref={orgNameInputRef}
                />
              </div>
              <div className={styles.formGroup}>
                <label>Admin:</label>
                <span className={styles.adminEmail}>{account?.email}</span>
              </div>
              <div className={styles.formGroup}>
                <label htmlFor="paymentsEmail">Payments Email:</label>
                <input
                  type="email"
                  id="paymentsEmail"
                  value={paymentsEmail}
                  onChange={(e) => setPaymentsEmail(e.target.value)}
                />
                <small className={styles.fieldCaption}>Only needed if different from admin</small>
              </div>
              <button type="submit" className={styles.submitButton} disabled={!isOrgNameValid || isSubmitting}>
                {isSubmitting ? (
                  <>
                    <Spinner animation="border" size="sm" className={styles.buttonSpinner} />
                    Submitting...
                  </>
                ) : (
                  'Proceed to Payment'
                )}
              </button>
              {error && <div className={styles.errorMessage}>{error}</div>}
            </form>
          )}
        </>
      ) : (
        <div className={`${styles.statusMessage} ${styles.centerAligned}`}>
          {paymentStatus === 'processing' && (
            <div className={styles.processingMessage}>
              <div className={styles.processingStatus}>
                <Spinner animation="border" size="sm" className={styles.processingSpinner} />
                <span>We are processing your payment...</span>
              </div>
              <p>Please be patient, it may take a while to process.</p>
              <p>Please contact support if it takes longer than a few minutes.</p>
            </div>
          )}
          {paymentStatus === 'active' && (
            <>
              <p className={styles.successMessage}>Your organization subscription is now active!</p>
              <p>You can proceed to <a href="/account">account page</a> to add more users to the organization.</p>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default SubscribeOrg;
