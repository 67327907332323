import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Button, Modal, Form, Col, Row, OverlayTrigger, Tooltip, Overlay } from 'react-bootstrap';
import CustomModalHeader from './CustomModalHeader';
import styles from './FooterButton.module.css';


interface SettingsButtonProps {
  onClose: () => void; // The callback function for the close event
}

export default function SettingsButton({ onClose }: SettingsButtonProps): JSX.Element {
  const [showModal, setShowModal] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [hoverTimeout, setHoverTimeout] = useState<number | null>(null);

  const target = useRef(null);

  const [recordingSpeed, setRecordingSpeed] = useState(
    parseFloat(localStorage.getItem('recordingSpeed') || '3')
  );
  const [recordingFrameRate, setRecordingFrameRate] = useState(
    parseFloat(localStorage.getItem('recordingFrameRate') || '10')
  );
  const [recordingQuality, setRecordingQuality] = useState(
    parseFloat(localStorage.getItem('recordingQuality') || '50')
  );
  const [roundPickerStyle, setRoundPickerStyle] = useState<string>(
    localStorage.getItem('roundPickerStyle-v2') || 'NUMBERS'
  );
  const [autoplay, setAutoplay] = useState<boolean>(
    localStorage.getItem('autoplay') === "true" || localStorage.getItem('autoplay') === null
  );

  const handleShowModal = (): void => setShowModal(true);
  const handleCloseModal = (): void => {
    setShowModal(false);
    onClose();
  }

  const handleKeyDown = (event: { preventDefault: () => void; }) => {
    event.preventDefault();
  };

  const buttonClasses = showModal
    ? `${styles.button} ${styles.buttonActivated}`
    : styles.button;

  useEffect(() => {
    const handleStorageChange = (e: StorageEvent) => {
      if (e.key === 'recordingSpeed') {
        setRecordingSpeed(parseFloat(e.newValue || '3'));
      }
      if (e.key === 'recordingFrameRate') {
        setRecordingFrameRate(parseFloat(e.newValue || '10'));
      }
      if (e.key === 'recordingQuality') {
        setRecordingQuality(parseFloat(e.newValue || '50'));
      }
      if (e.key === 'roundPickerStyle-v2') {
        setRoundPickerStyle(e.newValue || 'NUMBERS');
      }
    };
    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const handleMouseEnter = () => {
    const timeout = window.setTimeout(() => {
      setShowTooltip(true);
    }, 300) as unknown as number; // cast to number
    setHoverTimeout(timeout);
  };
  
  const handleMouseLeave = () => {
    if (hoverTimeout) {
      window.clearTimeout(hoverTimeout); 
    }
    setShowTooltip(false);
    setHoverTimeout(null);
  };

  const handleRoudpickerOptionChange = (e: ChangeEvent<HTMLInputElement>) => {
    localStorage.setItem('roundPickerStyle-v2', e.target.id);
    setRoundPickerStyle(e.target.id);
  };

  const handleAutoplayChange = (e: ChangeEvent<HTMLInputElement>) => {
    localStorage.setItem('autoplay', e.target.checked ? "true" : "false");
    setAutoplay(e.target.checked);
  };

  return (
    <>
      <Button
        ref={target}
        variant={showModal ? 'secondary' : 'outline-secondary'}
        size="sm"
        className={buttonClasses}
        onClick={() => {
          handleShowModal();
          setShowTooltip(false);
        }}
        onKeyDown={handleKeyDown}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <i className="bi bi-gear"></i>
      </Button>

      <Overlay
        target={target.current}
        show={showTooltip}
        placement="top"
      >
        {(props) => (
          <Tooltip id="tooltip-playback-speed" {...props}>
            Settings
          </Tooltip>
        )}
      </Overlay>

      <Modal show={showModal} onHide={handleCloseModal}>
        <CustomModalHeader />
        <Modal.Body>
          <Row>
            <Col sm={4} className="d-flex align-items-center">
              <Form.Label column sm={3} style={{ whiteSpace: 'nowrap', fontWeight: 'bold', marginTop: 0, marginBottom: 0 }}>
                GIF frame rate
              </Form.Label>
            </Col>
            <Col sm={8} className="d-flex align-items-center">
              <Form.Control
                style={{ maxWidth: '80px' }}
                type="number"
                min={1}
                max={16}
                value={Math.round(parseFloat(localStorage.getItem('recordingFrameRate') || '10'))} // Use Math.round()
                onChange={(e) => {
                  const valueAsNumber = parseFloat(e.target.value);
                  localStorage.setItem('recordingFrameRate', valueAsNumber.toString());
                  setRecordingFrameRate(valueAsNumber); // Update the state variable
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={4} className="d-flex align-items-center">
              <Form.Label column sm={3} style={{ whiteSpace: 'nowrap', fontWeight: 'bold', marginTop: 0, marginBottom: 0 }}>
                GIF match speed
              </Form.Label>
            </Col>
            <Col sm={8} className="d-flex align-items-center">
              <Form.Control
                style={{ maxWidth: '80px' }}
                type="number"
                min={1}
                max={16}
                value={Math.round(parseFloat(localStorage.getItem('recordingSpeed') || '3'))} // Use Math.round()
                onChange={(e) => {
                  const valueAsNumber = parseFloat(e.target.value);
                  localStorage.setItem('recordingSpeed', valueAsNumber.toString());
                  setRecordingSpeed(valueAsNumber); // Update the state variable
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={4} className="d-flex align-items-center">
              <Form.Label column sm={3} style={{ whiteSpace: 'nowrap', fontWeight: 'bold', marginTop: 0, marginBottom: 0 }}>
                GIF quality (0-100)
              </Form.Label>
            </Col>
            <Col sm={8} className="d-flex align-items-center">
              <Form.Control
                style={{ maxWidth: '80px' }}
                type="number"
                min={0}
                max={100}
                value={Math.round(parseFloat(localStorage.getItem('recordingQuality') || '50'))} // Use Math.round()
                onChange={(e) => {
                  const valueAsNumber = parseFloat(e.target.value);
                  localStorage.setItem('recordingQuality', valueAsNumber.toString());
                  setRecordingQuality(valueAsNumber); // Update the state variable
                }}
              />
            </Col>
          </Row>
          <hr />
          <Row>
            <Col sm={4} className="d-flex align-items-center">
              <Form.Label column sm={3} style={{ whiteSpace: 'nowrap', fontWeight: 'bold', marginTop: 0, marginBottom: 0 }}>
                Round picker style
              </Form.Label>
            </Col>
            <Col sm={8} className="d-flex align-items-center">
              <Form.Check
                type="radio"
                label=""
                name="roundPickerOptions"
                id="ICONS"
                className="mr-3"
                onChange={handleRoudpickerOptionChange}
                checked={roundPickerStyle === 'ICONS'}
              />
              <img src="/roundpicker_icons.png" alt="Icons" style={{ height: '39px' }} />
              <Form.Check
                type="radio"
                label=""
                name="roundPickerOptions"
                id="NUMBERS"
                onChange={handleRoudpickerOptionChange}
                style={{ marginLeft: '10px' }}
                checked={roundPickerStyle === 'NUMBERS'}
              />
              <img src="/roundpicker_numbers.png" alt="Icons" style={{ height: '39px' }} />
            </Col>
          </Row>
          <hr />
          <Row>
            <Col sm={4} className="d-flex align-items-center">
              <Form.Label column sm={3} style={{ whiteSpace: 'nowrap', fontWeight: 'bold', marginTop: 0, marginBottom: 0 }}>
                Auto playback
              </Form.Label>
            </Col>
            <Col sm={8} className="d-flex align-items-center">
              <Form.Check
                type="checkbox"
                label=""
                name="autoplay"
                id="autoplay"
                className="mr-3"
                onChange={handleAutoplayChange}
                checked={autoplay}
              />
              <span style={{color: "#888"}}>Auto-play on round load</span>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col sm={4} className="d-flex">
              <Form.Label column sm={3} style={{ whiteSpace: 'nowrap', fontWeight: 'bold', marginTop: 0, marginBottom: 0 }}>
                Hotkeys
              </Form.Label>
            </Col>
            <Col sm={8} className="d-flex">
              <div style={{ paddingTop: '7px' }}>
                <table>
                  <tbody>
                    <tr>
                      <td className="hotkey-space">{"space"}</td>
                      <td className="hotkey-description">Toggle play/pause</td>
                    </tr>
                    <tr>
                      <td className="hotkey-arrow">,</td>
                      <td className="hotkey-description">Seek backward 5 seconds</td>
                    </tr>
                    <tr>
                      <td className="hotkey-arrow">.</td>
                      <td className="hotkey-description">Seek forward 5 seconds</td>
                    </tr>
                    <tr>
                      <td className="hotkey-arrow">{"J"}</td>
                      <td className="hotkey-description">Previous round</td>
                    </tr>
                    <tr>
                      <td className="hotkey-arrow">{"K"}</td>
                      <td className="hotkey-description">Next round</td>
                    </tr>
                    <tr>
                      <td className="hotkey-arrow">{"N"}</td>
                      <td className="hotkey-description">Slower playback</td>
                    </tr>
                    <tr>
                      <td className="hotkey-arrow">{"M"}</td>
                      <td className="hotkey-description">Faster playback</td>
                    </tr>
                    <tr>
                      <td className="hotkey">{"L"}</td>
                      <td className="hotkey-description">Toggle lower/upper level</td>
                    </tr>
                    <tr>
                      <td className="hotkey">{"S"}</td>
                      <td className="hotkey-description">Copy hovered player 'setpos'</td>
                    </tr>
                    <tr>
                      <td className="hotkey">{"P"}</td>
                      <td className="hotkey-description">Toggle painting</td>
                    </tr>
                    <tr>
                      <td className="hotkey">{"7"}</td>
                      <td className="hotkey-description"><span style={{ color: '#ccc' }}>Gray</span> paint color</td>
                    </tr>
                    <tr>
                      <td className="hotkey">{"8"}</td>
                      <td className="hotkey-description"><span style={{ color: '#e22' }}>Red</span> paint color</td>
                    </tr>
                    <tr>
                      <td className="hotkey">{"9"}</td>
                      <td className="hotkey-description"><span style={{ color: '#fb5' }}>Yellow</span> paint color</td>
                    </tr>
                    <tr>
                      <td className="hotkey">{"0"}</td>
                      <td className="hotkey-description"><span style={{ color: '#5af' }}>Blue</span> paint color</td>
                    </tr>
                    <tr>
                      <td className="hotkey-space">{"Ctrl-Z"}</td>
                      <td className="hotkey-description">Undo last painting stroke</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}
