import { useEffect, useRef, useState } from "react";
import { Button, Col, Dropdown, DropdownButton, Form, Modal, Overlay, OverlayTrigger, Row, Spinner, ToggleButton, ToggleButtonGroup, Tooltip } from "react-bootstrap";
import { RoundMetadata } from "../utils/types";
import useWindowSize from "../useWindowSize";
import { fetchFromApi } from "../utils/api";
import CustomModalHeader from "./CustomModalHeader";
import { getHasSubscription } from "../utils/premium";
import UpgradeInfoModal from "./UpgradeInfoModal";
import { PlaylistsState, StoredPlaylist } from "../pages/Playlists";
import { on } from "events";
import { useNavigate } from "react-router-dom";
import { post_usage } from "../utils/usage";

function getRoundId(round: RoundMetadata): string {
  return `${round.match_id}.${round.mapname}.${round.roundnum}`;
}

function playlistHasRound(playlist: StoredPlaylist, round: RoundMetadata) {
  const roundId = getRoundId(round);
  return playlist.items.has(roundId);
}

const PlaylistButton = ({ selectedRound, onRemovedRound }: {
  selectedRound: RoundMetadata | undefined,
  onRemovedRound: (playlist_id: string, round_id: string) => void
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [hoverTimeout, setHoverTimeout] = useState<number | null>(null);
  const [playlists, setPlaylists] = useState<PlaylistsState | undefined>(undefined);
  const [newPlaylistName, setNewPlaylistName] = useState<string | undefined>(undefined);
  const [savingNewPlaylist, setSavingNewPlaylist] = useState<boolean>(false);
  const [showModal, setShowModal] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [premium, setPremium] = useState(false);
  const [updatingPlaylist, setUpdatingPlaylist] = useState(false);
  const { width, height } = useWindowSize();

  const target = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const checkPremium = async () => {
      const premium = await getHasSubscription();
      setPremium(premium);
    }
    setShowModal(false);
    checkPremium();
  }, []);

  function loadPlaylists(createdPlaylist: boolean = false) {
    fetchFromApi('/playlist')
      .then((response) => response.json())
      .then((responseObject: StoredPlaylist[]) => {
        const playlistMap: PlaylistsState = responseObject.reduce<PlaylistsState>((acc, playlist) => {
          acc[playlist.playlist_id!] = {
            ...playlist,
            items: new Set(playlist.items)
          };
          return acc;
        }, {});
        setPlaylists(playlistMap);
        if(createdPlaylist) {
          setSavingNewPlaylist(false);
          setShowModal(false);
        }
      });
  }

  useEffect(() => {
    if (premium) {
      loadPlaylists();
    }
  }, [premium]);

  const handleShowModal = (): void => { loadPlaylists(); setShowModal(true); };
  const handleCloseModal = (): void => setShowModal(false);

  const createNewPlaylist = (name: string, firstRound?: RoundMetadata) => {
    if (!name) {
      return;
    }

    post_usage('create_playlist');
    setSavingNewPlaylist(true);
    const roundId = firstRound ? getRoundId(firstRound) : undefined;
    const newPlaylist = {
      playlist_id: undefined,
      name,
      items: roundId ? [roundId] : []
    };

    fetchFromApi('/playlist', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(newPlaylist)
    })
      .then(response => {
        if (response.ok && playlists) {
          loadPlaylists(true);
        } else {
          console.error(`Failed to create playlist. Status: ${response.status}`);
          setSavingNewPlaylist(false);
          setShowModal(false);
        }
      })
  }

  const isInAnyPlaylist = () => {
    if (!playlists || !selectedRound) {
      return false;
    }
    return Object.values(playlists).some(playlist => playlistHasRound(playlist, selectedRound));
  };

  const toggleRoundInPlaylist = (playlist: StoredPlaylist) => {
    if (selectedRound && playlists) {
      const playlist_id = playlist.playlist_id!;
      const roundId = getRoundId(selectedRound);
      setUpdatingPlaylist(true);

      fetchFromApi('/playlist')
      .then((response) => response.json())
      .then((responseObject: StoredPlaylist[]) => {
        // Find the playlist we want to update
        let updatedPlaylist = responseObject.find(p => p.playlist_id === playlist_id);

        // Check if updatedPlaylist is defined to avoid runtime errors
        if (updatedPlaylist) {
          updatedPlaylist = {
            ...updatedPlaylist,
            items: new Set(updatedPlaylist.items)
          };
          const isRoundInPlaylist = playlistHasRound(updatedPlaylist, selectedRound);
    
          if (isRoundInPlaylist) {
            updatedPlaylist.items.delete(roundId);
          } else {
            updatedPlaylist.items.add(roundId);
          }

          const transformedPlaylist = {
            playlist_id: updatedPlaylist!.playlist_id,
            name: updatedPlaylist!.name,
            items: Array.from(updatedPlaylist.items),
            owned_by_user: updatedPlaylist!.owned_by_user,
            timestamp: updatedPlaylist!.timestamp
          };

          // Send the entire updated playlist to the /playlist API using a POST request
          fetchFromApi('/playlist', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(transformedPlaylist)
          }).then(response => {
            if (response.ok) {
              // Update the local state with the new playlist
              setPlaylists((oldValue) => {
                const newPlaylists = { ...oldValue };
                newPlaylists[playlist_id] = updatedPlaylist!;
                return newPlaylists;
              });
              setUpdatingPlaylist(false);
            } else {
              console.error(`Failed to update playlist. Status: ${response.status}`);
              setUpdatingPlaylist(false);
            }
          }
          );
        }
      });
    }
  }

  const handleKeyDown = (event: { preventDefault: () => void }) => {
    event.preventDefault();
  };

  const renderDropdownItems = () => {
    if (!playlists || !selectedRound) {
      return <><Spinner animation="border" size="sm" style={{ marginRight: 15, marginLeft: 15, color: "#888" }} /><span style={{ color: "#777" }}>Loading...</span></>;
    }
    const userPlaylists = Object.values(playlists).filter(p => p.owned_by_user);
    return (
      <>
        {userPlaylists.map(playlist => {
          const isRoundInPlaylist = playlistHasRound(playlist, selectedRound);
          return (
            <Dropdown.Item
              key={playlist.playlist_id}
              onClick={() => toggleRoundInPlaylist(playlist)}
            >
              <i
                className={`bi ${isRoundInPlaylist ? "bi-star-fill" : "bi-star"}`}
                style={{ marginRight: 10, color: isRoundInPlaylist ? '#fd4' : '#aaa' }}
              ></i>
              {playlist.name}
            </Dropdown.Item>
          );
        })}
        {userPlaylists.length > 0 && <Dropdown.Divider />}
        <Dropdown.Item
          key={"create-new-playlist"}
          onClick={() => {
            setNewPlaylistName('');
            setShowModal(true);
          }}
        >
          <i
            className={`bi bi-plus-lg`}
            style={{ marginRight: 10, color: '#8f8' }}
          ></i>
          <span style={{ color: '#aaa' }}>New Playlist</span>
        </Dropdown.Item>
        {userPlaylists.length > 0 && <Dropdown.Divider />}
        {userPlaylists.length > 0 && <Dropdown.Item
          key={"manage-playlists"}
          onClick={() => {
            navigate('/playlists');
          }}
        >
          <i
            className={`bi bi-list`}
            style={{ marginRight: 10 }}
          ></i>
          <span style={{ color: '#aaa' }}>All Playlists</span>
        </Dropdown.Item>
        }
      </>
    );
  };

  const handleMouseEnter = () => {
    const timeout = window.setTimeout(() => {
      setShowTooltip(!isDropdownOpen);
    }, 300) as unknown as number; // cast to number
    setHoverTimeout(timeout);
  };

  const handleMouseLeave = () => {
    if (hoverTimeout) {
      window.clearTimeout(hoverTimeout);
    }
    setShowTooltip(false);
    setHoverTimeout(null);
  };

  return (
    <>
      <DropdownButton
        ref={target}
        onToggle={(isOpen) => {
          if (premium) {
            post_usage('playlist_dropdown');
            if(isOpen) {
              loadPlaylists();
            }
            setIsDropdownOpen(isOpen);
          } else {
            setIsDropdownOpen(false);
            setShowUpgradeModal(true);
          }
          setShowTooltip(false);
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={`dropdown-playlist-star-button`}
        style={{ padding: 0, margin: 0 }}
        variant="outline-secondary"
        id="dropdown-round-picker"
        title={updatingPlaylist ?
          <Spinner animation="border" size="sm" style={{ color: '#fd4' }} /> :
          <i className={`bi ${isInAnyPlaylist() ? "bi-star-fill" : "bi-star"}`} style={{ color: isInAnyPlaylist() ? '#fd4' : '#aaa' }}></i>
        }
        size="sm"
      >
        {isDropdownOpen && renderDropdownItems()}
      </DropdownButton>
      <Overlay
        target={target.current}
        show={showTooltip}
        placement="top"
      >
        {(props) => (
          <Tooltip id="tooltip-playback-speed" {...props}>
            Add to playlist
          </Tooltip>
        )}
      </Overlay>

      <Modal show={showModal} onHide={handleCloseModal}>
        <div onKeyDown={(e) => e.stopPropagation()}>
          <CustomModalHeader />
          <Modal.Body>
            <Row>
              <Col sm={2} className="d-flex align-items-center">
                <Form.Label column sm={3} style={{ whiteSpace: 'nowrap', fontWeight: 'bold', marginTop: 0, marginBottom: 0 }}>
                  Name
                </Form.Label>
              </Col>
              <Col sm={10} className="d-flex align-items-center">
                <Form.Control
                  min={1}
                  max={64}
                  value={newPlaylistName}
                  placeholder="New playlist name"
                  autoFocus={true}
                  onChange={(e) => {
                    setNewPlaylistName(e.target.value);
                  }}
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            {savingNewPlaylist && <Spinner animation="border" size="sm" style={{ marginRight: 10 }} />}
            <Button variant="success" onClick={() => createNewPlaylist(newPlaylistName || 'My playlist', selectedRound)} disabled={!newPlaylistName}>
              Create
            </Button>
            <Button variant="outline-secondary" onClick={() => setShowModal(false)}>
              Cancel
            </Button>
          </Modal.Footer>
        </div>
      </Modal>

      <UpgradeInfoModal show={showUpgradeModal} handleCloseModal={() => setShowUpgradeModal(false)} />
    </>
  );
};

export default PlaylistButton;