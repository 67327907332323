import React, { useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { fetchFromApi } from '../utils/api';
import styles from './DeleteMatchButton.module.css';

interface DeleteMatchButtonProps {
  matchIds: string[]; // Now an array of strings
  deleteMultiple?: boolean;
}

const DeleteMatchButton: React.FC<DeleteMatchButtonProps> = ({ matchIds, deleteMultiple }) => {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleDeleteUpload = () => {
    setIsLoading(true);

    // Create a Promise for each matchId to delete
    const deletePromises = matchIds.map(matchId => {
      const data = { match_id: matchId };
      return fetchFromApi(`/upload_delete`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json'
        },
      });
    });

    // Execute all promises
    Promise.all(deletePromises)
      .then(() => window.location.reload())
      .catch((err) => {
        console.error(err.message);
      })
      .finally(() => setIsLoading(false));
  };

  const deleteMessage = matchIds.length > 1 
    ? `Are you sure you want to delete these ${matchIds.length} matches?` 
    : 'Are you sure you want to delete this match?';

  return (
    <>
      <Button
        variant={'danger'}
        className={!deleteMultiple ? "btn-sm" : styles['delete-matches-button-sm']}
        onClick={handleShow}
        disabled={deleteMultiple && matchIds.length === 0}
      >
        <i className="bi bi-trash" style={{ fontSize: 14 }}></i>
        {deleteMultiple && <span style={{ marginLeft: 5 }}>Remove selected</span>}
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>{deleteMessage}</Modal.Body>
        <Modal.Footer>
          {isLoading && <Spinner animation="border" size="sm" style={{ marginRight: 10 }} />}
          <Button variant="danger" onClick={handleDeleteUpload}>
            Yes, Delete
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DeleteMatchButton;
