import { RoundMetadata, RoundsMetadata } from "./types";
import { fetchFromApi } from "./api";

export async function fetchRoundsMetadata(match_id: string, mapName?: string | undefined, roundNum?: number | undefined) {
  type QueryParams = {
    match_id: string,
    sides: string,
    unlisted: boolean,
    map?: string,
    roundnum?: number,
  };

  const queryParams: Partial<QueryParams> = {
    match_id: match_id,
    sides: "BOTH",
    unlisted: true,
    map: mapName,
    roundnum: roundNum,
  };

  let url = '/rounds?';
  Object.keys(queryParams).forEach((key, index) => {
    const value = queryParams[key as keyof QueryParams];
    if (value !== undefined) {
      url += `${key}=${value}`;
      if (index < Object.keys(queryParams).length - 1) {
        url += '&';
      }
    }
  });

  try {
    const response = await fetchFromApi(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const responseRounds: RoundsMetadata = await response.json();
    return responseRounds;
  } catch (error) {
    console.error("An error occurred while fetching the rounds.", error);
  }
}
