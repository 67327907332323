import AnalyseMapViewLayer from "./AnalyseMapViewLayer";
import { mapInfoAnalyse } from "../utils/maps";
import { DamageData, GrenadeData, KillData, PlayerFrameData, WeaponFireData } from "../utils/types";
import { FireInfo, SmokeInfo } from "./MapView";
import { Projectile } from "../utils/projectile";
import { MatchRound } from "./Analyse";


interface AnalyseMapViewProps {
  upperView: boolean,
  mapName: string,
  currentTick: number | undefined,
  playerFrames: PlayerFrameData[],
  projectiles: Projectile[],
  explosions: GrenadeData[],
  smokes: SmokeInfo[],
  fires: FireInfo[],
  weaponFires: WeaponFireData[],
  bullets: DamageData[],
  damages: DamageData[],
  kills: KillData[],
  deaths: KillData[],
  bomb: {x: number, y: number, z: number} | null,
  bombPlanted: boolean,
  bombTimeLeft: number,
  bombDefusing: boolean,
  activePlayerSteamId: number | undefined,
  hoveredPlayerSteamId: number | undefined,
  activeRound: MatchRound | undefined,
  hoveredRound: MatchRound | undefined,
  playerColors: Map<number, string>,
  csVersion: string | undefined,
  onPlayerBeginHover: (matchID: string, mapName: string, roundNum: number, steamID: number) => void,
  onPlayerEndHover: (matchID: string, mapName: string, roundNum: number, steamID: number) => void,
  onPlayerClick: (matchID: string, mapName: string, roundNum: number, steamID: number) => void,
  rect: number[] | undefined,
  rectType: 'all' | 'landing' | undefined,
  rectChanged: (
    recordingRect: number[] | undefined,
    rectType: 'all' | 'landing' | undefined,
    dragStart: boolean,
    dragEnd: boolean,
  ) => void,
}

const AnalyseMapView = ({
  upperView,
  mapName,
  currentTick,
  playerFrames,
  projectiles,
  explosions,
  smokes,
  fires,
  weaponFires,
  bullets,
  damages,
  kills,
  deaths,
  bomb,
  bombPlanted,
  bombTimeLeft,
  bombDefusing,
  activePlayerSteamId,
  hoveredPlayerSteamId,
  activeRound,
  hoveredRound,
  playerColors,
  csVersion,
  onPlayerBeginHover,
  onPlayerEndHover,
  onPlayerClick,
  rect,
  rectType,
  rectChanged,
}: AnalyseMapViewProps) => {
  const mapOffset = mapInfoAnalyse[mapName as keyof typeof mapInfoAnalyse].offset;
  const mapZoom = mapInfoAnalyse[mapName as keyof typeof mapInfoAnalyse].zoom;

  return (
    <AnalyseMapViewLayer
      playerFrames={playerFrames}
      projectiles={projectiles}
      explosions={explosions}
      smokes={smokes}
      fires={fires}
      upperView={upperView}
      mapName={mapName}
      mapOffset={mapOffset}
      mapZoom={mapZoom}
      currentTick={currentTick}
      weaponFires={weaponFires}
      bullets={bullets}
      damages={damages}
      kills={kills}
      deaths={deaths}
      bomb={bomb}
      bombPlanted={bombPlanted}
      bombTimeLeft={bombTimeLeft}
      bombDefusing={bombDefusing}
      activePlayerSteamId={activePlayerSteamId}
      hoveredPlayerSteamId={hoveredPlayerSteamId}
      activeRound={activeRound}
      hoveredRound={hoveredRound}
      playerColors={playerColors}
      csVersion={csVersion}
      onPlayerBeginHover={onPlayerBeginHover}
      onPlayerEndHover={onPlayerEndHover}
      onPlayerClick={onPlayerClick}
      rect={rect}
      rectType={rectType}
      rectChanged={rectChanged}
    />
  );
}

export default AnalyseMapView;