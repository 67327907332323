const countryFlags: {[key: string]: string} = {
  'switzerland': '🇨🇭',
  'lebanon': '🇱🇧',
  'guatemala': '🇬🇹',
  'canada': '🇨🇦',
  'south-africa': '🇿🇦',
  'lithuania': '🇱🇹',
  'romania': '🇷🇴',
  'mauritius': '🇲🇺',
  'united-kingdom': '🇬🇧',
  'russian-federation': '🇷🇺',
  'belarus': '🇧🇾',
  'norway': '🇳🇴',
  'moldova-republic-of': '🇲🇩',
  'bosnia-and-herzegovina': '🇧🇦',
  'australia': '🇦🇺',
  'serbia': '🇷🇸',
  'netherlands': '🇳🇱',
  'sweden': '🇸🇪',
  'macedonia-the-former-yugoslav-republic-of': '🇲🇰',
  'united-states': '🇺🇸',
  'denmark': '🇩🇰',
  'brazil': '🇧🇷',
  'iceland': '🇮🇸',
  'argentina': '🇦🇷',
  'italy': '🇮🇹',
  'poland': '🇵🇱',
  'uzbekistan': '🇺🇿',
  'ireland': '🇮🇪',
  'japan': '🇯🇵',
  'latvia': '🇱🇻',
  'france': '🇫🇷',
  'germany': '🇩🇪',
  'ukraine': '🇺🇦',
  'austria': '🇦🇹',
  'turkey': '🇹🇷',
  'greece': '🇬🇷',
  'czech-republic': '🇨🇿',
  'finland': '🇫🇮',
  'belgium': '🇧🇪',
  'mexico': '🇲🇽',
  'peru': '🇵🇪',
  'slovakia': '🇸🇰',
  'kosovo': '🇽🇰',
  'portugal': '🇵🇹',
  'hungary': '🇭🇺',
  'spain': '🇪🇸',
  'bulgaria': '🇧🇬'
};

export { countryFlags };