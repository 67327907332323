import { useEffect, useRef, useState } from "react";
import { mapInfo } from "../utils/maps";
import { world_to_screen } from "../utils/math";
import { getExplosionTick, Projectile } from "../utils/projectile";
import { FireInfo, SmokeInfo } from "./MapView";
import { sortPlayers, sortPlayersLower } from "./MapViewLayer";
import { DamageData, GrenadeData, PlayerFrameData, PlayerGizmos, WeaponFireData } from "../utils/types";
import { SVGBomb } from "./SVGBomb";
import { SVGFire } from "./SVGFire";
import SVGPlayer from "./SVGPlayer";
import SVGPlayerDetailed from "./SVGPlayerDetailed";
import SVGPlayerEffects from "./SVGPlayerEffects";
import { SVGProjectile } from "./SVGProjectile";
import { SVGSmoke } from "./SVGSmoke";

interface MapViewSVGProps {
  mapName: string,
  upperView: boolean,
  currentTick: number,
  playerFrames: PlayerFrameData[],
  deadPlayerFrames: PlayerFrameData[],
  projectiles: Projectile[],
  explosions: GrenadeData[],
  smokes: SmokeInfo[],
  fires: FireInfo[],
  weaponFires: WeaponFireData[],
  bullets: DamageData[],
  damages: DamageData[],
  bomb: { x: number, y: number, z: number } | null,
  bombPlanted: boolean,
  bombTimeLeft: number,
  bombDefusing: boolean,
  hoveredPlayer: number | undefined,
  playerGizmos: PlayerGizmos,
  enablePointerEvents: boolean,
  mapZoom: number,
  csVersion: string | undefined,
  onPlayerBeginHover: (steamID: number) => void,
  onPlayerEndHover: (steamID: number) => void,
}

const MapViewSVG = ({
  mapName,
  upperView,
  currentTick,
  playerFrames,
  deadPlayerFrames,
  projectiles,
  explosions,
  smokes,
  fires,
  weaponFires,
  bullets,
  damages,
  bomb,
  bombPlanted,
  bombTimeLeft,
  bombDefusing,
  hoveredPlayer,
  playerGizmos,
  enablePointerEvents,
  mapZoom,
  csVersion,
  onPlayerBeginHover,
  onPlayerEndHover,
}: MapViewSVGProps) => {
  const playerSorter = upperView ? sortPlayers : sortPlayersLower;
  const [scaleFactor, setScaleFactor] = useState(10);
  const svgRef = useRef<SVGSVGElement | null>(null);

  useEffect(() => {
    const updateScaleFactor = () => {
      if (svgRef.current) {
        const svgWidth = svgRef.current.clientWidth;
        const svgHeight = svgRef.current.clientHeight;
        const viewBoxSize = 100;
        const newScaleFactor = Math.min(Math.max(Math.min(svgWidth, svgHeight) / (viewBoxSize * (1.0 / mapZoom)), 6.0), 15.0);
        setScaleFactor(newScaleFactor);
      }
    };

    updateScaleFactor();
    window.addEventListener('resize', updateScaleFactor);

    return () => {
      window.removeEventListener('resize', updateScaleFactor);
    };
  }, [mapName]);

  return (
    <svg className={`map-view-svg`} width="100%" height="100%" viewBox="0 0 100 100" ref={svgRef} style={{ pointerEvents: enablePointerEvents ? 'auto' : 'none' }}>
      {deadPlayerFrames.concat(playerFrames).sort(playerSorter).map((p, i) =>
        <svg key={`svg_dead_player_effects_wrapper_${p.steamID}`}>
          <SVGPlayerEffects
            key={`svg_dead_player_effects_${p.steamID}`}
            data={p}
            currentTick={currentTick}
            mapName={mapName}
            scaleFactor={scaleFactor}
            weaponFire={weaponFires.filter((w) => w.playerSteamID === p.steamID).sort((a, b) => b.tick - a.tick).shift()}
            highlight={hoveredPlayer === p.steamID && playerGizmos === 'Minimal'}
            upperView={upperView}
          />
        </svg>
      )}
      {deadPlayerFrames.sort(playerSorter).map((p, i) =>
        <svg
          key={`svg_dead_player_wrapper_${p.steamID}`}
          onMouseEnter={(event) => onPlayerBeginHover(p.steamID)}
          onMouseLeave={(event) => onPlayerEndHover(p.steamID)}
        >
          <SVGPlayer
            key={`svg_dead_player_${p.steamID}`}
            playerName={p.shortName ?? p.name}
            data={p}
            scaleFactor={scaleFactor}
            currentTick={currentTick}
            mapName={mapName}
            damage={undefined}
            upperView={upperView}
            playerGizmos={playerGizmos}
            shadow={true}
          />
        </svg>
      )}
      {bomb !== null && (!bombPlanted || (bombPlanted && bombTimeLeft > 0)) && (bomb.x !== 0 || bomb.y !== 0 || bomb.z !== 0) ?
        <SVGBomb
          key={`svg_bomb`}
          data={bomb}
          currentTick={currentTick}
          upperView={upperView}
          mapName={mapName}
          mapInfo={mapInfo}
          bombPlanted={bombPlanted}
          bombTimeLeft={bombTimeLeft}
          bombDefusing={bombDefusing}
          scaleFactor={scaleFactor}
        /> : null}
      {bomb && bombPlanted && bombTimeLeft < 0 && bombTimeLeft > -200 && !bombDefusing ? [bomb].map((b, i) => {
        const position = world_to_screen([b.x, b.y, b.z], mapName);
        const r = (bombTimeLeft * -1) * 0.1;
        const a = 3 / r;
        return (
          <circle
            key={`bomb_explosion_${i}`}
            transform={`translate(${position[0]}, ${position[1]})`}
            r={r}
            fill={`rgb(${a * 256}, ${a * 128}, ${a * 64}, ${a})`}
          />
        );
      }
      ) : null}
      {bullets.map((d, i) => {
        const t = 1.0 - (d.tick - currentTick) * 50 / d.distance;
        const x = d.victimX * t + d.attackerX * (1 - t);
        const y = d.victimY * t + d.attackerY * (1 - t);
        const z = d.victimZ * t + d.attackerZ * (1 - t);
        const position = world_to_screen([x, y, z], mapName);
        const angle = Math.atan2(d.attackerY - d.victimY, d.victimX - d.attackerX) * 180 / Math.PI;

        return (
          <svg key={`bullet_${d.tick}_${d.attackerSteamID}_${d.victimSteamID}`}>
            <defs>
              <radialGradient id={`bullet_gradient_${d.tick}_${d.attackerSteamID}_${d.victimSteamID}`} cx="10%" cy="50%" r="50%" fx="50%" fy="50%">
                <stop offset="0%" style={{ stopColor: `rgb(255,192,128)`, stopOpacity: 0.8 }} />
                <stop offset="10%" style={{ stopColor: `rgb(255,192,128)`, stopOpacity: 0.5 }} />
                <stop offset="20%" style={{ stopColor: `rgb(255,192,128)`, stopOpacity: 0.1 }} />
                <stop offset="50%" style={{ stopColor: `rgb(255,192,128)`, stopOpacity: 0.03 }} />
                <stop offset="100%" style={{ stopColor: `rgb(255,192,128)`, stopOpacity: 0.0 }} />
              </radialGradient>
            </defs>
            <ellipse
              key={`bullet_ellipse_${d.tick}_${d.attackerSteamID}_${d.victimSteamID}`}
              transform={`translate(${position[0]}, ${position[1]}) rotate(${angle}, 0, 0)`}
              rx={12.0 * t + 1.0}
              ry={0.5}
              fill={`url(#bullet_gradient_${d.tick}_${d.attackerSteamID}_${d.victimSteamID})`}
            />
          </svg>
        )
      })}
      {fires.map((fire, i) =>
        <SVGFire
          key={`fire_${i}`}
          data={fire}
          mapName={mapName}
          currentTick={currentTick}
          upperView={upperView}
          scaleFactor={scaleFactor}
          otherLevelOpacity={0.2}
          csVersion={csVersion}
        />
      )}
      {playerFrames.sort(playerSorter).map((p, i) =>
        <svg
          key={`svg_player_wrapper_${p.steamID}`}
          onMouseEnter={(event) => onPlayerBeginHover(p.steamID)}
          onMouseLeave={(event) => onPlayerEndHover(p.steamID)}
        >
          <SVGPlayer
            key={`svg_player_${p.steamID}`}
            playerName={p.shortName ?? p.name}
            data={p}
            scaleFactor={scaleFactor}
            currentTick={currentTick}
            mapName={mapName}
            damage={damages.filter((d) => d.victimSteamID === p.steamID).sort((a, b) => b.tick - a.tick).shift()}
            upperView={upperView}
            playerGizmos={playerGizmos}
            shadow={true}
          />
        </svg>
      )}
      {playerGizmos === 'Default' ? playerFrames.sort(playerSorter).map((p, i) =>
        <svg
          key={`svg_player_detailed_wrapper_${p.steamID}`}
          onMouseEnter={(event) => onPlayerBeginHover(p.steamID)}
          onMouseLeave={(event) => onPlayerEndHover(p.steamID)}
        >
          <SVGPlayerDetailed
            key={`svg_player_detailed_${p.steamID}`}
            playerName={p.shortName ?? p.name}
            data={p}
            scaleFactor={scaleFactor}
            currentTick={currentTick}
            mapName={mapName}
            damage={damages.filter((d) => d.victimSteamID === p.steamID).sort((a, b) => b.tick - a.tick).shift()}
            upperView={upperView}
            highlight={hoveredPlayer === p.steamID}
            invisible={false}
          />
        </svg>
      ) : null}
      {projectiles.map((p, i) =>
        <SVGProjectile
          key={p.data.uniqueID}
          data={p.data}
          throwerPosition={p.throwerPosition}
          positions={p.positions}
          visualPositions={p.visualPositions}
          mapName={mapName}
          mapInfo={mapInfo}
          upperView={upperView}
          minZ={p.minZ}
          maxZ={p.maxZ}
          endZ={p.endZ}
          scaleFactor={scaleFactor}
          startTick={p.startTick}
          endTick={p.endTick}
          angularVelocity={p.angularVelocity}
          startAngle={p.startAngle}
          side={p.side}
          currentTick={currentTick}
          currentFrameIndex={p.currentFrameIndex}
          otherLevelOpacity={0.2}
        />
      )}
      {smokes.map((smoke, i) =>
        <SVGSmoke
          key={`smoke_${i}`}
          data={smoke}
          mapName={mapName}
          currentTick={currentTick}
          upperView={upperView}
          scaleFactor={scaleFactor}
          otherLevelOpacity={0.2}
          csVersion={csVersion}
        />
      )}
      {explosions.map((p, i) => {
        const position = world_to_screen([p.grenadeX, p.grenadeY, p.grenadeZ], mapName);
        const explosionTick = getExplosionTick(p, csVersion);
        const t = currentTick - explosionTick;
        const tt = 1.0 - t / 30;
        const a = Math.max(tt, 0);
        const r = Math.max(tt * 3.0, 0);
        let color = p.grenadeType === 'Flashbang' ? [255, 255, 255, a] : [255, 64, 0, a];

        const isBelow = p.grenadeZ < mapInfo[mapName as keyof typeof mapInfo].splitZ;
        const isDimmed = upperView ? isBelow : !isBelow;

        if (isDimmed) {
          color = [color[0], color[1], color[2], 0.1];
        }

        return (
          <circle
            key={`explosion_${i}`}
            transform={`translate(${position[0]}, ${position[1]})`}
            r={r}
            fill={`rgb(${color[0]}, ${color[1]}, ${color[2]}, ${color[3]})`}
          />
        )
      })}
    </svg>
  );
}

export default MapViewSVG;