import React from 'react';
import { useWindowSize } from 'usehooks-ts';
// import './test.css';

const LayoutTest = () => {
  const { width, height } = useWindowSize();
  
  const teamA = <div className="team">
    <div className="teaminfo">Team A</div>
    <div className="playerinfo">Player 2</div>
    <div className="playerinfo">Player 3</div>
    <div className="playerinfo">Player 4</div>
    <div className="playerinfo">Player 5</div>
    <div className="playerinfo">Player 6</div>
  </div>

  const teamB = <div className="team">
  <div className="teaminfo">Team A</div>
    <div className="playerinfo">Player 2</div>
    <div className="playerinfo">Player 3</div>
    <div className="playerinfo">Player 4</div>
    <div className="playerinfo">Player 5</div>
    <div className="playerinfo">Player 6</div>
  </div>

  const main = width > 1024 ? 
    <div className="main">
      {teamA}
      <div className="map">
        <h2>Map Overview</h2>
      </div>
      {teamB}
    </div> :
    <div className="main">
      <div className="map">
        <h2>Map Overview</h2>
      </div>
      <div className="teams">
        {teamA}
        {teamB}
      </div>
    </div>;

  return (
    <>
      <nav className="nav">
        <h1>My Webpage</h1>
      </nav>
      <div className="main-wrapper">
        {main}
      </div>
      <footer className="footer">
        <p>Copyright 2021</p>
      </footer>
    </>
  );
};

export default LayoutTest;

/*
I'm making a webpage with responsive design.

- Nav of fixed height at the top
- Content area in the middle
- Footer of fixed height at the bottom

The content area will contain
- Info div of twelve players, six on both teams, each player in its own div
- A large map overview

If the screen is wide (desktop), the teams are in the left and right side and map takes up the rest of the space in the middle.
If the screen is not wide (mobile), both teams should be above the map, and map takes up the rest of the space.

There is no scrolling whatsoever

Can you show me example code for how to do this with react and css.
*/