import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Matches from "./pages/Matches";
import Nopage from "./pages/Nopage";
import MatchLoader from "./pages/MatchLoader";
import LayoutTest from "./pages/LayoutTest";
import ExternalMatchLoader from "./pages/ExternalMatchLoader";
import RoundFilter from "./pages/RoundFilter";
import ExternalPlaylistLoader from "./pages/ExternalPlaylistLoader";
import Account from "./pages/Account";
import CheckoutCancel from "./pages/CheckoutCancel";
import CheckoutSuccess from "./pages/CheckoutSuccess";
import Support from "./pages/Support";
import CustomerInformation from "./pages/Info";
import Terms from "./pages/Terms";
import StripeGumroadSuccess from "./pages/StripeGumroadSuccess";
import AnalyseLoader from "./pages/AnalyseLoader";
import Playlists from "./pages/Playlists";
import Upload2 from "./pages/Upload2";
import PlaylistImport from "./pages/PlaylistImport";
import SubscribeOrg from "./pages/SubscribeOrg";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/test" element={<LayoutTest />} />
        <Route path="/" element={<Layout />}>
          <Route index element={<Matches />} />
          <Route path="/about" element={<CustomerInformation />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/filter" element={<RoundFilter />} />
          <Route path="/analyse" element={<AnalyseLoader />} />
          <Route path="/playlist" element={<ExternalPlaylistLoader />} />
          <Route path="/playlists" element={<Playlists />} />
          <Route path="/playlists/import" element={<PlaylistImport />} />
          <Route path="/upload-cs2" element={<Upload2 />} />
          <Route path="/account" element={<Account />} />
          <Route path="/support" element={<Support />} />
          <Route path="/checkout/stripegumroadsuccess" element={<StripeGumroadSuccess />} />
          <Route path="/checkout/success" element={<CheckoutSuccess />} />
          <Route path="/checkout/cancel" element={<CheckoutCancel />} />
          <Route path="/subscribe/org" element={<SubscribeOrg />} />
          <Route path="/match/:id" element={<MatchLoader />} />
          <Route path="/match/:id/:mapName" element={<MatchLoader />} />
          <Route path="/match/:id/:mapName/:roundName" element={<MatchLoader />} />
          <Route path="/match/hltv/:id/:mapName/:roundName" element={<ExternalMatchLoader site="hltv" />} />
          <Route path="/match/esea/:id/:mapName/:roundName" element={<ExternalMatchLoader site="esea" />} />
          <Route path="*" element={<Nopage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
