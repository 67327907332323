import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useWindowSize } from "usehooks-ts";
import React from 'react';
import styles from './RoundFilter.module.css';
import { Button, Col, Container, Form, ProgressBar, Row, Spinner, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import TeamPicker from "../components/TeamPicker";
import TeamIcon from "../components/TeamIcon";
import { ApiMatchesData, MatchData, TeamsData } from "./Matches";
import { fetchTeams } from "../utils/team_cache";
import { fetchFromApi } from "../utils/api";
import { isLocked, getHasSubscription, getSubscription } from "../utils/premium";
import { Playlist, RoundMetadata, RoundsMetadata } from "../utils/types";
import { sortPlaylist } from "../utils/playlist";
import { buyTypeToAPI } from "../utils/economy";
import { post_usage } from "../utils/usage";

// Add new filter options
const economyOptions: string[] = ['Eco', 'Eco+', 'Semi', 'Full'];
const sideOptions: string[] = ['T', 'CT'];
const outcomeOptions: string[] = ['W', 'L'];
const mapnameOptions: string[] = ['ancient', 'anubis', 'dust2', 'inferno', 'mirage', 'nuke', 'overpass', 'vertigo'];
const timespanOptions: string[] = ['1 Month', '3 Months', 'All time'];

interface RoundFilterMultiSelectButtonGroupProps {
  groupId: string,
  options: string[];
  value: string[];
  wideMode: boolean;
  onSelect: (selected: string[]) => void;
}

// Button Group component
const RoundFilterMultiSelectButtonGroup: React.FC<RoundFilterMultiSelectButtonGroupProps> = ({ groupId, options, value, wideMode, onSelect }) => {
  const handleChange = (val: string[]) => {
    onSelect(val);
  };

  return (
    <div>
      <ToggleButtonGroup className={`${wideMode ? styles.buttonGroupFullWidth : styles.buttonGroupSmall}`} key={`btngroup-${groupId}`} id={`btngroup-${groupId}`} type="checkbox" value={value} onChange={handleChange}>
        {options.map((option, index) => {
          const optionCls = option.replace('+', 'plus').replace(' ', '').replace(/\d/g, '');
          const optionSelectedCls = optionCls + 'Selected';

          return (
            <ToggleButton
              key={`btn-${groupId}-${index}`}
              id={`btn-${groupId}-${index}`}
              className={`${styles.toggleButton} ${!wideMode && styles.toggleButtonSmall} ${styles[optionCls]} ${value.includes(option) ? styles[optionSelectedCls] : ''}`}
              value={option}
              variant="outline-primary"
            >
              {option}
            </ToggleButton>
          )
        })}
      </ToggleButtonGroup>
    </div>
  );
};

export function filterEmptyOrLockedMatches(matches: RoundsMetadata, subscription: string): RoundsMetadata {
  const finalPlaylist: RoundsMetadata = {};
  for (const matchId of Object.keys(matches).sort().reverse()) {
    if (matches[matchId].length > 0 && (!isLocked(matchId, subscription))) {
      finalPlaylist[matchId] = matches[matchId];
    }
  }

  return finalPlaylist;
}

export default function RoundFilter() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const teamParameter = searchParams.get('team');
  const mapnameParameter = searchParams.get('mapname');

  const [selectedTeam, setSelectedTeam] = useState<string>(teamParameter || sessionStorage.getItem('round-filter-team') || 'ence');
  const [selectedOpponent, setSelectedOpponent] = useState<string>(sessionStorage.getItem('round-filter-opponent') || 'Any');
  const [selectedTeamEconomy, setSelectedTeamEconomy] = useState<string[]>(JSON.parse(sessionStorage.getItem('round-filter-teamEconomy') || '[]'));
  const [selectedTeamSide, setSelectedTeamSide] = useState<string[]>(JSON.parse(sessionStorage.getItem('round-filter-teamSide') || '[]'));
  const [selectedTeamOutcome, setSelectedTeamOutcome] = useState<string[]>(JSON.parse(sessionStorage.getItem('round-filter-teamOutcome') || '[]'));
  const [selectedOpponentEconomy, setSelectedOpponentTeamEconomy] = useState<string[]>(JSON.parse(sessionStorage.getItem('round-filter-opponentEconomy') || '[]'));
  const [selectedOpponentSide, setSelectedOpponentSide] = useState<string[]>(JSON.parse(sessionStorage.getItem('round-filter-opponentSide') || '[]'));
  const [selectedOpponentOutcome, setSelectedOpponentOutcome] = useState<string[]>(JSON.parse(sessionStorage.getItem('round-filter-opponentOutcome') || '[]'));
  const [selectedMapname, setSelectedMapname] = useState<string>(mapnameParameter || sessionStorage.getItem('round-filter-mapname') || 'ancient');
  const [selectedTimespan, setSelectedTimespan] = useState<string>(sessionStorage.getItem('round-filter-timespan') || '1 Month');
  const [disabledMatchIds, setDisabledMatchIds] = useState(new Set());

  const [activeDropdown, setActiveDropdown] = useState<string>('');
  const [teams, setTeams] = useState<TeamsData>(JSON.parse(sessionStorage.getItem('teamscache') || '{}'));

  const [matches, setMatches] = useState<MatchData[] | undefined>(undefined);
  const [playlistRounds, setPlaylistRounds] = useState<RoundsMetadata | undefined>(undefined);
  const [fetchState, setFetchState] = useState<'matches' | 'rounds' | 'idle'>('matches');

  const [fetchIndex, setFetchIndex] = useState<number>(0);

  const [hasSubscription, setHasSubscription] = useState(false);
  const [subscription, setSubscription] = useState("FREE");

  useEffect(() => {
    getHasSubscription().then((isPremium) => {
      setHasSubscription(isPremium);
    });
  }, []);

  useEffect(() => {
    getSubscription().then((subscription) => {
      setSubscription(subscription);
    });
  }, []);

  useEffect(() => { post_usage('filter_window') }, []);

  // store teamParameter and mapnameParameter in session storage
  useEffect(() => {
    if (teamParameter) {
      sessionStorage.setItem('round-filter-team', teamParameter);
    }
    if (mapnameParameter) {
      sessionStorage.setItem('round-filter-mapname', mapnameParameter);
    }
  }, [teamParameter, mapnameParameter]);

  // Initialize the state from sessionStorage when the component mounts
  useEffect(() => {
    const disabledMatchesString = sessionStorage.getItem('round-filter-disabled-matches');
    if (disabledMatchesString) {
      const disabledMatchesArray = disabledMatchesString.split(',');
      setDisabledMatchIds(new Set(disabledMatchesArray));
    }
  }, []);

  useEffect(() => {
    if (!sessionStorage.getItem('teamscache')) {
      fetchTeams().then((data) => { setTeams(data); })
    }
  }, []);

  useEffect(() => {
    if (fetchState !== 'matches')
      return;
    setFetchIndex((prev) => prev + 1);
    setMatches(undefined);
    const afterParam = getAfterParam(selectedTimespan);
    const url = `/matches?teams=${selectedTeam}&maps=${selectedMapname.toLowerCase()}${afterParam}`;
    fetchFromApi(url)
      .then((response) => response.json())
      .then((data: ApiMatchesData) => {
        const sortedMatchesArray = Object.keys(data)
          .map((key) => { data[key].match_id = key; return data[key]; })
          .sort((a, b) => {
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);
            if (dateA > dateB) return -1;
            if (dateA < dateB) return 1;
            if (a.stars < b.stars) return 1;
            if (a.stars > b.stars) return -1;
            return b.timestamp - a.timestamp;
          });
        setMatches(sortedMatchesArray);
        setFetchState('rounds');
      })
      .catch((err) => {
        console.error(err.message);
      });
  }, [fetchState]);

  const getAfterParam = (timespan: string) => {
    switch (timespan) {
      case '1 Month':
        const oneMonthAgo = new Date();
        oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
        return `&after=${oneMonthAgo.toISOString().split('T')[0]}`;
      case '3 Months':
        const threeMonthsAgo = new Date();
        threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
        return `&after=${threeMonthsAgo.toISOString().split('T')[0]}`;
      case 'All time':
        return '';
      default:
        console.error('Invalid timespan');
        console.log(timespan);
        return '';
    }
  };

  useEffect(() => {
    type QueryParams = {
      match_id: string,
      team_key: string,
      map: string,
      opponent_key: string | undefined,
      team_win: string,
      team_buy_types: string,
      opponent_buy_types: string,
      sides: string
    };

    async function fetchCSGORounds(match_id: string, currentFetchIndex: number) {
      const queryParams: Partial<QueryParams> = {
        match_id: match_id,
        team_key: selectedTeam,
        opponent_key: selectedOpponent !== 'Any' ? selectedOpponent : undefined,
        map: selectedMapname.toLowerCase(),
      };

      if (selectedTeamOutcome.length > 0) {
        queryParams.team_win = selectedTeamOutcome[0] === 'W' ? 'true' : 'false';
      }

      if (selectedTeamEconomy.length > 0) {
        queryParams.team_buy_types = selectedTeamEconomy.map(buyTypeToAPI).join(',');
      }

      if (selectedOpponentEconomy.length > 0) {
        queryParams.opponent_buy_types = selectedOpponentEconomy.map(buyTypeToAPI).join(',');
      }

      if (selectedTeamSide.length > 0) {
        queryParams.sides = selectedTeamSide.join(',');
      } else {
        queryParams.sides = 'BOTH';
      }

      let url = '/rounds?';
      Object.keys(queryParams).forEach((key, index) => {
        const value = queryParams[key as keyof QueryParams];
        if (value !== undefined) {
          url += `${key}=${value}`;
          if (index < Object.keys(queryParams).length - 1) {
            url += '&';
          }
        }
      });

      try {
        const response = await fetchFromApi(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const responseRounds: RoundsMetadata = await response.json();
        if (currentFetchIndex === fetchIndex) {
          setPlaylistRounds((prevRounds) => {
            if (prevRounds) {
              if (Object.keys(responseRounds).length > 0) {
                return { ...prevRounds, [match_id]: responseRounds[match_id] };
              } else {
                return { ...prevRounds, [match_id]: [] };
              }
            } else {
              if (Object.keys(responseRounds).length > 0) {
                return { [match_id]: responseRounds[match_id] };
              } else {
                return { [match_id]: [] };
              }
            }
          });
        }
      } catch (error) {
        setPlaylistRounds((prevRounds) => {
          if (prevRounds) {
            return { ...prevRounds, [match_id]: [] };
          } else {
            return { [match_id]: [] };
          }
        });
        console.error("An error occurred while fetching the rounds.", error);
      }
    }

    setPlaylistRounds(undefined);
    if (matches && matches.length > 0) {
      matches.forEach((match) => {
        fetchCSGORounds(match.match_id, fetchIndex);
      });
    } else {
      setPlaylistRounds({});
    }
  }, [matches, fetchIndex]);

  // useEffect for when rounds are fetched. Compare matches to rounds keys and setFetchState to idle when all matches have rounds.
  useEffect(() => {
    if (fetchState !== 'rounds')
      return;
    if (matches && playlistRounds) {
      if (matches.length === Object.keys(playlistRounds).length) {
        setFetchState('idle');
      }
    }
  }, [playlistRounds]);

  const onSearchFilterChanged = () => {
    setDisabledMatchIds(new Set());
    setFetchState('matches');
  };

  const teamEconomyChanged = (selected: string[]) => {
    setSelectedTeamEconomy(selected);
    sessionStorage.setItem('round-filter-teamEconomy', JSON.stringify(selected));
    onSearchFilterChanged();
  };

  const teamSideChanged = (selected: string[]) => {
    let opponentSide: string[] = [];
    if (selected.length === 2) {
      if (selectedTeamSide.includes('T') && selectedOpponentSide.includes('CT')) {
        selected = ['CT'];
        opponentSide = ['T'];
      } else {
        selected = ['T'];
        opponentSide = ['CT'];
      }
    } else {
      if (selected.length === 1 && selected[0] === 'T') {
        opponentSide = ['CT'];
      } else if (selected.length === 1 && selected[0] === 'CT') {
        opponentSide = ['T'];
      } else {
        opponentSide = [];
      }
    }
    setSelectedTeamSide(selected);
    setSelectedOpponentSide(opponentSide);
    sessionStorage.setItem('round-filter-teamSide', JSON.stringify(selected));
    sessionStorage.setItem('round-filter-opponentSide', JSON.stringify(opponentSide));
    onSearchFilterChanged();
  };

  const teamOutcomeChanged = (selected: string[]) => {
    let opponentOutcome: string[] = [];
    if (selected.length === 2) {
      if (selectedTeamOutcome.includes('W') && selectedOpponentOutcome.includes('L')) {
        selected = ['L'];
        opponentOutcome = ['W'];
      } else {
        selected = ['W'];
        opponentOutcome = ['L'];
      }
    } else {
      if (selected.length === 1 && selected[0] === 'W') {
        opponentOutcome = ['L'];
      } else if (selected.length === 1 && selected[0] === 'L') {
        opponentOutcome = ['W'];
      } else {
        opponentOutcome = [];
      }
    }
    setSelectedTeamOutcome(selected);
    setSelectedOpponentOutcome(opponentOutcome);
    sessionStorage.setItem('round-filter-teamOutcome', JSON.stringify(selected));
    sessionStorage.setItem('round-filter-opponentOutcome', JSON.stringify(opponentOutcome));
    onSearchFilterChanged();
  };

  const opponentEconomyChanged = (selected: string[]) => {
    setSelectedOpponentTeamEconomy(selected);
    sessionStorage.setItem('round-filter-opponentEconomy', JSON.stringify(selected));
    onSearchFilterChanged();
  };

  const opponentSideChanged = (selected: string[]) => {
    let teamSide: string[] = [];
    if (selected.length === 2) {
      if (selectedTeamSide.includes('T') && selectedOpponentSide.includes('CT')) {
        selected = ['T'];
        teamSide = ['CT'];
      } else {
        selected = ['CT'];
        teamSide = ['T'];
      }
    } else {
      if (selected.length === 1 && selected[0] === 'T') {
        teamSide = ['CT'];
      } else if (selected.length === 1 && selected[0] === 'CT') {
        teamSide = ['T'];
      } else {
        teamSide = [];
      }
    }

    setSelectedOpponentSide(selected);
    setSelectedTeamSide(teamSide);
    sessionStorage.setItem('round-filter-opponentSide', JSON.stringify(selected));
    sessionStorage.setItem('round-filter-teamSide', JSON.stringify(teamSide));
    onSearchFilterChanged();
  };

  const opponentOutcomeChanged = (selected: string[]) => {
    let teamOutcome: string[] = [];
    if (selected.length === 2) {
      if (selectedOpponentOutcome.includes('W') && selectedTeamOutcome.includes('L')) {
        selected = ['L'];
        teamOutcome = ['W'];
      } else {
        selected = ['W'];
        teamOutcome = ['L'];
      }
    } else {
      if (selected.length === 1 && selected[0] === 'W') {
        teamOutcome = ['L'];
      } else if (selected.length === 1 && selected[0] === 'L') {
        teamOutcome = ['W'];
      } else {
        teamOutcome = [];
      }
    }
    setSelectedOpponentOutcome(selected);
    setSelectedTeamOutcome(teamOutcome);
    sessionStorage.setItem('round-filter-opponentOutcome', JSON.stringify(selected));
    sessionStorage.setItem('round-filter-teamOutcome', JSON.stringify(teamOutcome));
    onSearchFilterChanged();
  };

  const mapChanged = (selected: string[]) => {
    const changed = selected.find((item) => item !== selectedMapname);
    if (changed) {
      setSelectedMapname(changed.toLowerCase());
      sessionStorage.setItem('round-filter-mapname', changed.toLowerCase());
      onSearchFilterChanged();
    }
  }

  const timespanChanged = (selected: string[]) => {
    const changed = selected.find((item) => item !== selectedTimespan);
    if (changed) {
      setSelectedTimespan(changed);
      sessionStorage.setItem('round-filter-timespan', changed);
      onSearchFilterChanged();
    }
  }

  const handleCheckboxChange = (matchId: string, isChecked: boolean) => {
    const newDisabledMatchIds = new Set(disabledMatchIds);
    if (isChecked) {
      newDisabledMatchIds.delete(matchId);
    } else {
      newDisabledMatchIds.add(matchId);
    }
    setDisabledMatchIds(newDisabledMatchIds);
    const disabledMatchesString = Array.from(newDisabledMatchIds).join(',');
    sessionStorage.setItem('round-filter-disabled-matches', disabledMatchesString);
  };

  const { width, height } = useWindowSize();
  const navigate = useNavigate();

  const wideModeWidth = 900;
  const wideMode = width > wideModeWidth;
  const mapToggleClassName = wideMode ? styles.mapToggle : styles.mapToggleSmall;

  const handleDropdownToggle = (dropdown: string) => {
    setActiveDropdown((old) => old === dropdown ? '' : dropdown);
  };

  // Check how many rounds in total in the rounds dictionary
  let visibleRounds = 0;
  if (playlistRounds) {
    Object.keys(playlistRounds).forEach((key) => {
      visibleRounds += playlistRounds[key].length;
    });
  }

  function processFinalPlaylist(rawRounds: RoundsMetadata): [Playlist, string, RoundMetadata | null] {
    const filteredRounds: RoundsMetadata = {};
    for (const matchId in rawRounds) {
      if (!disabledMatchIds.has(matchId)) {
        filteredRounds[matchId] = rawRounds[matchId];
      }
    }

    const finalPlaylistRounds = filterEmptyOrLockedMatches(filteredRounds, subscription);

    const finalPlaylist: Playlist = {
      rounds: finalPlaylistRounds,
      team_key: selectedTeam,
      match_id: undefined,
      mapname: selectedMapname,
      external: false,
      side: selectedTeamSide.length > 0 ? selectedTeamSide[0] : '',
    };

    Object.keys(finalPlaylist.rounds).forEach((matchId) => {
      finalPlaylist.rounds[matchId].forEach((round) => {
        if (teams[round.t_team_key]) {
          round.t_team_flag = teams[round.t_team_key].flag;
          round.t_team = teams[round.t_team_key].name;
        }
        if (teams[round.ct_team_key]) {
          round.ct_team_flag = teams[round.ct_team_key].flag;
          round.ct_team = teams[round.ct_team_key].name;
        }
      });
    });

    const sortedPlaylist = sortPlaylist(finalPlaylist);

    const firstMatch = Object.keys(sortedPlaylist.rounds)[0];
    const firstRound = firstMatch ? sortedPlaylist.rounds[firstMatch][0] : null;

    return [finalPlaylist, firstMatch, firstRound];
  }

  function onHandleAnalyseClick() {
    if (playlistRounds) {
      const [finalPlaylist, firstMatch, firstRound] = processFinalPlaylist(playlistRounds);
      sessionStorage.setItem('playlist-analyse', JSON.stringify(finalPlaylist));
      navigate(`/analyse`);
    }
  }

  function onHandleReplayClick() {
    if (playlistRounds) {
      const [finalPlaylist, firstMatch, firstRound] = processFinalPlaylist(playlistRounds);
      sessionStorage.setItem('playlist', JSON.stringify(finalPlaylist));
      if (firstRound) {
        navigate(`/match/${firstMatch}/${firstRound.mapname}/${firstRound.roundnum}`);
      }
    }
  }

  const totalRounds = playlistRounds ? Object.entries(playlistRounds)
    .filter(([matchId]) => !disabledMatchIds.has(matchId))
    .reduce((total, [, matchRounds]) => total + matchRounds.length, 0) : 0;

  const handleCheckAll = (checked: boolean) => {
    let newDisabledMatchIds = new Set(disabledMatchIds);
    if (checked) {
      // If checking all, clear the disabledMatchIds set
      newDisabledMatchIds = new Set();
    } else {
      // If unchecking all, add all matchIds to the disabledMatchIds set
      newDisabledMatchIds = new Set(playlistRounds ? Object.keys(playlistRounds) : []);
    }

    setDisabledMatchIds(newDisabledMatchIds);
    const disabledMatchesString = Array.from(newDisabledMatchIds).join(',');
    sessionStorage.setItem('round-filter-disabled-matches', disabledMatchesString);
  };

  return (
    <div className={`container ${styles.roundsContainer}`}>
      <div>
        <Container fluid>
          <Row className={styles.gridRow}>
            <Col>
              <div className={styles.teamPickerWrapper}>
                <TeamPicker
                  isOpen={activeDropdown === 'team'}
                  selectedTeam={selectedTeam}
                  wideMode={width > wideModeWidth}
                  canSelectAny={false}
                  label="Team"
                  btnClassName={styles.teamFilterButton}
                  btnClassNameWide={styles.teamFilterButton}
                  onToggle={(isOpen: boolean) => handleDropdownToggle('team')}
                  onSelect={(team: string) => {
                    sessionStorage.setItem('round-filter-team', team);
                    setSelectedTeam(team);
                    onSearchFilterChanged();
                  }}
                />
              </div>
              <RoundFilterMultiSelectButtonGroup
                groupId="team-side"
                options={sideOptions}
                value={selectedTeamSide}
                wideMode={true}
                onSelect={(selected) => teamSideChanged(selected)}
              />
              <RoundFilterMultiSelectButtonGroup
                groupId="team-economy"
                options={economyOptions}
                value={selectedTeamEconomy}
                wideMode={true}
                onSelect={(selected) => teamEconomyChanged(selected)}
              />
              <RoundFilterMultiSelectButtonGroup
                groupId="team-outcome"
                options={outcomeOptions}
                value={selectedTeamOutcome}
                wideMode={true}
                onSelect={(selected) => teamOutcomeChanged(selected)}
              />
            </Col>
            <Col>
              <div className={styles.teamPickerWrapper}>
                <TeamPicker
                  isOpen={activeDropdown === 'opponent'}
                  selectedTeam={selectedOpponent}
                  wideMode={width > wideModeWidth}
                  canSelectAny={true}
                  label="Opponent"
                  btnClassName={styles.teamFilterButton}
                  btnClassNameWide={styles.teamFilterButton}
                  onToggle={(isOpen: boolean) => handleDropdownToggle('opponent')}
                  onSelect={(team: string) => {
                    sessionStorage.setItem('round-filter-opponent', team);
                    setSelectedOpponent(team);
                    onSearchFilterChanged();
                  }}
                />
              </div>
              <RoundFilterMultiSelectButtonGroup
                groupId="opponent-side"
                options={sideOptions}
                value={selectedOpponentSide}
                wideMode={true}
                onSelect={(selected) => opponentSideChanged(selected)}
              />
              <RoundFilterMultiSelectButtonGroup
                groupId="opponent-economy"
                options={economyOptions}
                value={selectedOpponentEconomy}
                wideMode={true}
                onSelect={(selected) => opponentEconomyChanged(selected)}
              />
              <RoundFilterMultiSelectButtonGroup
                groupId="opponent-outcome"
                options={outcomeOptions}
                value={selectedOpponentOutcome}
                wideMode={true}
                onSelect={(selected) => opponentOutcomeChanged(selected)}
              />
            </Col>
          </Row>
          <Row className={styles.gridRow}>
            <Col>
              <RoundFilterMultiSelectButtonGroup
                groupId="mapname"
                options={mapnameOptions.map(option =>
                  option.charAt(0).toUpperCase() + option.slice(1)
                )}
                value={[selectedMapname.charAt(0).toUpperCase() + selectedMapname.slice(1)]}
                wideMode={wideMode}
                onSelect={(selected) => mapChanged(selected.map(option => option.toLowerCase()))}
              />
            </Col>
          </Row>
          <Row className={styles.gridRow}>
            <Col>
              <RoundFilterMultiSelectButtonGroup
                groupId="timespan"
                options={timespanOptions}
                value={[selectedTimespan]}
                wideMode={wideMode}
                onSelect={(selected) => timespanChanged(selected.map(option => option))}
              />
            </Col>
          </Row>
          <Row className={styles.gridRow}>
            <Col>
              <ProgressBar
                className={"roundFilterProgressBar"}
                animated={fetchState !== 'idle'}
                striped={fetchState !== 'idle'}
                now={
                  playlistRounds && matches
                    ? Math.round(Object.keys(playlistRounds).length * 100 / matches.length)
                    : 0
                }
                label={
                  playlistRounds && matches
                    ? `${Math.round(Object.keys(playlistRounds).length * 100 / matches.length)}%`
                    : '0%'
                }
                style={{ marginTop: '15px', marginBottom: '10px', width: '100%' }}
              />
              {playlistRounds === undefined || teams === undefined || matches === undefined || fetchState === 'matches' || fetchState === 'rounds' && visibleRounds === 0 ?
                <div className="py-3" style={{ textAlign: "center" }}><span style={{ color: '#555' }}><Spinner animation="border" /><span className="loading-label">Loading...</span></span></div> :
                <div className={styles.resultsWrapper}>
                  <table className={styles.results}>
                    <tbody>
                      {Object.keys(playlistRounds).sort().reverse().map((matchId: string) => {
                        // check if teamA_key and teamB_key are in teams
                        const teamA_key = matchId.split(".")[1];
                        const teamB_key = matchId.split(".")[2];

                        // Search from matches
                        const match = matches.find((match) => {
                          return match.match_id === matchId;
                        });

                        if (match === undefined) {
                          return null;
                        }

                        const matchRounds = playlistRounds[matchId];
                        if (matchRounds.length === 0) {
                          return null;
                        }

                        const locked = isLocked(matchId, subscription);
                        const selected = !disabledMatchIds.has(matchId);

                        return (
                          <tr key={matchId}>
                            <td className={styles.tableRow} style={{ width: '30px' }}>
                              <Form.Check
                                type="checkbox"
                                checked={selected}
                                onChange={(e) => handleCheckboxChange(matchId, e.target.checked)}
                              />
                            </td>
                            {wideMode ? <>
                              <td className={styles.tableRow} style={{ width: '120px' }}><span style={{ color: '#555' }}>{match['date']}</span></td>
                              <td className={styles.tableRow} style={{ width: '120px' }}><span style={{ color: '#383838' }}>{match.time.split(":")[0]}:{match.time.split(":")[1]}</span></td>
                            </> : null}
                            {locked ?
                              <td className={styles.tableRow} style={{ width: '250px' }}><span className={styles.premiumLink}>{subscription === 'BASIC' ? "Premium subscription only" : "For subscription only"}</span></td> :
                              <td className={styles.tableRow} style={{ width: '250px' }}><TeamIcon size={20} name={selectedTeam === teamA_key ? teamA_key : teamB_key} flag={selectedTeam === teamA_key ? teams[teamA_key]?.flag : teams[teamB_key]?.flag} opacity={selected ? 1 : 0.33} /><span className={selected ? styles.teamLabel : styles.teamLabelUnselected}>{selectedTeam === teamA_key ? match.teamA_name : match.teamB_name}</span></td>
                            }
                            <td className={styles.tableRow} style={{ width: '400px' }}><TeamIcon size={20} name={selectedTeam === teamA_key ? teamB_key : teamA_key} flag={selectedTeam === teamA_key ? teams[teamB_key]?.flag : teams[teamA_key]?.flag} opacity={selected ? 1 : 0.33} /><span className={selected ? styles.teamLabel : styles.teamLabelUnselected}>{selectedTeam === teamA_key ? match.teamB_name : match.teamA_name}</span></td>
                            <td className={styles.tableRow} style={{ textAlign: 'right', color: selected ? '#888' : '#555', width: '40px' }}>{matchRounds.length}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                    {visibleRounds > 0 &&
                      <tfoot>
                        <tr>
                          <td className={styles.tableRow} style={{ borderTop: '1px dashed #444' }}>
                            <Form.Check
                              type="checkbox"
                              checked={totalRounds > 0}
                              onChange={(e) => handleCheckAll(e.target.checked)}
                            />
                          </td>
                          <td className={styles.tableRow} colSpan={wideMode ? 4 : 2} style={{ color: '#888', borderTop: '1px dashed #444' }}>
                            {totalRounds > 0 ? "Unselect all" : "Select all"}
                          </td>
                          <td className={styles.tableRow} style={{ textAlign: 'right', fontWeight: 'bold', borderTop: '1px dashed #444' }}>
                            {totalRounds}
                          </td>
                        </tr>
                      </tfoot>
                    }
                  </table>
                  {visibleRounds === 0 && fetchState === 'idle' ?
                    <>
                      <div className={styles.noresults}>No matching rounds found.</div>
                    </> : null
                  }
                </div>
              }
            </Col>
          </Row>
          {visibleRounds > 0 &&
            <Row className={styles.gridRow} style={{ marginBottom: '50px' }}>
              <Col style={{ textAlign: 'right' }}>
                {fetchState === 'idle' && visibleRounds > 0 && <>
                  <Button
                    style={{
                      position: "relative",
                      height: 38,
                      paddingLeft: '20px',
                      paddingRight: '20px',
                      marginRight: '10px',
                    }}
                    disabled={fetchState !== 'idle'}
                    className="btn-sm"
                    onClick={() => { onHandleAnalyseClick() }}
                    variant="warning"
                  >
                    <i className="bi bi-cup-hot" style={{ marginRight: '7px' }} />Analyse
                  </Button>
                  <Button
                    style={{
                      position: "relative",
                      height: 38,
                      paddingLeft: '20px',
                      paddingRight: '26px',
                    }}
                    disabled={fetchState !== 'idle'}
                    className="btn-sm"
                    onClick={() => { onHandleReplayClick() }}
                  >
                    <i className="bi bi-play-fill" style={{ marginRight: '4px' }}></i>Replay
                  </Button>
                </>
                }
              </Col>
            </Row>
          }
        </Container>
      </div>
    </div>
  );
}