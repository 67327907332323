import { useEffect, useRef, useState } from 'react';
import { Button, ButtonGroup, Overlay, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { getHasSubscription } from '../utils/premium';
import styles from './FooterButton.module.css';
import UpgradeInfoModal from './UpgradeInfoModal';

type PaintButtonProps = {
  activePaintColor: number[];
  isPainting: boolean;
  onTogglePainting: () => void;
  onActivePaintColorchange: (activeColor: number[]) => void;
  onUndoPaint: () => void;
};

function ColorButton(props: {
  color: number[];
  isActive: boolean;
  onClick: () => void;
}) {
  const { color, isActive, onClick } = props;
  const [r, g, b] = color;
  const style = isActive ? {
    backgroundColor: `rgb(${r}, ${g}, ${b}, 1)`,
    border: '1px solid #fff'
  } : {
    backgroundColor: `rgb(${r * 0.33}, ${g * 0.33}, ${b * 0.33}, 1)`,
  };
  return (
    <Button
      size="sm"
      className={styles.button}
      onClick={onClick}
      style={style}
      active={isActive}
    >
      &nbsp;
    </Button>
  );
}

export default function PaintButton(props: PaintButtonProps): JSX.Element {
  const [premium, setPremium] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [hoverTimeout, setHoverTimeout] = useState<number | null>(null);

  const target = useRef(null);

  const { activePaintColor: activeColor, isPainting, onTogglePainting, onActivePaintColorchange, onUndoPaint } = props;

  useEffect(() => {
    const checkPremium = async () => {
      const premium = await getHasSubscription();
      setPremium(premium);
    }
    setShowModal(false);
    checkPremium();
  }, []);

  const handleKeyDown = (event: { preventDefault: () => void }) => {
    event.preventDefault();
  };

  const buttonClasses = isPainting
    ? `${styles.button} ${styles.buttonActivated}`
    : styles.button;

  const colors = [
    [192, 192, 192],
    [255, 0, 0],
    [255, 175, 71],
    [71, 203, 255],
  ];

  const handleMouseEnter = () => {
    const timeout = window.setTimeout(() => {
      setShowTooltip(true);
    }, 300) as unknown as number; // cast to number
    setHoverTimeout(timeout);
  };
  
  const handleMouseLeave = () => {
    if (hoverTimeout) {
      window.clearTimeout(hoverTimeout); 
    }
    setShowTooltip(false);
    setHoverTimeout(null);
  };

  return (
    <>
      {isPainting && (
        <>
          <ButtonGroup style={{ marginLeft: '10px' }}>
            {colors.map((colorRow, i) => (
              <ColorButton
                key={i}
                color={colorRow}
                isActive={activeColor.join(',') === colorRow.join(',')}
                onClick={() => onActivePaintColorchange(colorRow)}
              />
            ))}
          </ButtonGroup>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id={`tooltip-undo-paint`}>
                Undo
              </Tooltip>
            }
          >
            <Button
              variant="outline-secondary"
              size="sm"
              className={styles.button}
              onClick={onUndoPaint}
            >
              <i className="bi bi-arrow-counterclockwise"></i>
            </Button>
          </OverlayTrigger>
        </>
      )}

      <Button
        ref={target}
        variant={isPainting ? 'secondary' : 'outline-secondary'}
        size="sm"
        className={buttonClasses}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={() => {
          if (premium) {
            onTogglePainting();
          } else {
            setShowModal(true);
          }
          setShowTooltip(false);
        }}
        onKeyDown={handleKeyDown}
      >
        <i className="bi bi-pencil"></i>
      </Button>
      
      <Overlay
        target={target.current}
        show={showTooltip}
        placement="top"
      >
        {(props) => (
          <Tooltip id="tooltip-playback-speed" {...props}>
            Paint (P)
          </Tooltip>
        )}
      </Overlay>

      <UpgradeInfoModal show={showModal} handleCloseModal={() => setShowModal(false)} />
    </>
  );
}
