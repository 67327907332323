import { mapInfo } from "../utils/maps";
import { world_to_screen } from "../utils/math";
import { PlayerFrameData } from "../utils/types";

function hexToRgbArray(hex: string, opacity: number): number[] {
  // Ensure the hex string starts with "#" and is 7 characters long
  if (hex.startsWith('#') && hex.length === 7) {
    // Extract the red, green, and blue components from the hex string
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    // Return the RGB values as an array
    return [r, g, b, opacity];
  } else {
    throw new Error('Invalid hex color format. Expected format: #RRGGBB');
  }
}

const AnalyseSVGPlayer = ({ data, currentTick, scaleFactor, mapName, upperView, invisible, activePlayer, hoveredRound, marqueedPlayer, nonMarqueedPlayer, playerColor }: {
  data: PlayerFrameData,
  currentTick: number,
  scaleFactor: number,
  mapName: string,
  upperView: boolean,
  invisible: boolean,
  activePlayer: boolean,
  hoveredRound: boolean,
  marqueedPlayer: boolean,
  nonMarqueedPlayer: boolean,
  playerColor: string,
}) => {

  const position = world_to_screen([data.x, data.y, data.z], mapName);
  const mInfo = mapInfo[mapName as keyof typeof mapInfo];
  const zOpacity = (data.z - mInfo.minZ) / (mInfo.maxZ - mInfo.minZ);
  const opacity = invisible ? 0.0 : Math.min(Math.max(upperView ? zOpacity : 1.0 - zOpacity, 0.05), 1.0);
  let color = hexToRgbArray(playerColor, opacity);

  if (!data.isAlive) {
    color = color.map((c) => c * 0.2 + 48);
  }

  let strokeColor: number[] = data.isAlive ? [255, 255, 255, opacity] : [64, 64, 64, opacity];
  let activeStroke: number[] = data.isAlive ? [255, 255, 255, opacity * 0.33] : [64, 64, 64, opacity * 0.33];
  const r = 30.0 / scaleFactor;
  let shadowColor = [0, 0, 0, 0.33 * opacity];
  const shadowDistance = 0.1*r;
  let strokeWidth = r * 0.06;
  const hoverCircleColor = [128, 255, 128, opacity];

  if (hoveredRound) {
    color = [64, 128, 64, opacity];
    strokeColor = [0, 0, 0, opacity];
    strokeWidth = r * 0.08;
  }

  if(nonMarqueedPlayer) {
    color = [128, 128, 128, 0];
  }
  
  return (
    <>
      {hoveredRound && <>
        <circle
          transform={`translate(${position[0] + shadowDistance}, ${position[1] + shadowDistance})`}
          r={r * 0.4}
          fill={`rgb(${shadowColor[0]}, ${shadowColor[1]}, ${shadowColor[2]}, ${shadowColor[3]})`}
        />
        <circle
          transform={`translate(${position[0]}, ${position[1]})`}
          r={r * 0.35}
          stroke={`rgb(${hoverCircleColor[0]}, ${hoverCircleColor[1]}, ${hoverCircleColor[2]}, ${hoverCircleColor[3]})`}
          strokeWidth={strokeWidth}
          fill={`rgb(0,0,0,0)`}
        />
        <circle
          transform={`translate(${position[0]}, ${position[1]})`}
          r={r * 0.25}
          stroke={`rgb(${strokeColor[0]}, ${strokeColor[1]}, ${strokeColor[2]}, ${strokeColor[3]})`}
          strokeWidth={strokeWidth}
          fill={`rgb(${color[0]}, ${color[1]}, ${color[2]}, ${color[3]})`}
        />
      </>}
      {!hoveredRound && <circle
        transform={`translate(${position[0]}, ${position[1]})`}
        r={r * 0.25}
        stroke={`rgb(${strokeColor[0]}, ${strokeColor[1]}, ${strokeColor[2]}, ${strokeColor[3]})`}
        strokeWidth={strokeWidth}
        fill={`rgb(${color[0]}, ${color[1]}, ${color[2]}, ${color[3]})`}
      />}
    </>
  )
};

export default AnalyseSVGPlayer;