import { Projectile } from "../utils/projectile";
import { FireInfo, SmokeInfo } from "./MapView";
import MapViewSVG from "./MapViewSVG";
import { DamageData, GrenadeData, PlayerFrameData, PlayerGizmos, WeaponFireData } from "../utils/types";
import { Paintings } from "./Paintings";

interface MapViewLayerProps {
  canRender: boolean,
  upperView: boolean,
  mapName: string,
  mapOffset: number[],
  mapZoom: number,
  currentTick: number | undefined,
  playerFrames: PlayerFrameData[],
  deadPlayerFrames: PlayerFrameData[],
  projectiles: Projectile[],
  explosions: GrenadeData[],
  smokes: SmokeInfo[],
  fires: FireInfo[],
  weaponFires: WeaponFireData[],
  bullets: DamageData[],
  damages: DamageData[],
  bomb: {x: number, y: number, z: number} | null,
  bombPlanted: boolean,
  bombTimeLeft: number,
  bombDefusing: boolean,
  hoveredPlayer: number | undefined,
  playerGizmos: PlayerGizmos,
  activePaintColor: number[],
  paintUndoCount: number,
  paintClearCount: number,
  painting: boolean,
  csVersion: string | undefined,
  onPlayerBeginHover: (steamID: number) => void,
  onPlayerEndHover: (steamID: number) => void,
}

export function sortPlayers(a: PlayerFrameData, b: PlayerFrameData): number {
  if (a.isAlive === b.isAlive) {
    const zDiff = Math.abs(a.z - b.z);
    if (zDiff < 100) {
      return a.steamID - b.steamID;
    } else {
      return a.z - b.z;
    }
  } else {
    return a.isAlive ? 1 : -1;
  }
}

export function sortPlayersLower(a: PlayerFrameData, b: PlayerFrameData): number {
  if (a.isAlive === b.isAlive) {
    const zDiff = Math.abs(b.z - a.z);
    if (zDiff < 50) {
      return a.steamID - b.steamID;
    } else {
      return b.z - a.z;
    }
  } else {
    return a.isAlive ? 1 : -1;
  }
}

const MapViewLayer = ({
  canRender,
  upperView,
  mapName,
  mapOffset,
  mapZoom,
  currentTick,
  playerFrames,
  deadPlayerFrames,
  projectiles,
  explosions,
  smokes,
  fires,
  weaponFires,
  bullets,
  damages,
  bomb,
  bombPlanted,
  bombTimeLeft,
  bombDefusing,
  hoveredPlayer,
  playerGizmos,
  activePaintColor,
  paintUndoCount,
  paintClearCount,
  painting,
  csVersion,
  onPlayerBeginHover,
  onPlayerEndHover,
}: MapViewLayerProps) => {
  const lower = <div className={!upperView ? `map-view-image-fullbright` : `map-view-image-dimmed`} style={{ backgroundImage: mapName === 'de_vertigo' || mapName === 'de_nuke' ? `url('${process.env.PUBLIC_URL}/maps/${mapName}_lower.png')` : '' }}></div>
  const upper = <div className={upperView ? `map-view-image-fullbright map-view-shadows` : `map-view-image-dimmed map-view-shadows`} style={{ backgroundImage: `url('${process.env.PUBLIC_URL}/maps/${mapName}.png')` }}></div>

  const backgrounds = upperView ? <>{lower}{upper}</> : <>{upper}{lower}</>;

  return (
    <>
      <div className="map-view-wrapper p-3">
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "100%",
            transform: `translate(${mapOffset[0]}vmin, ${mapOffset[1]}vmin) scale(${mapZoom})`,
          }}
        >          
          <div style={{ position: "absolute", width: "100%", height: "100%" }}>
            {backgrounds}
          </div>
          {canRender && currentTick ? (
            <div style={{ position: "absolute", width: "100%", height: "100%" }}>
              <MapViewSVG
                upperView={upperView}
                mapName={mapName}
                mapZoom={mapZoom}
                currentTick={currentTick}
                playerFrames={playerFrames}
                deadPlayerFrames={deadPlayerFrames}
                projectiles={projectiles}
                explosions={explosions}
                smokes={smokes}
                fires={fires}
                weaponFires={weaponFires}
                bullets={bullets}
                damages={damages}
                bomb={bomb}
                bombPlanted={bombPlanted}
                bombTimeLeft={bombTimeLeft}
                bombDefusing={bombDefusing}
                hoveredPlayer={hoveredPlayer}
                playerGizmos={playerGizmos}
                enablePointerEvents={!painting}
                csVersion={csVersion}
                onPlayerBeginHover={onPlayerBeginHover}
                onPlayerEndHover={onPlayerEndHover}
              />
            </div>
          ) : null}
        </div>
        {painting ?
          <Paintings activeColor={activePaintColor} paintUndoCount={paintUndoCount} paintClearCount={paintClearCount} />
        : null}
      </div>
    </>
  );
}

export default MapViewLayer;