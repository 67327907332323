import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Alert } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import CustomModalHeader from './CustomModalHeader';

type PasswordResetProps = {
  show: boolean;
  onClose: () => void;
};


const PasswordReset = ({ show, onClose }: PasswordResetProps) => {
  const [email, setEmail] = useState('');
  const [confirmationCode, setConfirmationCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showReset, setShowReset] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (showSuccess) {
      setTimeout(() => {
        setShowSuccess(false);
        setShowConfirmation(false);
        setShowReset(false);
        setEmail('');
        setConfirmationCode('');
        setNewPassword('');
        setConfirmPassword('');
        onClose();
      }, 3000);
    }
  }, [showSuccess]);

  const handlePasswordReset = async () => {
    try {
      await Auth.forgotPassword(email);
      setShowConfirmation(true);
    } catch (error: any) {
      setError(error.message);
    }
  };

  const handleNewPassword = async () => {
    if (newPassword !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }
    try {
      await Auth.forgotPasswordSubmit(email, confirmationCode, newPassword);
      setError('');
      setShowSuccess(true);
    } catch (error: any) {
      setError(error.message);
    }
  };

  return (
    <Modal show={show} onHide={onClose}>
      <CustomModalHeader />
      <Modal.Body>
        {error && <Alert variant='danger'>{error}</Alert>}
        {!showConfirmation && !showReset &&
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="email" placeholder="Enter email" value={email} onChange={(e) => setEmail(e.target.value)} />
            </Form.Group>
            <Button variant="primary" onClick={handlePasswordReset}>Reset Password</Button>
          </Form>
        }
        {showConfirmation && !showReset &&
          <Form>
            <div style={{marginBottom: '10px'}}>
              We've sent the confirmation code to:<br /><code>{email}</code>
            </div>
            <Form.Group className="mb-3">
              <Form.Label>Confirmation Code</Form.Label>
              <Form.Control type="text" placeholder="Enter confirmation code" value={confirmationCode} onChange={(e) => setConfirmationCode(e.target.value)} />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>New Password</Form.Label>
              <Form.Control type="password" placeholder="New password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Confirm New Password</Form.Label>
              <Form.Control type="password" placeholder="Confirm new password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
            </Form.Group>
            {showSuccess && <Alert variant='success'>Password reset successful!</Alert>}
            <Button variant="primary" onClick={handleNewPassword}>Submit New Password</Button>
          </Form>
        }
      </Modal.Body>
    </Modal>
  );
};

export default PasswordReset;
