import { Spinner } from "react-bootstrap";
import { primaryWeaponsSvg, secondaryWeaponsSvg, utilityItemsSvg } from "../utils/weapons";
import { InventoryItem, PlayerFrameData, Side } from "../utils/types";

function compareInventory(a: InventoryItem, b: InventoryItem) {
  if (a.weaponName < b.weaponName) {
    return -1;
  }
  if (a.weaponName > b.weaponName) {
    return 1;
  }
  return 0;
}

const Player = ({ data, side, minimal, hovered, csVersion }: {
  data: PlayerFrameData | undefined,
  side: Side,
  minimal: boolean,
  hovered: boolean,
  csVersion: string | undefined,
}) => {
  if (data !== undefined) {
    let playerInfoDead = data.isAlive ? "" : "player-info-dead";
    let healthbarDead = data.isAlive ? "" : "healthbar-dead";
    let hoverClass = hovered ? (data.isAlive ? `player-info-hover-${side}` : `player-info-hover-dead`) : "";
    let primaryWeapon = data.inventory ? data.inventory.find(item => item.weaponName in primaryWeaponsSvg)?.weaponName : undefined;
    let secondary = data.inventory ? data.inventory.find(item => item.weaponName in secondaryWeaponsSvg)?.weaponName : undefined;
    let weapon = primaryWeapon !== undefined ? primaryWeapon : secondary !== undefined ? secondary : undefined;
    let weaponSvg = weapon !== undefined ? (weapon in primaryWeaponsSvg ? primaryWeaponsSvg[weapon] : secondaryWeaponsSvg[weapon]) : undefined;
    let utilities = data.inventory ? data.inventory.sort(compareInventory).filter(item => item.weaponName in utilityItemsSvg) : [];

    if (csVersion === "cs2") {
      utilities = utilities.filter(item => item.weaponName !== "C4");
    }

    if (minimal) {
      return (
        <div className={`info player-info player-info-${side} ${playerInfoDead} ${hoverClass} rounded shadow`}>
          <div className={'d-flex'}>
            <div className={'player-name flex-grow-1'}>{data.name}</div>
            <div className={'player-weapon'}>{weapon}</div>
          </div>
          <div className={`healthbar healthbar-${side} ${healthbarDead}`}>
            <div className={`healthbar healthbar-bg-${side}`} style={{ width: `${data.hp}%` }}></div>
          </div>
        </div>
      )
    }

    return (
      <div className={`info player-info player-info-${side} ${playerInfoDead} ${hoverClass} rounded shadow`}>
        <div className="d-flex">
          <div className={'player-name flex-grow-1'}>{data.name}</div>
          <div className={data.isAlive ? `player-cash-${side}` : 'player-cash-dead'}>${data.cash}</div>
        </div>
        <div className={`healthbar healthbar-${side} ${healthbarDead}`}>
          <div className={`healthbar healthbar-bg-${side}`} style={{ width: `${data.hp}%` }}></div>
        </div>
        <div className={`armorbar healthbar-${side} ${healthbarDead}`}>
          <div className={`armorbar healthbar-bg-${side}`} style={{ width: `${data.armor}%` }}></div>
        </div>
        <div className="d-flex">
          {data.isAlive && data.armor === 0 && !data.hasHelmet && <i className={`bi bi-shield armor-icon`}></i>}
          {data.isAlive && data.armor > 0 && !data.hasHelmet && <i className={`bi bi-shield-shaded armor-icon`}></i>}
          {data.isAlive && data.armor > 0 && data.hasHelmet && <i className={`bi bi-shield-fill armor-icon`}></i>}
          <div className={"player-primary-weapon flex-grow-1"}>{weaponSvg && data.isAlive ?
            <img src={`${process.env.PUBLIC_URL}/items/${weaponSvg}`} alt={weapon} className={`weapon-icon weapon-icon-${side}`} /> :
            <div className={`weapon-icon weapon-icon-${side}`}></div>
          }
          </div>
          <div className={"flex-grow-1"}>
          </div>
          <div>
            {data.hasBomb && data.isAlive ? <img src={`${process.env.PUBLIC_URL}/items/weapon_c4.svg`} alt={weapon} className={`weapon-icon weapon-icon-${side}`} /> : null}
            {data.hasDefuse ? <img src={`${process.env.PUBLIC_URL}/items/item_defuser.svg`} alt={weapon} className={`weapon-icon weapon-icon-${side}`} /> : null}
            {utilities.map((item, index) => {
              // Bug in early awpy where ammo is doubled
              const totalAmmo = csVersion === "cs2" ? (item.ammoInMagazine) : (item.ammoInMagazine + item.ammoInReserve);

              const images = [];
              for (let i = 0; i < totalAmmo; i++) {
                images.push(
                  <img
                    key={`${item.weaponName}-${i}`}
                    src={`${process.env.PUBLIC_URL}/items/${utilityItemsSvg[item.weaponName]}`}
                    alt={item.weaponName}
                    className={`weapon-icon weapon-icon-${side}`}
                  />
                );
              }
              return data.isAlive ? images : [];
            })}
          </div>
        </div>
      </div>
    )
  }
  return minimal ?
    <div className={`info player-info mx-3 player-info-loading rounded shadow`}>
      <div className={'mx-auto my-auto'}>
        <Spinner className={"spinner-border-sm"} animation="border" style={{ color: "#444" }} />
      </div>
    </div> :
    <div className={`info player-info player-info-dead player-info-loading m-3 py-3 px-4 rounded shadow`}>
      <div className={'mx-auto my-auto'}>
        <Spinner animation="border" style={{ color: "#444" }} />
      </div>
    </div>
}

export default Player;