import { RoundData } from "./types";

export const primaryWeaponsSvg: { [key: string]: string } = {
  // Shotguns
  'MAG-7': 'weapon_mag7.svg',
  'XM1014': 'weapon_xm1014.svg',
  'Nova': 'weapon_nova.svg',
  'Sawed-Off': 'weapon_sawedoff.svg',

  // SMGs
  'MP9': 'weapon_mp9.svg',
  'MP5-SD': 'weapon_mp5sd.svg',
  'UMP-45': 'weapon_ump45.svg',
  'PP-Bizon': 'weapon_bizon.svg',
  'MAC-10': 'weapon_mac10.svg',
  'MP7': 'weapon_mp7.svg',
  'P90': 'weapon_p90.svg',

  // Rifles
  'Galil AR': 'weapon_galilar.svg',
  'FAMAS': 'weapon_famas.svg',
  'AK-47': 'weapon_ak47.svg',
  'M4A1': 'weapon_m4a1_silencer.svg',
  'M4A4': 'weapon_m4a1.svg',
  'AUG': 'weapon_aug.svg',
  'SG 553': 'weapon_sg556.svg',
  'SCAR-20': 'weapon_scar20.svg',
  'G3SG1': 'weapon_g3sg1.svg',
  'M249': 'weapon_m249.svg',
  'Negev': 'weapon_negev.svg',
  'AWP': 'weapon_awp.svg',
  'SSG 08': 'weapon_ssg08.svg'
}

export const secondaryWeaponsSvg: { [key: string]: string } = {
  // Pistols
  'Glock-18': 'weapon_glock.svg',
  'P2000': 'weapon_hkp2000.svg',
  'USP-S': 'weapon_usp_silencer.svg',
  'P250': 'weapon_p250.svg',
  'CZ75 Auto': 'weapon_cz75a.svg',
  'Dual Berettas': 'weapon_elite.svg',
  'Five-SeveN': 'weapon_fiveseven.svg',
  'Tec-9': 'weapon_tec9.svg',
  'Desert Eagle': 'weapon_deagle.svg',
}

export const utilityItemsSvg: { [key: string]: string } = {
  // Grenades
  'Incendiary Grenade': 'weapon_incgrenade.svg',
  'Molotov': 'weapon_molotov.svg',
  'Flashbang': 'weapon_flashbang.svg',
  'Smoke Grenade': 'weapon_smokegrenade.svg',
  'HE Grenade': 'weapon_hegrenade.svg',
  'Decoy Grenade': 'weapon_decoy.svg',
  'C4': 'weapon_c4.svg',
}

export const allItemsSvg: { [key: string]: string } = {
  ...primaryWeaponsSvg,
  ...secondaryWeaponsSvg,
  ...utilityItemsSvg
};

// Hacky fix for the early CS2 support
export function fixWeaponInventoryCs2(data: RoundData) {
  // Iterate over all frames
  // Gather all the active weapons during the round for a player
  // Remove any weapons from the inventory which were not active during the round

  // Add the active weapon to the inventory if it is not there for each frame
  // If inventory doesn't have any weapons, add the gathered active weapon

  const firstFrame = data.frames[0]
  const players = firstFrame.t.players.concat(firstFrame.ct.players)
  for (let player of players) {
    const activeWeapons = new Set<string>();
    for (let frame of data.frames) {
      const playerFrame = frame.t.players.find((p) => p.steamID === player.steamID) || frame.ct.players.find((p) => p.steamID === player.steamID);
      if (playerFrame) {
        activeWeapons.add(playerFrame.activeWeapon);
      }
    }

    for (let frame of data.frames) {
      const playerFrame = frame.t.players.find((p) => p.steamID === player.steamID) || frame.ct.players.find((p) => p.steamID === player.steamID);
      if (playerFrame && playerFrame.inventory) {
        playerFrame.inventory = playerFrame.inventory.filter((item) => activeWeapons.has(item.weaponName));
      }

      // See if the player has any weapons in the inventory (weaponClass is either "Pistols", "Rifle", "SMG", "Shotgun")
      const hasWeapons = playerFrame && playerFrame.inventory && playerFrame.inventory.filter((item) => item.weaponClass === "Pistols" || item.weaponClass === "Rifle" || item.weaponClass === "SMG" || item.weaponClass === "Shotgun").length > 0;
      // If the player doesn't have any weapons in the inventory, add the active weapons set
      if (playerFrame && !hasWeapons) {
        playerFrame.inventory = Array.from(activeWeapons).map((weapon) => {
          return {
            weaponName: weapon,
            weaponClass: "",
            ammoInMagazine: 1,
            ammoInReserve: 1,
          }
        });
      }

      if (playerFrame && playerFrame.activeWeapon && playerFrame.inventory) {
        // If the active weapon is not in the inventory, add it
        if (!playerFrame.inventory.find((item) => item.weaponName === playerFrame.activeWeapon)) {
          playerFrame.inventory.push({
            weaponName: playerFrame.activeWeapon,
            weaponClass: "",
            ammoInMagazine: 1,
            ammoInReserve: 1,
          });
        }
      }
    }
  }
}

export function fixHasBombCs2(data: RoundData) {
  for(let frame of data.frames) {
    if(frame.bombPlanted) {
      for(let playerFrame of frame.t.players) {
        if (playerFrame.inventory) {
          playerFrame.inventory = playerFrame.inventory.filter((item) => item.weaponName !== "C4");
          playerFrame.hasBomb = false;
        }
      }
    }
  }
}

export function fixUtilityInventoryCs2(data: RoundData) {

}