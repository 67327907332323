import Icon from "./Icon";

const MapIcon = ({name, size, margin} : {
    name: string,
    size: number,
    margin?: number,
  }) => {
  return (
    <Icon 
        prefix="maps/"
        name={`map_icon_de_${name}`}
        postfix=".svg"
        size={size}
        margin={margin ?? 10}
        shadowStrength={0.7}
        shadowDistance={1}
    />
  );
}

export default MapIcon;