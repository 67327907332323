import React, { useState, useEffect } from 'react';
import { ProgressBar, Spinner } from "react-bootstrap";
import { useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import { fetchFromApi } from '../utils/api';
import { Playlist, RoundsMetadata } from '../utils/types';
import { TeamsData } from './Matches';
import { fetchTeams } from '../utils/team_cache';
import styles from './ExternalPlaylistLoader.module.css';
import { cleanEmptyMatches, sortPlaylist } from '../utils/playlist';


const ExternalPlaylistLoader = () => {
  const [data, setData] = useState<undefined | "na" | Playlist>(undefined);
  const [teams, setTeams] = useState<TeamsData>(JSON.parse(sessionStorage.getItem('teamscache') || '{}'));
  const [fetchedItems, setFetchedItems] = useState<Set<string>>(new Set());
  const [progress, setProgress] = useState<number>(0);
  const [missingRounds, setMissingRounds] = useState<undefined | number>(undefined);
  const [missingMatches, setMissingMatches] = useState<undefined | number>(undefined);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const itemsParameter = searchParams.get('items');

  const navigate = useNavigate();

  useEffect(() => {
    if (!sessionStorage.getItem('teamscache')) {
      fetchTeams().then((data) => { setTeams(data); })
    }
  }, []);

  useEffect(() => {
    if (itemsParameter === null) {
      console.error(`Error: No items parameter provided in ${location.search} ... ${new URLSearchParams(location.search)}`);
      setData('na');
      return;
    }
    if (Object.keys(teams).length === 0) {
      return;
    }

    const items = itemsParameter.split(',');

    // create a set of site/id pairs to find uniques
    const siteIdPairs = new Set<string>();
    items.forEach((item) => {
      const [site, id, mapname, roundnum] = item.split('.');
      siteIdPairs.add(`${site}.${id}`);
    });

    const fetchPromises: Promise<Response>[] = [];
    // For each site id pair, fetch the match data
    siteIdPairs.forEach((siteIdPair) => {
      const [site, id] = siteIdPair.split('.');
      const matches_url = `/matches?${site}_id=${id}`;
      fetchPromises.push(fetchFromApi(matches_url));
    });

    Promise.all(fetchPromises)
      .then((responses) => {
        return Promise.all(responses.map(res => res.json()));
      })
      .then((responseObjects: any[]) => {
        const matchIdDict: { [siteIdPair: string]: string } = {};
        responseObjects.forEach((responseObject, i) => {
          if (Object.keys(responseObject).length !== 0) {
            const match_id = Object.keys(responseObject)[0];
            const siteIdPair = Array.from(siteIdPairs)[i];
            matchIdDict[siteIdPair] = match_id;
          } else {
            console.warn("Warning: Match not found for: " + Array.from(siteIdPairs)[i]);
          }
        });
        const playlistRounds: RoundsMetadata = {};
        const fetchRoundPromises = items.map((item) => {
          const [site, id, mapname, roundnum] = item.split('.');
          const match_id = matchIdDict[`${site}.${id}`];
          if (match_id) {
            if (!playlistRounds[match_id]) {
              playlistRounds[match_id] = [];
            }
            const url = '/rounds?match_id=' + match_id + '&map=' + mapname + '&roundnum=' + roundnum + '&sides=BOTH&team_key=' + match_id.split(".")[1];
            return fetchFromApi(url)
              .then((response) => response.json())
              .then((rounds: RoundsMetadata) => {
                setFetchedItems(prevState => new Set(prevState).add(match_id + '.' + roundnum));
                if (match_id in rounds) {
                  playlistRounds[match_id].push(rounds[match_id][0]);
                } else {
                  setMissingRounds(prevState => prevState ? prevState + 1 : 1);
                  console.warn("Warning: Round not found for: " + `${site}.${id}.${mapname}.${roundnum}`);
                }
              });
          } else {
            setMissingMatches(prevState => prevState ? prevState + 1 : 1);
            console.warn("Warning: Match not found for: " + `${site}.${id}`);
            return Promise.resolve();
          }
        });

        return Promise.all(fetchRoundPromises)
          .then(() => {
            const finalPlaylist: Playlist = {
              rounds: playlistRounds,
              team_key: "", // There is no consistent team across the playlist
              side: "", // There is no consistent side across the playlist
              external: true,
            }
            Object.keys(finalPlaylist.rounds).forEach((matchId) => {
              finalPlaylist.rounds[matchId].forEach((round) => {
                if (teams[round.t_team_key]) {
                  round.t_team_flag = teams[round.t_team_key].flag;
                  round.t_team = teams[round.t_team_key].name;
                }
                if (teams[round.ct_team_key]) {
                  round.ct_team_flag = teams[round.ct_team_key].flag;
                  round.ct_team = teams[round.ct_team_key].name;
                }
              });
            });
            setData(finalPlaylist);
          });
      })
      .catch((err) => {
        console.error(err.message);
      });
  }, [itemsParameter, teams]);

  useEffect(() => {
    if (!itemsParameter || !fetchedItems) {
      return;
    }
    const items = itemsParameter.split(',');

    // For each site id pair, fetch the match data
    const siteIdPairs = new Set<string>();
    items.forEach((item) => {
      const [site, id, mapname, roundnum] = item.split('.');
      siteIdPairs.add(`${site}.${id}`);
    });

    // Log the progress as how many matches have been fetched
    setProgress(Math.round(fetchedItems.size * 100 / items.length));
  }, [fetchedItems, itemsParameter]);

  useEffect(() => {
    if (!data || data === 'na' || !data.rounds || missingRounds || missingMatches) {
      return;
    }

    const cleanedPlaylist = cleanEmptyMatches(data);
    const sortedPlaylist = sortPlaylist(cleanedPlaylist);
    sessionStorage.setItem('playlist', JSON.stringify(sortedPlaylist));

    const firstRound = Object.values(sortedPlaylist.rounds)[0][0];
    if (firstRound) {
      navigate(`/match/${firstRound.match_id}/${firstRound.mapname}/${firstRound.roundnum}`);
    }
  }, [data, navigate, missingRounds, missingMatches]);

  return <div className="d-flex flex-column min-vh-100 justify-content-center align-items-center">
    {data === 'na' ?
      <div className={styles.loadingLabel} style={{ color: '#666' }}>Failed to load playlist.</div> :
      <>
        {!missingRounds && !missingMatches &&
          <span>
          <div className={styles.loadingLabel}><Spinner size="sm" /><span className={styles.spinnerLabel}> Processing playlist...</span></div>
        </span>
        
    }
    <span>
      <ProgressBar
        className={"externalPlaylistLoaderProgressBar"}
        animated={true}
        striped={true}
        now={progress}
        label={`${progress}%`}
        style={{ marginTop: '15px', marginBottom: '10px', width: '300px' }}
      />
    </span>
    {missingRounds &&
      <span>
        <div className={styles.loadingLabel} style={{ color: '#c22' }}>Warning: {missingRounds} round(s) not found.</div>
      </span>}
    {missingMatches &&
      <span>
        <div className={styles.loadingLabel} style={{ color: '#c22' }}>Warning: {missingMatches} matches(s) not found.</div>
      </span>}
    {(missingRounds || missingMatches) &&
      <span>
        <button className="btn btn-outline-secondary" style={{ marginTop: 10 }} onClick={() => {setMissingRounds(undefined); setMissingMatches(undefined);}}>Continue</button>
      </span>
    }
  </>
}
  </div >
};

export default ExternalPlaylistLoader;