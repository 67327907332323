import { useState } from "react";
import { Button, Dropdown, DropdownButton, Modal, OverlayTrigger, Spinner, ToggleButton, ToggleButtonGroup, Tooltip } from "react-bootstrap";
import { RoundMetadata, Playlist } from "../utils/types";
import useWindowSize from "../useWindowSize";
import { getFlatPlaylist, getNextOrPrevRound, getPlaylistLength, getRoundIndex } from "../utils/playlist";
import TeamIcon from "./TeamIcon";
import styles from './FooterButton.module.css';
import RoundPickerToolbar from "./RoundPickerToolbar";


const getRoundEndIcon = (roundMetadata: RoundMetadata, active: boolean) => {
  if ((localStorage.getItem('roundPickerStyle-v2') || 'NUMBERS') === 'ICONS') {
    const winner_postfix = roundMetadata.winning_side === "T" ? "_t.svg" : "_ct.svg";

    let svgPath = "/svg/skull" + winner_postfix;
    if (roundMetadata.round_end_reason === "BombDefused") {
      svgPath = "/svg/defused" + winner_postfix;
    } else if (roundMetadata.round_end_reason === "TargetBombed") {
      svgPath = "/svg/exploded" + winner_postfix;
    } else if (roundMetadata.round_end_reason === "TargetSaved") {
      svgPath = "/svg/stopwatch" + winner_postfix;
    }

    return <img src={svgPath} style={{ width: 14, height: 14, opacity: active ? 1.0 : 0.5 }} alt="" />;
  } else {
    return <span className={roundMetadata.winning_side === "T" ? "round-win-t" : "round-win-ct"}>{zeroPad(Number(roundMetadata.roundnum), 2)}</span>;
  }

}

const roundTitle = (round: RoundMetadata, playlist: Playlist, team_key: string, emphasis: boolean, listitem: boolean, wideMode: boolean) =>
  <span style={emphasis ? { color: '#fff' } : (listitem ? { color: '#555' } : {})}>
    <TeamIcon
      name={round.ct_team_key === team_key ? round.ct_team_key : round.t_team_key}
      flag={round.ct_team_key === team_key ? round.ct_team_flag : round.t_team_flag}
      size={13}
      flagMargin={10}
      iconMargin={10} />
    {wideMode &&
      <span>{round.ct_team_key === team_key ? round.ct_team : round.t_team}</span>
    }
    <span className="mx-2" style={{ color: emphasis ? '#888' : '#383838' }}>vs</span>
    <TeamIcon
      name={round.ct_team_key !== team_key ? round.ct_team_key : round.t_team_key}
      flag={round.ct_team_key !== team_key ? round.ct_team_flag : round.t_team_flag}
      size={13}
      flagMargin={10}
      iconMargin={10} />
    {wideMode &&
      <span>{round.ct_team_key !== team_key ? round.ct_team : round.t_team}</span>
    }
    {wideMode && <>
      <span className="mx-2" style={{color: emphasis ? '#888' : '#383838'}}>|</span>
      <span>{round.mapname}</span>
      </>
    }
    <span className="mx-2" style={{color: emphasis ? '#888' : '#383838'}}>|</span>
    <span>{zeroPad(Number(round.roundnum), 2)}</span>
  </span>;

const zeroPad = (num: number, places: number) => String(num).padStart(places, '0')

const RoundPickerButtons = ({ playlist, selectedRound, wideMode, setSelectedRound }: {
  playlist: Playlist,
  selectedRound: RoundMetadata | undefined,
  wideMode: boolean,
  setSelectedRound: (round: RoundMetadata) => void
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { width, height } = useWindowSize();
  const roundCount = Object.keys(playlist.rounds).reduce((acc, match_id) => acc + playlist.rounds[match_id].length, 0);

  const handleKeyDown = (event: { preventDefault: () => void }) => {
    event.preventDefault();
  };

  function toggleNextOrPreviousRound(direction: number) {
    if (direction === 1) {
      if (selectedRound) {
        const newRound = getNextOrPrevRound(playlist.rounds, selectedRound, 1);
        if (newRound !== selectedRound && newRound !== undefined) {
          setSelectedRound(newRound);
        }
      }
    }
    else if (direction === -1) {
      if (selectedRound) {
        const newRound = getNextOrPrevRound(playlist.rounds, selectedRound, -1);
        if (newRound !== selectedRound && newRound !== undefined) {
          setSelectedRound(newRound);
        }
      }
    } else {
      console.error('Invalid direction');
    }
  }

  function hasNextOrPreviousRound(direction: number) {
    if (direction === 1) {
      if (selectedRound) {
        const newRound = getNextOrPrevRound(playlist.rounds, selectedRound, 1);
        if (newRound !== selectedRound && newRound !== undefined) {
          return true;
        }
      }
    }
    else if (direction === -1) {
      if (selectedRound) {
        const newRound = getNextOrPrevRound(playlist.rounds, selectedRound, -1);
        if (newRound !== selectedRound && newRound !== undefined) {
          return true;
        }
      }
    }
    return false;
  }

  const renderDropdownItems = () => {
    let prevMatchId = "";
    let prevTeamKey = "";
    return <> 
      {playlist.name && <Dropdown.Header><span style={{color: '#666', marginRight: '7px'}}>Playlist:</span> {playlist.name}</Dropdown.Header>}
      {playlist.name && <Dropdown.Divider />}
      {getFlatPlaylist(playlist.rounds).map((roundObj, round_index) => {
      const sameMatch = prevMatchId === roundObj.match_id; // update this line
      const team_key = sameMatch ? prevTeamKey : roundObj.t_team_key;
      prevMatchId = roundObj.match_id;
      prevTeamKey = team_key;
      return <Dropdown.Item
        key={roundObj.match_id + "." + roundObj.roundnum}
        onClick={() => setSelectedRound(roundObj)}
        as="span"
        style={{cursor: "pointer"}}
      >
        {roundTitle(roundObj, playlist, team_key, roundObj === selectedRound, true, wideMode)}
      </Dropdown.Item>
    })}
    </>
  };

  if (roundCount > 0 && selectedRound) {
    return (
      <>
        <Button
          size="sm"
          variant="outline-secondary"
          className={hasNextOrPreviousRound(-1) ? styles.dimmedButton : styles.disabledButton}
          onKeyDown={handleKeyDown}
          onClick={() => toggleNextOrPreviousRound(-1)}
          disabled={!hasNextOrPreviousRound(-1)}
        >
          <i className="bi bi-caret-left" style={{position: 'relative', top: '1px'}}></i>
        </Button>
        <DropdownButton
          onToggle={(isOpen) => setIsDropdownOpen(isOpen)}
          disabled={!wideMode}
          className={`dropdown-round-picker-button`}
          style={{ padding: 0, margin: 0}}
          variant="outline-secondary"
          id="dropdown-round-picker"
          title={zeroPad(selectedRound.roundnum, 2)}
          size="sm"
        >
          {isDropdownOpen && renderDropdownItems()}
        </DropdownButton>
        <Button
          size="sm"
          variant="outline-secondary"
          className={hasNextOrPreviousRound(1) ? styles.dimmedButton : styles.disabledButton}
          onKeyDown={handleKeyDown}
          onClick={() => toggleNextOrPreviousRound(1)}
          disabled={!hasNextOrPreviousRound(1)}
        >
          <i className="bi bi-caret-right" style={{position: 'relative', top: '1px'}}></i>
        </Button>
      </>
    );
  } else {
    return <Spinner className={"spinner-border-sm"} animation="border" style={{ color: "#888" }} />
  }

};

export default RoundPickerButtons;