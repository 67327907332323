import styles from "./UpgradeInfoModal.module.css";
import CustomModalHeader from "./CustomModalHeader";
import UpgradeInfo from "./UpgradeInfo";
import { Modal } from "react-bootstrap";


export default function UpgradeInfoModal({show, handleCloseModal} : {show: boolean, handleCloseModal : () => void}): JSX.Element {

  return (
    <Modal show={show} onHide={handleCloseModal} dialogClassName={styles.dialog}>
    <CustomModalHeader />
    <div style={{margin: '25px'}}>
    <UpgradeInfo />
    </div>
  </Modal>
  )
}