import React, { useEffect } from 'react';

const Nopage = () => {
  useEffect(() => {
    console.log('No page found.');
  }, []);
  return (
    <>
      <div className='d-flex justify-content-center no-page-found'>
        <p>No page found!</p>
      </div>
    </>
  );
};

export default Nopage;