import React, { useEffect, useRef, useState } from 'react';

interface PaintingsProps {
  activeColor: number[];
  paintUndoCount: number;
  paintClearCount: number;
}

export const Paintings = ({ activeColor, paintUndoCount, paintClearCount }: PaintingsProps) => {
  type Stroke = {
    points: number[][],
    color: number[],
  };

  const [activePosition, setActivePosition] = useState([0, 0]);
  const [strokes, setStrokes] = useState<Stroke[]>([]);
  const [isPainting, setIsPainting] = useState(false);
  const svgRef = useRef<SVGSVGElement | null>(null);

  useEffect(() => {
    // remove last stroke
    if (strokes.length > 0) {
      setStrokes(strokes.slice(0, -1));
    }
  }, [paintUndoCount]);

  useEffect(() => {
    // remove last stroke
    if (strokes.length > 0) {
      setStrokes([]);
    }
  }, [paintClearCount]);

  const handleMouseDown = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    setIsPainting(true);
    const newPosition = getTransformedPositionFromMouseEvent(event);
    if (newPosition) {
      setActivePosition(newPosition);
      setStrokes([...strokes, { points: [newPosition], color: activeColor }]);
    }
  };

  const handleMouseUp = () => {
    handlePaintingEnd();
  };

  const handleMouseMove = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    if (isPainting) {
      const newPosition = getTransformedPositionFromMouseEvent(event);
      if (newPosition) {
        setActivePosition(newPosition);
        const lastStroke = strokes[strokes.length - 1];
        setStrokes([...strokes.slice(0, -1), { ...lastStroke, points: [...lastStroke.points, newPosition] }]);
      }
    }
  };
  
  const handleTouchStart = (event: React.TouchEvent<SVGSVGElement>) => {
    setIsPainting(true);
    const newPosition = getTransformedPositionFromTouchEvent(event);
    if (newPosition) {
      setActivePosition(newPosition);
      setStrokes([...strokes, { points: [newPosition], color: activeColor }]);
    }
  };
  
  const handleTouchMove = (event: React.TouchEvent<SVGSVGElement>) => {
    if (isPainting) {
      event.preventDefault();
      const newPosition = getTransformedPositionFromTouchEvent(event);
      if (newPosition) {
        setActivePosition(newPosition);
        const lastStroke = strokes[strokes.length - 1];
        setStrokes([...strokes.slice(0, -1), { ...lastStroke, points: [...lastStroke.points, newPosition] }]);
      }
    }
  };
  

  const handleTouchEnd = () => {
    handlePaintingEnd();
  };

  const handlePaintingEnd = () => {
    const lastStroke = strokes[strokes.length - 1];
    if (lastStroke.points.length > 1) {
      const smoothedLastStroke = smoothStroke(lastStroke.points, 0.08);
      setStrokes([...strokes.slice(0, -1), { ...lastStroke, points: smoothedLastStroke }]);
    }
    setIsPainting(false);
  }

  const getTransformedPositionFromMouseEvent = (
    event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ) => {
    return getTransformedPosition(event.clientX, event.clientY);
  };

  const getTransformedPositionFromTouchEvent = (
    event: React.TouchEvent<SVGSVGElement>
  ) => {
    if (event.touches.length === 0) return;
    const touch = event.touches[0];
    return getTransformedPosition(touch.clientX, touch.clientY);
  };

  const getTransformedPosition = (clientX: number, clientY: number) => {
    const svg = svgRef.current;
    if (!svg) return;
    const point = svg.createSVGPoint();
    if (!point) return;
    point.x = clientX;
    point.y = clientY;
    const screenCTM = svg.getScreenCTM();
    if (!screenCTM) return;
    const matrix = screenCTM.inverse();
    const transformedPoint = point.matrixTransform(matrix);
    return [transformedPoint.x, transformedPoint.y];
  };

  function smoothStroke(points: number[][], strength: number): number[][] {
    if (strength < 0) strength = 0;
    if (strength > 1) strength = 1;
  
    const smoothedPoints: number[][] = [];
    const windowSize = Math.floor(strength * (points.length - 1)) + 1;
  
    for (let i = 0; i < points.length; i++) {
      let sumX = 0;
      let sumY = 0;
      let count = 0;
  
      for (let j = Math.max(0, i - windowSize); j <= Math.min(points.length - 1, i + windowSize); j++) {
        sumX += points[j][0];
        sumY += points[j][1];
        count++;
      }
  
      const averageX = sumX / count;
      const averageY = sumY / count;
      smoothedPoints.push([averageX, averageY]);
    }
  
    return smoothedPoints;
  }
  
  return (
    <svg
      className={`map-view-svg map-view-paintings`}
      width="100%"
      height="100%"
      viewBox="0 0 100 100"
      ref={svgRef}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onTouchMove={handleTouchMove}
    >
      {strokes.map((stroke, index) => (
        <path
          key={index}
          d={`M${stroke.points.map((point) => `${point[0]},${point[1]}`).join(' L')}`}
          stroke={`rgba(${stroke.color[0]}, ${stroke.color[1]}, ${stroke.color[2]}, 1)`}
          strokeWidth="0.5"
          strokeLinecap="round"
          fill="none"
        />
      ))}
    </svg>
  );
};
