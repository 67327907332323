import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import styles from './FooterButton.module.css';

type FilterButtonProps = {
  onClick: () => void;
};

export default function FilterButton(props: FilterButtonProps): JSX.Element {
  const { onClick } = props;

  const navigate = useNavigate();

  const handleKeyDown = (event: { preventDefault: () => void }) => {
    event.preventDefault();
  };

  return (
    <>
      <OverlayTrigger
        placement="top"
        delay={{ show: 300, hide: 0 }}
        overlay={
          <Tooltip id={`tooltip-round-filter`}>
            Round filter
          </Tooltip>
        }
      >
        <Button
          variant={'outline-secondary'}
          size="sm"
          className={styles.button}
          onClick={onClick}
          onKeyDown={handleKeyDown}
        >
          <i className="bi bi-search"></i>
        </Button>
      </OverlayTrigger>

    </>
  );
}
