import React, { useState, useEffect } from "react";
import { calculateTimeLeft } from "../utils/premium";
import styles from "./MatchUnlockTimer.module.css";

const MatchUnlockTimer = ({ matchId } : {matchId: string}) : JSX.Element => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(matchId));

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft(matchId));
    }, 1000);
    return () => clearInterval(timer);
  }, []);
  
  return (
    <div className={styles.timer}>
      <p className={styles.message}>
        This round will be free in:
      </p>
      <p className={styles.time}>
        {timeLeft.days}<span className={styles.time_unit}>d</span>
        {timeLeft.hours}<span className={styles.time_unit}>h</span>
        {timeLeft.minutes}<span className={styles.time_unit}>min</span>
        {timeLeft.seconds}<span className={styles.time_unit}>s</span>
      </p>
    </div>
  );
};

export default MatchUnlockTimer;

