import React, { useState, useEffect } from 'react';
import { Spinner } from "react-bootstrap";
import { useParams } from 'react-router-dom'
import { HLTVMatchData, Playlist } from "../utils/types";
import { useNavigate } from 'react-router-dom';
import { fetchRoundsMetadata } from '../utils/rounds';
import Replayer from '../components/Replayer';
import { fetchFromApi } from '../utils/api';
import { post_usage } from '../utils/usage';


const ExternalMatchLoader = ({site} : {
  site: string
  }) => {
  
  const [matchKey, setMatchKey] = useState<string | undefined>(undefined);
  const [playlist, setPlaylist] = React.useState<Playlist | undefined>(undefined);
  const { id, mapName, roundName } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    const matches_url = `/matches?${site}_id=${id}`;
    fetchFromApi(matches_url)
      .then(response => response.json()) 
      .then((responseObject: any) => {
        if(Object.keys(responseObject).length !== 0) {
          post_usage(`external_match_${site}`);
          setMatchKey(Object.keys(responseObject)[0]);
        } else {
          console.error(`No match found for ${site} ${id}`);
          setPlaylist({rounds: {}, match_id: "", external: true});
        }
      })
      .catch((err) => {
        console.error(err.message);
      });
  }, [site, id]);

  useEffect(() => {
    if(!playlist && id && matchKey && mapName && roundName) {
      console.log(`Fetching rounds metadata for ${matchKey} ${mapName} ${roundName}...`);
      fetchRoundsMetadata(matchKey, mapName, parseInt(roundName))
        .then((roundsMetadata) => {
          if(roundsMetadata) {
            const fetchedPlaylist: Playlist = {
              rounds: roundsMetadata,
              match_id: matchKey,
              external: true,
            };
            setPlaylist(fetchedPlaylist);
          }
        }
      )
    }
  }, [id, matchKey, mapName, roundName, playlist]);

  useEffect(() => {
    sessionStorage.setItem('playlist', JSON.stringify(playlist));
    if(matchKey !== undefined && playlist) {
      if(mapName !== undefined && roundName !== undefined) {
        navigate(`/match/${matchKey}`);
      }
    }
  }, [mapName, matchKey, navigate, playlist, roundName]);

  if(playlist) {
    if(Object.keys(playlist.rounds).length === 0) {
      return <div className="d-flex flex-column min-vh-100 justify-content-center align-items-center">
        <span className="loading-label">Match not found 😞</span>
      </div>;
    }
    return <Replayer 
      playlist={playlist}
    />;
  } else {
    return <div className="d-flex flex-column min-vh-100 justify-content-center align-items-center">
      <span><Spinner animation="border" /><span className="loading-label">Loading...</span></span>
    </div>;
  }
};

export default ExternalMatchLoader;