import { Projectile } from "../utils/projectile";
import { MatchRound } from "./Analyse";
import AnalyseMapViewSVG from "./AnalyseMapViewSVG";
import { FireInfo, SmokeInfo } from "./MapView";
import { DamageData, GrenadeData, KillData, PlayerFrameData, WeaponFireData } from "../utils/types";

interface AnalyseMapViewLayerProps {
  playerFrames: PlayerFrameData[],
  upperView: boolean,
  mapName: string,
  mapOffset: number[],
  mapZoom: number,
  currentTick: number | undefined,
  projectiles: Projectile[],
  explosions: GrenadeData[],
  smokes: SmokeInfo[],
  fires: FireInfo[],
  weaponFires: WeaponFireData[],
  bullets: DamageData[],
  damages: DamageData[],
  kills: KillData[],
  deaths: KillData[],
  bomb: {x: number, y: number, z: number} | null,
  bombPlanted: boolean,
  bombTimeLeft: number,
  bombDefusing: boolean,
  activePlayerSteamId: number | undefined,
  hoveredPlayerSteamId: number | undefined,
  activeRound: MatchRound | undefined,
  hoveredRound: MatchRound | undefined,
  csVersion: string | undefined,
  playerColors: Map<number, string>,
  onPlayerBeginHover: (matchID: string, mapName: string, roundNum: number, steamID: number) => void,
  onPlayerEndHover: (matchID: string, mapName: string, roundNum: number, steamID: number) => void,
  onPlayerClick: (matchID: string, mapName: string, roundNum: number, steamID: number) => void,
  rect: number[] | undefined,
  rectType: 'all' | 'landing' | undefined,
  rectChanged: (
    recordingRect: number[] | undefined,
    rectType: 'all' | 'landing' | undefined,
    dragStart: boolean,
    dragEnd: boolean,
  ) => void,
}

const AnalyseMapViewLayer = ({
  playerFrames,
  upperView,
  mapName,
  mapOffset,
  mapZoom,
  currentTick,
  projectiles,
  explosions,
  smokes,
  fires,
  weaponFires,
  bullets,
  damages,
  kills,
  deaths,
  bomb,
  bombPlanted,
  bombTimeLeft,
  bombDefusing,
  activePlayerSteamId,
  hoveredPlayerSteamId,
  activeRound,
  hoveredRound,
  playerColors,
  csVersion,
  onPlayerBeginHover,
  onPlayerEndHover,
  onPlayerClick,
  rect,
  rectType,
  rectChanged,
}: AnalyseMapViewLayerProps) => {
  const lower = <div className={!upperView ? `map-view-image-fullbright` : `map-view-image-dimmed`} style={{ backgroundImage: mapName === 'de_vertigo' || mapName === 'de_nuke' ? `url('${process.env.PUBLIC_URL}/maps/${mapName}_lower.png')` : '' }}></div>
  const upper = <div className={upperView ? `map-view-image-fullbright map-view-shadows` : `map-view-image-dimmed map-view-shadows`} style={{ backgroundImage: `url('${process.env.PUBLIC_URL}/maps/${mapName}.png')` }}></div>

  const backgrounds = upperView ? <>{lower}{upper}</> : <>{upper}{lower}</>;

  return (
    <>
      <div className="map-view-wrapper p-3">
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "100%",
            transform: `translate(${mapOffset[0]}vmin, ${mapOffset[1]}vmin) scale(${mapZoom})`,
          }}
        >          
          <div style={{ position: "absolute", width: "100%", height: "100%" }}>
            {backgrounds}
          </div>
          {currentTick !== undefined ? (
            <div style={{ position: "absolute", width: "100%", height: "100%" }}>
              <AnalyseMapViewSVG
                playerFrames={playerFrames}
                upperView={upperView}
                mapName={mapName}
                currentTick={currentTick}
                projectiles={projectiles}
                explosions={explosions}
                smokes={smokes}
                fires={fires}
                weaponFires={weaponFires}
                bullets={bullets}
                damages={damages}
                kills={kills}
                deaths={deaths}
                bomb={bomb}
                bombPlanted={bombPlanted}
                bombTimeLeft={bombTimeLeft}
                bombDefusing={bombDefusing}
                activePlayerSteamId={activePlayerSteamId}
                hoveredPlayerSteamId={hoveredPlayerSteamId}
                activeRound={activeRound}
                hoveredRound={hoveredRound}
                mapZoom={mapZoom}
                playerColors={playerColors}
                csVersion={csVersion}
                onPlayerBeginHover={onPlayerBeginHover}
                onPlayerEndHover={onPlayerEndHover}
                onPlayerClick={onPlayerClick}
                rect={rect}
                rectType={rectType}
                rectChanged={rectChanged}
              />
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}


export default AnalyseMapViewLayer;