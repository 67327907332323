import React, { ReactNode, useEffect, useState } from "react";
import { ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import styles from './MultiSelectButtonGroup.module.css';

interface MultiSelectButtonGroupProps {
  groupId: string;
  options: { key: string; label: ReactNode | string }[];
  value: string | string[];
  multiSelect: boolean;
  allowEmpty: boolean;
  flexDirection: "row" | "column";
  buttonStyle?: React.CSSProperties;
  multiSelectWithControlKey?: boolean;
  onSelect: (selected: string | string[]) => void;
}

const MultiSelectButtonGroup: React.FC<MultiSelectButtonGroupProps> = ({
  groupId,
  options,
  value,
  multiSelect,
  allowEmpty,
  flexDirection = "row",
  buttonStyle,
  onSelect,
  multiSelectWithControlKey = false,
}) => {
  const valueArray = Array.isArray(value) ? value : [value];

  const handleClick = (clickedValue: string, isCtrlPressed: boolean) => {
    let newVal: string[] = [...valueArray];

    if (multiSelect || (multiSelectWithControlKey && isCtrlPressed)) {
      if (newVal.includes(clickedValue)) {
        newVal = newVal.filter(val => val !== clickedValue);
      } else {
        newVal.push(clickedValue);
      }
    } else {
      if (newVal.includes(clickedValue)) {
        if(!multiSelect && multiSelectWithControlKey && newVal.length > 1) {
          newVal = [clickedValue];
        } else {
          newVal = [];
        }
      } else {
        newVal = [clickedValue];
      }
    }

    if (!allowEmpty && newVal.length === 0) {
      newVal = valueArray; // Revert to the previous value if empty selection is not allowed
    }

    onSelect(newVal.length === 1 ? newVal[0] : newVal);
  };

  const buttonClass = flexDirection === "column" ? "toggleButtonColumn" : "toggleButton";

  return (
    <ToggleButtonGroup
      className={`${styles.buttonGroupFullWidth} ${styles.toggleButtonGroup}`}
      style={{ flexDirection }}
      key={`btngroup-${groupId}`}
      id={`btngroup-${groupId}`}
      type="checkbox"
      value={valueArray}
    >
      {options.map((option) => (
        <ToggleButton
          key={`btn-${groupId}-${option.key}`}
          id={`btn-${groupId}-${option.key}`}
          className={`${styles[buttonClass]} ${styles.toggleButton} ${valueArray.includes(option.key) ? styles.selectedButton : ""}`}
          value={option.key}
          style={buttonStyle}
          variant="outline-primary"
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleClick(option.key, e.ctrlKey)}
        >
          {option.label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export default MultiSelectButtonGroup;

