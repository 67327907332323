import { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import SignInButton from "../components/SignInButton";
import styles from "./Checkout.module.css";

const CheckoutSuccess = () => {

  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.reload();  // refresh the page
    }, 10000);  // 10 seconds

    // Cleanup: clear the timer when component is unmounted
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={`container ${styles.checkoutContainer}`}>
      <Container fluid>
        <Row>
          <Col>
            <h1><i className="bi bi-check" style={{ color: '#0d0' }}></i> Checkout complete?</h1>
          </Col>
        </Row>
        <hr />
        <Row className={styles.myrow}>
          <Col>
            <p>Once you've finished your payment, please be patient! 🙏</p>
          </Col>
        </Row>
        <Row className={styles.myrow}>
          <Col>
            <p>There may be a short lag in the processing of your subscription. If the problem persists, please <a href="https://cs2lens.com/support">contact</a> us immediately.</p>
          </Col>
        </Row>
        <Row className={styles.myrow}>
          <Col>
            <p>Once activated, your subscription will be displayed in the top-left header.</p>
          </Col>
        </Row>
        {/* <Row className={styles.myrow}>
          <Col>
            <SignInButton onLogin={() => window.location.href = "/"} onForgotPassword={() => window.location.href = "/"} label={"Re-login"} />
          </Col>
        </Row> */}
      </Container>
    </div>
  );
};

export default CheckoutSuccess;