import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Button, Spinner } from 'react-bootstrap';

interface RecordingGizmoProps {
  recordingRect: number[],
  recording: boolean,
  recordingProgress: number,
  setRecordingRect: (recordingRect: number[]) => void,
  onStartRecording: () => void,
  onCancelRecording: () => void,
}

const RecordingGizmo = ({
  recordingRect,
  recording,
  recordingProgress,
  setRecordingRect,
  onStartRecording,
  onCancelRecording,
}: RecordingGizmoProps) => {
  const [dragging, setDragging] = useState<number[] | null>(null);
  const [draggingRect, setDraggingRect] = useState(false);
  const [initialMousePos, setInitialMousePos] = useState<{ x: number; y: number } | null>(null);
  const svgRef = useRef<SVGSVGElement>(null);
  const buttonWrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
    const svg = svgRef.current;

    if (!svg) {
      return;
    }

    const viewbox = svg.getAttribute('viewBox');

    if (!viewbox) {
      return;
    }

    const viewBox = viewbox.split(' ').map(Number);
    const aspectRatio = viewBox[2] / viewBox[3];

    const setDivDimensionsAndPosition = () => {
      const wrapper = buttonWrapperRef.current;

      if (!wrapper) {
        return;
      }

      const svgWidth = svg.clientWidth;
      const svgHeight = svg.clientHeight;
      const divWidth = Math.min(svgWidth, svgHeight * aspectRatio);
      const divHeight = Math.min(svgHeight, svgWidth / aspectRatio);
      wrapper.style.width = `${divWidth}px`;
      wrapper.style.height = `${divHeight}px`;

      // Calculate and set the position
      const positionX = (svgWidth - divWidth) / 2;
      const positionY = (svgHeight - divHeight) / 2;
      wrapper.style.position = 'absolute';
      wrapper.style.left = `${positionX}px`;
      wrapper.style.top = `${positionY}px`;
    };

    setDivDimensionsAndPosition();
    window.addEventListener('resize', setDivDimensionsAndPosition);

    return () => {
      window.removeEventListener('resize', setDivDimensionsAndPosition);
    };
  }, [svgRef]);

  const handleMouseDown = useCallback(
    (xIndex: number, yIndex: number) => (event: React.MouseEvent) => {
      setDraggingRect(false);
      setDragging([xIndex, yIndex]);
    },
    []
  );

  const handleRectMouseDown = useCallback(
    (event: React.MouseEvent) => {
      setDraggingRect(true);
      setInitialMousePos({ x: event.clientX, y: event.clientY });
    },
    []
  );

  const handleMouseMove = useCallback(
    (event: React.MouseEvent) => {
      const minWidth = 25; // Set the minimum width
      const minHeight = 10; // Set the minimum height
  
      if (!svgRef.current || (!dragging && !draggingRect)) return;
  
      const svg = svgRef.current;
      const ctm = svg.getScreenCTM();
      if (!ctm) return;
  
      const point = svg.createSVGPoint();
      point.x = event.clientX;
      point.y = event.clientY;
      const viewBoxPoint = point.matrixTransform(ctm.inverse());
  
      if (dragging) {
        const updatedRect = [...recordingRect];
        updatedRect[dragging[0]] = viewBoxPoint.x;
        updatedRect[dragging[1]] = viewBoxPoint.y;
  
        // Calculate the width and height of the updated rectangle
        const updatedWidth = Math.abs(updatedRect[2] - updatedRect[0]);
        const updatedHeight = Math.abs(updatedRect[3] - updatedRect[1]);
  
        // Enforce minimum width and height constraints
        if (updatedWidth >= minWidth && updatedHeight >= minHeight) {
          setRecordingRect(updatedRect);
        }
      } else if (draggingRect && initialMousePos) {
        const initialPoint = svg.createSVGPoint();
        initialPoint.x = initialMousePos.x;
        initialPoint.y = initialMousePos.y;
        const initialViewBoxPoint = initialPoint.matrixTransform(ctm.inverse());
        const deltaX = viewBoxPoint.x - initialViewBoxPoint.x;
        const deltaY = viewBoxPoint.y - initialViewBoxPoint.y;
        setRecordingRect([
          recordingRect[0] + deltaX,
          recordingRect[1] + deltaY,
          recordingRect[2] + deltaX,
          recordingRect[3] + deltaY,
        ]);
        setInitialMousePos({ x: event.clientX, y: event.clientY });
      }
    },
    [dragging, draggingRect, recordingRect, setRecordingRect, initialMousePos]
  );
  

  const handleMouseUp = useCallback(() => {
    setDragging(null);
    setDraggingRect(false);
    setInitialMousePos(null);
  }, []);

  const corners = [
    { x: 0, y: 1 },
    { x: 2, y: 1 },
    { x: 0, y: 3 },
    { x: 2, y: 3 },
  ];

  return (
    <>
      <svg
        ref={svgRef}
        width="100%"
        height="100%"
        viewBox="0 0 100 100"
        preserveAspectRatio='xMidYMid'
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
      >
        <defs>
          <mask id="cutout-rect">
            <rect x="0" y="0" width="100" height="100" fill="white" />
            <rect
              x={recordingRect[0]}
              y={recordingRect[1]}
              width={recordingRect[2] - recordingRect[0]}
              height={recordingRect[3] - recordingRect[1]}
              fill="black"
            />
          </mask>
        </defs>
        <rect
          x="0"
          y="0"
          width="100"
          height="100"
          fill="rgba(34, 34, 34, 0.66)"
          mask="url(#cutout-rect)"
        />
        <rect
          x={recordingRect[0]}
          y={recordingRect[1]}
          width={recordingRect[2] - recordingRect[0]}
          height={recordingRect[3] - recordingRect[1]}
          fill="rgb(0,0,0,0)"
          stroke="#888"
          strokeWidth={0.1}
          strokeDasharray="0.5 0.5"
          style={{ cursor: draggingRect ? 'grabbing' : 'grab' }}
          onMouseDown={!recording ? handleRectMouseDown : undefined}
        />
        {recording ? (
          <rect
            x={recordingRect[0]}
            y={recordingRect[1]}
            width={(recordingRect[2] - recordingRect[0]) * recordingProgress}
            height={1}
            fill="rgba(224,32,32,1.0)"
          />
        ) : null}
          <style>
            {`
              .corner-rect:hover {
                fill: rgb(128, 128, 128);
              }
            `}
          </style>
        {corners.map((corner, index) => (
          <rect
            key={`corner-${index}`}
            className="corner-rect"
            x={recordingRect[corner.x] - 0.65}
            y={recordingRect[corner.y] - 0.65}
            rx={0.25}
            ry={0.25}
            width={1.3}
            height={1.3}
            r="1"
            fill={dragging && dragging[0] === corner.x && dragging[1] === corner.y ? "rgb(128, 128, 128)" : "rgba(64,64,64)"}
            stroke="#fff"
            strokeWidth={0.1}
            style={{ cursor: index === 2 || index === 1 ? 'nesw-resize' : 'nwse-resize' }}
            onMouseDown={!recording ? handleMouseDown(corner.x, corner.y) : undefined}
          />
        ))}
      </svg>
      <div ref={buttonWrapperRef} style={{pointerEvents: 'none'}}>
      {recording ? (
        <div
          style={{
            position: 'absolute',
            top: `${recordingRect[1] + (recordingRect[3] - recordingRect[1]) / 2}%`,
            left: `${(recordingRect[0] + (recordingRect[2] - recordingRect[0]) / 2)}%`,
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner animation="border" variant="danger" style={{color: recordingProgress < 1.0 ? 'rgba(255,0,0,0.4)' : 'rgba(0,255,0,0.4)', marginRight: '10px', width: '80px', height: '80px'}}/>
        </div>
      ) : null}
        <div
          style={{
            position: 'absolute',
            top: `${Math.min((recordingRect[1] + (recordingRect[3] - recordingRect[1])), 97)}%`,
            left: `${(recordingRect[0] + (recordingRect[2] - recordingRect[0]) / 2)}%`,
            width: `170px`,
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            className="record-button"
            size="sm"
            style={{
              width: '100%',
              filter: 'drop-shadow(0px 0px 4px rgba(0,0,0,1.0))',
              border: '1px solid #888',
              pointerEvents: dragging ? 'none' : 'all'
            }}
            onClick={() => {
              if (recording) {
                onCancelRecording();
              } else {
                onStartRecording();
              }
            }}
          >
              {recording ? (
                <>
                  <i className="bi bi-x-circle" style={{color: '#da3'}}></i><span style={{color: '#aaa', marginLeft: '5px'}}>Cancel</span>
                </>
              ) : (
                <>
                  <i className="bi bi-record-fill" style={{color: 'red'}}></i> Record
                </>
              )}
          </Button>
        </div>
      </div>
    </>
  );
}

export default RecordingGizmo;
