import { useEffect, useState } from "react";
import { mapInfo } from "../utils/maps";
import { interpolateBombFramesLinear, interpolatePlayerFramesLinear, interpolateProjectileFramesLinear, world_to_screen } from "../utils/math";
import { CachedProjectiles, calculateProjectileCache, getExplosionTick, precalculateBlindedPlayers, Projectile } from "../utils/projectile";
import MapViewLayer from "./MapViewLayer";
import { DamageData, FireData, FrameData, GrenadeData, PlayerFrameData, PlayerGizmos, ProjectileData, RoundData, Side, WeaponFireData } from "../utils/types";
import Clock from "./Clock";

export interface SmokeInfo {
  uniqueID: number
  position: [number, number, number]
  startTick: number
  endTick: number
  side: Side
  thrower: PlayerFrameData
}

export interface FireInfo {
  uniqueID: number
  position: [number, number, number]
  startTick: number
  endTick: number
  side: Side
}

export interface CachedFires {
  [key: number]: FireInfo
}

const MapView = ({ data, currentTick, mapName, hoveredPlayer, upperView, playerGizmos, activePaintColor, paintUndoCount, paintClearCount, painting, wideLayout, csVersion, recording: showClock, recordingRect: clockArea, onPlayerBeginHover, onPlayerEndHover }: {
  data: RoundData | undefined,
  currentTick: number | undefined,
  mapName: string,
  hoveredPlayer: number | undefined,
  upperView: boolean,
  playerGizmos: PlayerGizmos,
  activePaintColor: number[],
  paintUndoCount: number,
  paintClearCount: number,
  painting: boolean,
  wideLayout: boolean,
  csVersion: string,
  recording: boolean,
  recordingRect: number[] | undefined,
  onPlayerBeginHover: (steamID: number) => void,
  onPlayerEndHover: (steamID: number) => void,
}) => {
  const [projectileCache, setProjectileCache] = useState<CachedProjectiles>({});

  useEffect(() => {
    if (data) {
      setProjectileCache(calculateProjectileCache(data, mapName));
      precalculateBlindedPlayers(data);
    }
  }, [data, mapName]);

  const canRender = data !== undefined && currentTick !== undefined;
  const tickRate = csVersion === "cs2" ? 64 : 128;

  let playerFrames: PlayerFrameData[] = [];
  let deadPlayerFrames: PlayerFrameData[] = [];
  let projectiles: Projectile[] = [];
  let explosions: GrenadeData[] = [];
  let smokes: SmokeInfo[] = [];
  let fires: FireInfo[] = [];
  let weaponFires: WeaponFireData[] = [];
  let bullets: DamageData[] = [];
  let damages: DamageData[] = [];
  let bomb = null;
  let bombPlanted = false;
  let bombTimeLeft = 0;
  let bombDefusing = false;

  if (canRender) {
    const startTick = data.frames[0].tick;
    const endTick = data.frames[data.frames.length - 1].tick;

    const tickStart: number = Math.max(...data.frames.map((f) => f.tick).filter((t) => t <= currentTick && t >= startTick && t < endTick));
    const tickEnd: number = Math.min(...data.frames.map((f) => f.tick).filter((t) => t >= currentTick && t > startTick && t <= endTick && t !== tickStart));
    const frameStart: FrameData = data.frames.find((f) => f.tick === tickStart)!;
    const frameEnd: FrameData = data.frames.find((f) => f.tick === tickEnd)!;
    const frameBefore: FrameData | undefined = data.frames.indexOf(frameStart) > 0 ? data.frames[data.frames.indexOf(frameStart) - 1] : undefined;
    const currentFrameIndex = data.frames.indexOf(frameStart);

    if (frameStart && frameEnd) {
      const t = (currentTick - tickStart) / (frameEnd.tick - frameStart.tick);
      for (let i = 0; i < frameStart.t.players.length; i++) {
        const pStart = frameStart.t.players[i];
        const pEnd = frameEnd.t.players.find((p) => p.steamID === pStart.steamID)!;
        if (pStart && pEnd) {
          const pFrame = interpolatePlayerFramesLinear(pStart, pEnd, t)
          pStart.isAlive ? playerFrames.push(pFrame) : deadPlayerFrames.push(pFrame);
        }
      }
      for (let i = 0; i < frameStart.ct.players.length; i++) {
        const pStart = frameStart.ct.players[i];
        const pEnd = frameEnd.ct.players.find((p) => p.steamID === pStart.steamID)!;
        if (pStart && pEnd) {
          const pFrame = interpolatePlayerFramesLinear(pStart, pEnd, t)
          pStart.isAlive ? playerFrames.push(pFrame) : deadPlayerFrames.push(pFrame);
        }
      }
      const zSortedProjectiles = frameStart.projectiles.sort((a, b) => a.z - b.z);
      for (let i = 0; i < zSortedProjectiles.length; i++) {
        const pStart = zSortedProjectiles[i];
        let pEnd = frameEnd.projectiles.find((p) => p.uniqueID === pStart.uniqueID) ?? undefined;

        // Sometimes we don't have projectile in next frame, but we have it in projectileCache for fires
        if (pStart && pEnd === undefined && (pStart.projectileType === "Molotov" || pStart.projectileType === "Incendiary Grenade")) {
          const cachedPos = projectileCache[pStart.uniqueID]?.positions[currentFrameIndex + 1];
          if (cachedPos && cachedPos[0] !== undefined && cachedPos[1] !== undefined) {
            pEnd = {
              ...pStart,
              x: cachedPos[0],
              y: cachedPos[1],
              z: pStart.z,
            } as ProjectileData;
          }
        }

        if (pStart && pEnd && pStart.projectileType === "Smoke Grenade" && pStart.z === pEnd.z && pStart.uniqueID in projectileCache) {
          const projectile = projectileCache[pStart.uniqueID];
          smokes.push({
            uniqueID: pStart.uniqueID,
            position: [pStart.x, pStart.y, pStart.z],
            startTick: projectile.endTick,
            endTick: projectile.turnOffTick,
            side: projectile.side,
            thrower: projectile.thrower,
          });
        } else if (pStart && pEnd && pStart.uniqueID in projectileCache && (pStart.projectileType !== "HE Grenade" || data.grenades.some((g) => g.entityId === pStart.uniqueID && g.throwTick <= currentTick && getExplosionTick(g, data.cs_version) >= currentTick))) {
          const projectile = projectileCache[pStart.uniqueID];
          projectiles.push({
            data: interpolateProjectileFramesLinear(pStart, pEnd, t),
            throwerPosition: projectile.throwerPosition,
            positions: projectile.positions,
            visualPositions: projectile.visualPositions,
            visualPositionsFlat: projectile.visualPositionsFlat,
            minZ: projectile.minZ,
            maxZ: projectile.maxZ,
            endZ: projectile.endZ,
            startTick: projectile.startTick,
            endTick: projectile.endTick,
            currentFrameIndex: currentFrameIndex,
            angularVelocity: projectile.angularVelocity,
            startAngle: projectile.startAngle,
            side: projectile.side,
          });
        }
      }

      for (let i = 0; i < frameStart.fires.length; i++) {
        const pStart = frameStart.fires[i];
        const pEnd = frameEnd.fires.find((p) => p.uniqueID === pStart.uniqueID)!;
        if (pStart && pEnd && !fires.find((s) => s.uniqueID === pStart.uniqueID)) {
          const projectileUniqueID = Object.keys(projectileCache).map(Number).find((k) => projectileCache[k].fireUniqueID === pStart.uniqueID);
          if (projectileUniqueID) {
            const projectile = projectileCache[projectileUniqueID];
            if (csVersion === "cs2") {
              if (frameStart.tick > projectile.turnOnTick + 64 * 7) {
                continue;
              }
              fires.push({
                uniqueID: pStart.uniqueID,
                position: [pStart.x, pStart.y, pStart.z],
                startTick: projectile.turnOnTick,
                endTick: projectile.turnOnTick + 64 * 7,
                side: projectile.side,
              });
            } else {
              fires.push({
                uniqueID: pStart.uniqueID,
                position: [pStart.x, pStart.y, pStart.z],
                startTick: projectile.turnOnTick,
                endTick: projectile.turnOffTick,
                side: projectile.side,
              });
            }
          }
        }
      }

      for (let i = 0; i < data.grenades.length; i++) {
        if (data.grenades[i].grenadeType === "Flashbang" || data.grenades[i].grenadeType === "HE Grenade") {
          const explosionTick = getExplosionTick(data.grenades[i], data.cs_version);
          if (explosionTick < currentTick && explosionTick + 2000 > currentTick && (data.grenades[i].grenadeX !== 0.0 || data.grenades[i].grenadeY !== 0.0)) {
            explosions.push(data.grenades[i]);
          }
        }
      }
      for (let i = 0; i < data.weaponFires.length; i++) {
        if (data.weaponFires[i].tick < currentTick && data.weaponFires[i].tick + 200 > currentTick && data.weaponFires[i].weaponClass !== "Equipment" && data.weaponFires[i].weaponClass !== "Grenade") {
          weaponFires.push(data.weaponFires[i]);
        }
      }
      for (let i = 0; i < data.damages.length; i++) {
        if (data.damages[i].tick - data.damages[i].distance / 50 < currentTick &&
          data.damages[i].tick > currentTick &&
          data.damages[i].weaponClass !== "Equipment" &&
          data.damages[i].weaponClass !== "Grenade" &&
          !bullets.some(b =>
            b.tick === data.damages[i].tick &&
            b.attackerSteamID === data.damages[i].attackerSteamID &&
            b.victimSteamID === data.damages[i].victimSteamID)
        ) {
          bullets.push(data.damages[i]);
        }
        if (data.damages[i].tick < currentTick && data.damages[i].tick + 500 > currentTick) {
          damages.push(data.damages[i]);
        }
      }

      if (frameStart.t.players.every((p) => !p.hasBomb) || frameStart.bombPlanted) {
        bombPlanted = frameStart.bombPlanted;
        bombTimeLeft = 40 * tickRate - (currentTick - data.bombPlantTick);
        bomb = interpolateBombFramesLinear(frameStart.bomb, frameEnd.bomb, t);
        bombDefusing = data.frames.some((f) => f.tick < currentTick && f.ct.players.some((p) => p.isDefusing && data?.roundEndReason === "BombDefused"));
      }
    }
  }

  const mapOffset = mapInfo[mapName as keyof typeof mapInfo].offset;
  const mapZoom = wideLayout ? mapInfo[mapName as keyof typeof mapInfo].zoomWide : mapInfo[mapName as keyof typeof mapInfo].zoom;

  return (<>
    {showClock && <Clock
      currentTick={currentTick}
      startTick={data?.frames[0].tick || 0}
      bombPlantTick={data?.bombPlantTick}
      csVersion={csVersion}
      areaClock={true}
      area={clockArea}
    />}
    <MapViewLayer
      upperView={upperView}
      canRender={canRender}
      mapName={mapName}
      mapOffset={mapOffset}
      mapZoom={mapZoom}
      currentTick={currentTick}
      playerFrames={playerFrames}
      deadPlayerFrames={deadPlayerFrames}
      projectiles={projectiles}
      smokes={smokes}
      fires={fires}
      explosions={explosions}
      weaponFires={weaponFires}
      bullets={bullets}
      damages={damages}
      bomb={bomb}
      bombPlanted={bombPlanted}
      bombTimeLeft={bombTimeLeft}
      bombDefusing={bombDefusing}
      hoveredPlayer={hoveredPlayer}
      playerGizmos={playerGizmos}
      activePaintColor={activePaintColor}
      paintUndoCount={paintUndoCount}
      paintClearCount={paintClearCount}
      painting={painting}
      csVersion={data?.cs_version ?? "csgo"}
      onPlayerBeginHover={onPlayerBeginHover}
      onPlayerEndHover={onPlayerEndHover}
    />
  </>
  );
};

export default MapView;