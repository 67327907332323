import GIF from "gif.js";
import html2canvas from "html2canvas";

// This transforms the 1:1 aspect ratio recording area rect into outer rect, which is the entire mapview size.
export function innerToOuterRect(innerRectArea: number[], outerRect: number[]) {
  const outerWidth = outerRect[2] - outerRect[0];
  const outerHeight = outerRect[3] - outerRect[1];

  let innerWidth, innerHeight, offsetX, offsetY;

  if (outerWidth > outerHeight) {
    innerWidth = outerHeight;
    innerHeight = outerHeight;
    offsetX = (outerWidth - innerWidth) / 2;
    offsetY = 0;
  } else {
    innerWidth = outerWidth;
    innerHeight = outerWidth;
    offsetX = 0;
    offsetY = (outerHeight - innerHeight) / 2;
  }

  // Transform innerRectArea percentages (relative to the inner rect)
  // to percentages of the outer rect
  const innerX1 = (innerRectArea[0] * innerWidth / 100 + offsetX) / outerWidth * 100;
  const innerY1 = (innerRectArea[1] * innerHeight / 100 + offsetY) / outerHeight * 100;
  const innerX2 = (innerRectArea[2] * innerWidth / 100 + offsetX) / outerWidth * 100;
  const innerY2 = (innerRectArea[3] * innerHeight / 100 + offsetY) / outerHeight * 100;

  // Return the transformed innerRectArea as percentages of the outer rect
  return [innerX1, innerY1, innerX2, innerY2];
}

export const captureAndExportGIF = async (
  element: HTMLElement,
  startTick: number,
  endTick: number,
  frameRate: number,
  speed: number,
  quality: number,
  rect: number[],
  title: string,
  csVersion: string | undefined,
  setRecordingProgress: (progress: number) => void,
  setCurrentTick: (tick: number) => void,
  setRecording: (isRecording: boolean) => void,
  setReadyToRecord: (isReady: boolean) => void,
) => {
  const croppedWidth = Math.round(element.clientWidth * (rect[2] - rect[0]) / 100);
  const croppedHeight = Math.round(element.clientHeight * (rect[3] - rect[1]) / 100);

  const gif = new GIF({
    workerScript: `${process.env.PUBLIC_URL}/gifworker/gif.worker.js`,
    workers: 2,
    quality,
    width: croppedWidth,
    height: croppedHeight,
  });
  const tickRate = csVersion === "cs2" ? 64 : 128;
  const length = (endTick - startTick) / (tickRate * speed);
  const step = tickRate * speed / frameRate;
  for (let i = 0; i < length * frameRate; i++) {
    setRecordingProgress(i / (length * frameRate));
    setCurrentTick(startTick + i * step);

    const canvas = await html2canvas(element, { backgroundColor: 'rgb(34,34,34)' });
    const dataUrl = canvas.toDataURL();
    const imgElement = new Image();
    imgElement.src = dataUrl;

    // Wait for the image to load
    await new Promise((resolve) => {
      imgElement.onload = () => resolve(null);
    });

    // Create a canvas for cropping
    const cropCanvas = document.createElement('canvas');
    cropCanvas.width = croppedWidth;
    cropCanvas.height = croppedHeight;
    const cropCtx = cropCanvas.getContext('2d');

    if (!cropCtx) {
      return;
    }

    // Calculate the actual pixel values for the cropping rectangle
    const x1 = Math.round(element.clientWidth * rect[0] / 100);
    const y1 = Math.round(element.clientHeight * rect[1] / 100);

    // Crop the image by drawing the desired area onto the new canvas
    cropCtx.drawImage(
      imgElement,
      x1,
      y1,
      croppedWidth,
      croppedHeight,
      0,
      0,
      croppedWidth,
      croppedHeight
    );

    // Add the cropped frame to the GIF
    gif.addFrame(cropCtx, { delay: 1000 / frameRate });

    // if (!recordingRef.current) {
    //   setCurrentTick(startTick);
    //   return;
    // }
  }
  setRecordingProgress(1.0);

  gif.on("finished", (blob: Blob) => {
    setRecordingProgress(0.0);
    setRecording(false);
    setReadyToRecord(false);
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${title}.gif`;
    link.click();
    URL.revokeObjectURL(url);
  });

  gif.render();
};