import Icon from "./Icon";

const SourceIcon = ({eventKey, size} : {
    eventKey: string,
    size: number,
  }) => {
  let iconName = 'hltv'
  if(eventKey.includes('esea-league')) {
    iconName = 'esea';
  } else if(eventKey.includes('user-upload')) {
    iconName = 'user-upload';
  }
  return iconName !== 'user-upload' ? 
    <Icon 
        prefix="sources/"
        name={iconName}
        postfix=".png"
        size={size}
        shadowStrength={0.7}
        shadowDistance={1}
    /> :
    <i className="bi bi-person-fill" style={{marginRight: '7px', color: '#a68'}}></i>;
}

export default SourceIcon;