export const buyTypeToAPI = (buyType: string) => {
  switch (buyType) {
    case 'Eco':
      return 'Full-Eco';
    case 'Eco+':
      return 'Semi-Eco';
    case 'Semi':
      return 'Semi-Buy';
    case 'Full':
      return 'Full-Buy';
    default:
      return '';
  }
};

export const APIToBuyType = (buyType: string) => {
  switch (buyType) {
    case 'Full-Eco':
      return 'Eco';
    case 'Semi-Eco':
      return 'Eco+';
    case 'Semi-Buy':
      return 'Semi';
    case 'Full-Buy':
      return 'Full';
    default:
      return '';
  }
}

export const buyTypeToDatabase = (buyType: string) => {
  switch (buyType) {
    case 'Eco':
      return 'Full Eco';
    case 'Eco+':
      return 'Semi Eco';
    case 'Semi':
      return 'Semi Buy';
    case 'Full':
      return 'Full Buy';
    default:
      return '';
  }
};

export const databaseToBuyType = (buyType: string) => {
  switch (buyType) {
    case 'Full Eco':
      return 'Eco';
    case 'Semi Eco':
      return 'Eco+';
    case 'Semi Buy':
      return 'Semi';
    case 'Full Buy':
      return 'Full';
    default:
      return '';
  }
};