import { Auth } from "aws-amplify";

export const getApiUrl = () =>
  process.env.REACT_APP_STAGE !== 'dev' 
    ? 'https://u712aatmkj.execute-api.eu-west-1.amazonaws.com/prod' 
    : 'https://u712aatmkj.execute-api.eu-west-1.amazonaws.com/dev';

const apiKey = process.env.REACT_APP_API_KEY || '';

export async function fetchFromApi(endpoint: string, options?: RequestInit): Promise<Response> {
  const url = `${getApiUrl()}${endpoint}`;

  const jwtToken = await getJWTToken();

  const defaultHeaders: Record<string, string> = {
    'x-api-key': apiKey,
  };

  if (jwtToken) {
    defaultHeaders['Authorization'] = `Bearer ${jwtToken}`;
  }

  const mergedOptions = {
    ...options,
    headers: {
      ...defaultHeaders,
      ...(options?.headers),
    },
  };
  return fetch(url, mergedOptions);
};

export async function fetchFromApiUrl(url: string, options?: RequestInit): Promise<Response> { 
  const jwtToken = await getJWTToken();

  const defaultHeaders: Record<string, string> = {
    'x-api-key': apiKey,
  };

  if (jwtToken) {
    defaultHeaders['Authorization'] = `Bearer ${jwtToken}`;
  }

  const mergedOptions = {
    ...options,
    headers: {
      ...defaultHeaders,
      ...(options?.headers),
    },
  };

  return fetch(url, mergedOptions);
};

export async function fetchFromS3(url: string, options?: RequestInit): Promise<Response> { 
  const mergedOptions = {
    ...options,
    headers: {
      ...(options?.headers),
    },
  };

  return fetch(url, mergedOptions);
};

export async function getJWTToken() {
  try {
    const session = await Auth.currentSession();
    const idToken = session.getIdToken();
    const jwtToken = idToken.getJwtToken();
    return jwtToken;
  } catch (error) {
    return null;
  }
}