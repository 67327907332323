import { world_to_screen } from "../utils/math";
import { projectileShapes } from "../utils/projectile";
import { ProjectileData, Side } from "../utils/types";

interface SVGProjectileProps {
  data: ProjectileData,
  throwerPosition: [number, number, number],
  positions: [number | undefined, number | undefined][],
  visualPositions: [number | undefined, number | undefined][],
  mapName: string,
  mapInfo: { [key: string]: any },
  upperView: boolean,
  minZ: number,
  maxZ: number,
  endZ: number,
  scaleFactor: number,
  startTick: number,
  endTick: number,
  angularVelocity: number,
  startAngle: number,
  side: Side,
  currentTick: number,
  currentFrameIndex: number,
  otherLevelOpacity: number;
}

export const SVGProjectile = ({ data, throwerPosition, positions, visualPositions, currentFrameIndex, mapName, mapInfo, upperView, minZ, maxZ, endZ, scaleFactor, startTick, endTick, angularVelocity, startAngle, side, currentTick, otherLevelOpacity }: SVGProjectileProps) => {
  const projectileShape = projectileShapes[data.projectileType]
  const position = world_to_screen([data.x, data.y, data.z], mapName);

  const size = 800.0 / scaleFactor;
  const z = Math.max(data.z - endZ, 0);
  const r = Math.min(Math.max(0.025 + (z / 50000), 0), 0.05) * size;
  const rs = Math.min(Math.max(0.025 + (z / 25000), 0), 0.1) * size;
  const s = 0.1;

  const mInfo = mapInfo[mapName as keyof typeof mapInfo];
  const zOpacity = (data.z - mInfo.minZ) / (mInfo.maxZ - mInfo.minZ);
  const opacity = Math.min(Math.max(upperView ? zOpacity : 1.0 - zOpacity, otherLevelOpacity), 1.0);

  let color = side === "T" ? [255, 157, 16, opacity] : [17, 199, 256, opacity];
  let stroke: number[] = [color[0] * s, color[1] * s, color[2] * s, color[3]];
  let trailColor: number[] = [color[0], color[1], color[2], 0.45 * opacity];

  let shadow = [0, 0, 0, 0.25];

  const extraMultiplier = side === "T" ? 1.5 : 10.0;
  const extraColor = [color[0] * extraMultiplier, color[1] * extraMultiplier, color[2] * extraMultiplier, color[3]];

  const t = (currentTick - startTick) / (endTick - startTick);
  const angle = (t - 0.5) * angularVelocity * 270 + startAngle * 90;

  const shadowPos = [position[0], position[1]];
  const fakeDepthProjectionRatio = 1 / 200; // This needs to match with MapView projectile cache
  const pos = [position[0] - z * fakeDepthProjectionRatio, position[1] - z * fakeDepthProjectionRatio];

  const trail: [[number, number]] = [[pos[0], pos[1]]];
  let i = currentFrameIndex;
  while (visualPositions[i] !== undefined && visualPositions[i][0] !== undefined && visualPositions[i][1] !== undefined && i >= 0) {
    trail.push([visualPositions[i][0] as number, visualPositions[i][1] as number]);
    i--;
  }

  const throwerScreenPosition = world_to_screen([throwerPosition[0] - z / 200, throwerPosition[1] - z / 200, throwerPosition[2]], mapName);
  trail.push([throwerScreenPosition[0], throwerScreenPosition[1]]);
  trail.reverse();

  const trailData = trail.length > 1 ? `M${trail[0][0]},${trail[0][1]} ${trail
      .slice(1)
      .map((p) => `L${p[0]},${p[1]}`)
      .join(' ')
    }` : undefined;

  return (
    <svg>
      <g key={`projectile_icon_${data.uniqueID}`}>
        <path
          transform={`translate(${position[0] + size * 0.005 + z / 200}, ${position[1] + size * 0.005 + z / 200}) scale(${rs}, ${rs}) rotate(${angle}, 0.0, 0.0)`}
          d={projectileShape['main']}
          fill={`rgba(${shadow[0]}, ${shadow[1]}, ${shadow[2]}, ${shadow[3]})`}
          style={{ filter: `blur(${z / 5000}px)` }}
        />
        <path
          transform={`translate(${pos[0]}, ${pos[1]}) scale(${r}, ${r}) rotate(${angle}, 0.0, 0.0)`}
          d={projectileShape['main']}
          fill={'none'}
          stroke={`rgba(${stroke[0]}, ${stroke[1]}, ${stroke[2]}, ${stroke[3]})`}
          strokeWidth={(0.1)}
        />
        <path
          transform={`translate(${pos[0]}, ${pos[1]}) scale(${r}, ${r}) rotate(${angle}, 0.0, 0.0)`}
          d={projectileShape['main']}
          fill={`rgba(${color[0]}, ${color[1]}, ${color[2]}, ${color[3]})`}
        />
        <path
          transform={`translate(${pos[0]}, ${pos[1]}) scale(${r}, ${r}) rotate(${angle}, 0.0, 0.0)`}
          d={projectileShape['extra']}
          fill={`rgba(${extraColor[0]}, ${extraColor[1]}, ${extraColor[2]}, ${extraColor[3]})`}
        />
      </g>
      <g key={`projectile_trace_${data.uniqueID}_shadow`}>
        <path
          d={trailData}
          fill="none"
          stroke={`rgba(${trailColor[0]}, ${trailColor[1]}, ${trailColor[2]}, ${trailColor[3]})`}
          strokeWidth="0.1"
          strokeDasharray="0.4, 0.6"
          strokeDashoffset="0px"
        />
      </g>
    </svg>
  );
}
