import { Auth } from 'aws-amplify';

export async function getSubscription() {
  try {
    const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
    const attributes = user.signInUserSession.idToken.payload;
    if (attributes && 'custom:subscription' in attributes) {
      return attributes['custom:subscription'];
    }
    return 'FREE';
  } catch (error) {
    return 'FREE';
  }
}

export async function getHasSubscription() {
  try {
    const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
    const attributes = user.signInUserSession.idToken.payload;
    if (attributes && 'custom:subscription' in attributes) {
      return attributes['custom:subscription'] === 'PREMIUM' || attributes['custom:subscription'] === 'BASIC';
    }
    return false;
  } catch (error) {
    return false;
  }
}

export async function isStripe() {
  try {
    const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
    const attributes = user.signInUserSession.idToken.payload;
    if (attributes && 'custom:stripe_sub_id' in attributes && attributes['custom:stripe_sub_id'].length > 0) {
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
}

const lockTime = 30; // Days

export function calculateTimeLeft(matchId: string) {
  const matchDate = matchId.split('.')[0];
  const matchDateTime = new Date(matchDate);
  matchDateTime.setDate(matchDateTime.getDate() + lockTime);

  const now = new Date();
  const difference = matchDateTime.getTime() - now.getTime();

  // Calculate time left
  const days = Math.floor(difference / (1000 * 60 * 60 * 24));
  const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((difference / 1000 / 60) % 60);
  const seconds = Math.floor((difference / 1000) % 60);

  // Check if there is a difference
  if (difference > 0) {
    return {
      days: days > 0 ? days : 0,
      hours: hours > 0 ? hours : 0,
      minutes: minutes > 0 ? minutes : 0,
      seconds: seconds > 0 ? seconds : 0,
    };
  } else {
    // If the time is up, return zeros
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  }
}

// Store the original getTime function at the module level, outside of your function
const originalGetTime = Date.prototype.getTime;

export function isLocked(matchId: string, subscription: string) {
  if(matchId.includes('user-upload')) return false;
  if(subscription === 'PREMIUM') return false;
  if(matchId.includes('esea') && subscription === 'BASIC') return false;

  const matchDate = matchId.split('.')[0];
  const matchDateTime = new Date(matchDate);
  
  // Use the original setDate method to avoid the same kind of tampering
  const originalSetDate = Date.prototype.setDate;
  originalSetDate.call(matchDateTime, matchDateTime.getDate() + lockTime);

  // Use the stored original getTime function
  const now = new Date();
  const difference = originalGetTime.call(matchDateTime) - originalGetTime.call(now);

  return difference > 0;
}
