import { CachedProjectiles, Projectile } from "../utils/projectile";
import AnalyseHeatMapViewSVG from "./AnalyseHeatMapViewSVG";
import { FireInfo, SmokeInfo } from "./MapView";
import { GrenadeData, PlayerFrameData } from "../utils/types";

interface AnalyseHeatMapViewLayerProps {
  playerFrames: PlayerFrameData[],
  upperView: boolean,
  mapName: string,
  mapOffset: number[],
  mapZoom: number,
  currentTick: number | undefined,
  currentTickRange: number[],
  projectiles: Projectile[],
  explosions: GrenadeData[],
  smokes: SmokeInfo[],
  fires: FireInfo[],
  smoothing: number,
}

const AnalyseHeatMapViewLayer = ({
  playerFrames,
  upperView,
  mapName,
  mapOffset,
  mapZoom,
  currentTick,
  currentTickRange,
  projectiles,
  explosions,
  smokes,
  fires,
  smoothing,
}: AnalyseHeatMapViewLayerProps) => {
  const lower = <div className={!upperView ? `map-view-image-fullbright` : `map-view-image-dimmed`} style={{ backgroundImage: mapName === 'de_vertigo' || mapName === 'de_nuke' ? `url('${process.env.PUBLIC_URL}/maps/${mapName}_lower.png')` : '' }}></div>
  const upper = <div className={upperView ? `map-view-image-fullbright map-view-shadows` : `map-view-image-dimmed map-view-shadows`} style={{ backgroundImage: `url('${process.env.PUBLIC_URL}/maps/${mapName}.png')` }}></div>

  const backgrounds = upperView ? <>{lower}{upper}</> : <>{upper}{lower}</>;

  return (
    <>
      <div className="map-view-wrapper p-3">
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "100%",
            transform: `translate(${mapOffset[0]}vmin, ${mapOffset[1]}vmin) scale(${mapZoom})`,
          }}
        >          
          <div style={{ position: "absolute", width: "100%", height: "100%" }}>
            {backgrounds}
          </div>
          {currentTick !== undefined ? (
            <div style={{ position: "absolute", width: "100%", height: "100%" }}>
              <AnalyseHeatMapViewSVG
                playerFrames={playerFrames}
                upperView={upperView}
                mapName={mapName}
                currentTick={currentTick}
                currentTickRange={currentTickRange}
                projectiles={projectiles}
                explosions={explosions}
                smokes={smokes}
                fires={fires}
                smoothing={smoothing}
              />
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}


export default AnalyseHeatMapViewLayer;