import { mapInfoAnalyse } from "../utils/maps";
import { GrenadeData, PlayerFrameData } from "../utils/types";
import { FireInfo, SmokeInfo } from "./MapView";
import { Projectile } from "../utils/projectile";
import AnalyseHeatMapViewLayer from "./AnalyseHeatMapViewLayer";


interface AnalyseHeatMapViewProps {
  upperView: boolean,
  mapName: string,
  currentTick: number | undefined,
  currentTickRange: number[],
  playerFrames: PlayerFrameData[],
  projectiles: Projectile[],
  explosions: GrenadeData[],
  smokes: SmokeInfo[],
  fires: FireInfo[],
  smoothing: number,
}

const AnalyseHeatMapView = ({
  upperView,
  mapName,
  currentTick,
  currentTickRange,
  playerFrames,
  projectiles,
  explosions,
  smokes,
  fires,
  smoothing,
}: AnalyseHeatMapViewProps) => {
  const mapOffset = mapInfoAnalyse[mapName as keyof typeof mapInfoAnalyse].offset;
  const mapZoom = mapInfoAnalyse[mapName as keyof typeof mapInfoAnalyse].zoom;

  return (
    <AnalyseHeatMapViewLayer
      playerFrames={playerFrames}
      projectiles={projectiles}
      explosions={explosions}
      smokes={smokes}
      fires={fires}
      upperView={upperView}
      mapName={mapName}
      mapOffset={mapOffset}
      mapZoom={mapZoom}
      currentTick={currentTick}
      currentTickRange={currentTickRange}
      smoothing={smoothing}
    />
  );
}

export default AnalyseHeatMapView;