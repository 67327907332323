import { Col, Container, Row } from "react-bootstrap";
import styles from "./Account.module.css";

const Support = () => {
  return (
    <div className={`container ${styles.accountContainer}`}>
      <Container fluid>
        <Row className={styles.myrow}>
          <Col>
            <h2>Need help?</h2>
            <hr />
            Don't hesitate to reach out ❤️
            <hr />
          </Col>
        </Row>
        <Row className={styles.valuerow}>
        <Col sm={3} className={styles.label}><i className="bi bi-people" style={{ marginRight: 5 }} /> Discord</Col>
          <Col sm={8} className={styles.value} id="user-id">
            <div className="discord-button">
            <a href="https://discord.gg/FdxpTYwtrV" target="_blank" className="discord-button" rel="noreferrer">
              <img src="/discord-icon.png" height={15}></img>
              <span className="feedback-label">Discord</span>
            </a>
          </div>
      </Col>
        </Row>
        <hr />
        <Row className={styles.valuerow}>
          <Col sm={3} className={styles.label}><i className="bi bi-phone" style={{ marginRight: 5 }} /> Twitter</Col>
          <Col sm={8} className={styles.value} id="user-id"><a href="https://twitter.com/cs2lens?ref_src=twsrc%5Etfw" target="_blank" className="twitter-button" data-show-count="false" rel="noreferrer">
        <svg width={20} height={20} viewBox='0 0 72 72'><path fill='rgb(255,255,255)' d='M68.812 15.14a26.189 26.189 0 0 1-7.52 2.06 13.125 13.125 0 0 0 5.757-7.243 26.133 26.133 0 0 1-8.314 3.176A13.066 13.066 0 0 0 49.182 9c-7.23 0-13.092 5.86-13.092 13.093 0 1.026.118 2.02.338 2.98C25.543 24.527 15.9 19.318 9.44 11.396a13.057 13.057 0 0 0-1.77 6.58c0 4.543 2.312 8.552 5.824 10.9a13.05 13.05 0 0 1-5.93-1.64c-.002.056-.002.11-.002.163 0 6.345 4.513 11.638 10.504 12.84-1.1.298-2.256.457-3.45.457-.845 0-1.666-.078-2.464-.23 1.667 5.2 6.5 8.985 12.23 9.09a26.29 26.29 0 0 1-16.26 5.605c-1.055 0-2.096-.06-3.122-.184a37.036 37.036 0 0 0 20.067 5.882c24.083 0 37.25-19.95 37.25-37.25 0-.565-.013-1.133-.038-1.693a26.61 26.61 0 0 0 6.532-6.774z'/></svg>
        <span className="feedback-label" style={{marginRight: 5}}>Follow</span>
      </a><script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script></Col>
        </Row>
        <Row className={styles.valuerow}>
          <Col sm={3} className={styles.label}><i className="bi bi-envelope" style={{ marginRight: 5 }} /> E-mail</Col>
          <Col sm={8} className={styles.value} id="team-name">support@csgolens.com</Col>
        </Row>
      </Container>
    </div>
  );
};

export default Support;
