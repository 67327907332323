import AnalysePlayer from "./AnalysePlayer";
import AnalyseTeamInfo from "./AnalyseTeamInfo";
import { FrameData, PlayerFrameData, RoundData, Side } from "../utils/types";

const AnalyseTeam = ({ roundData, teamKey, teamName, flag, currentTick, side }: {
  roundData: RoundData,
  teamKey: string,
  teamName: string,
  flag: string | undefined,
  currentTick: number,
  side: Side,
}) => {

  const startTick = roundData.frames[0].tick;
  const endTick = roundData.frames[roundData.frames.length - 1].tick;
  const tickStart: number = Math.max(...roundData.frames.map((f) => f.tick).filter((t) => t <= currentTick && t >= startTick && t < endTick));
  const frameStart: FrameData = roundData.frames.find((f) => f.tick === tickStart)!;
  const players = roundData.frames[0][side === Side.CT ? "ct" : "t"].players.sort((a, b) => a !== null && b !== null ? a.name.localeCompare(b.name) : 0)
  let teamBuy = side === Side.T ? roundData.tBuyType : roundData.ctBuyType;
  if(teamBuy === 'Full Eco') {
    teamBuy = 'Eco';
  } else if(teamBuy === 'Semi Eco') {
    teamBuy = 'Eco+';
  }

  if (frameStart !== undefined) {
    return (
      <div className="w-100">
        <AnalyseTeamInfo
          key={`team_info_${side}`}
          teamKey={teamKey}
          teamName={teamName}
          teamBuy={teamBuy}
          flag={flag ? flag : ""}
        />
        {players.map((player) => {
          const frameTeam = (side === Side.CT ? frameStart.ct : frameStart.t);
          const playerData = frameTeam.players.find((p: PlayerFrameData) => p.steamID === player.steamID);
          return playerData ? <div
            key={`teaminfo_player_wrapper_${player.steamID}`}
          >
            <AnalysePlayer key={`teaminfo_player_${player.steamID}`} data={playerData} side={side} />
          </div>: null;
        })}
      </div>
    )
  }
  return null;
};

export default AnalyseTeam;