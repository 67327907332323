import React, { useState, useCallback, useRef, useEffect } from 'react';

const parseMatrixValues = (matrixString: string): number[] => {
  // Regular expression to match all floating-point values in the string
  const regex = /([-+]?[0-9]*\.?[0-9]+)/g;
  const matches = matrixString.match(regex);

  if (matches) {
    // Convert all matched values to numbers
    return matches.map(value => parseFloat(value));
  }

  return [];
};

interface AnalyseRectProps {
  rect: number[] | undefined,
  rectChanged: (
    recordingRect: number[] | undefined,
    rectType: 'all' | 'landing' | undefined,
    dragStart: boolean,
    dragEnd: boolean,
  ) => void,
}

const AnalyseRectGizmo = ({
  rect,
  rectChanged,
}: AnalyseRectProps) => {
  const [dragging, setDragging] = useState(false);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [startPoint, setStartPoint] = useState({ x: 0, y: 0 });
  const svgRef = useRef<SVGSVGElement | null>(null);

  const getSVGPoint = (event: React.MouseEvent<SVGSVGElement> | MouseEvent) => {
    const svg = svgRef.current;
    if (!svg) return { x: 0, y: 0 };

    const isFirefox = typeof navigator !== 'undefined' && navigator.userAgent.toLowerCase().includes('firefox');
    const point = svg.createSVGPoint();
    point.x = event.clientX;
    point.y = event.clientY;

    let transformedPoint = point;

    const ctm = svg.getScreenCTM();
    if (ctm) {
      transformedPoint = point.matrixTransform(ctm.inverse());
    }

    // Firefox has a bug with getScreenCTM() (https://stackoverflow.com/questions/67549709/svg-getscreenctm-on-firefox)
    // This is totally horrible fix which looks at the grandparent matrix and approximately fixes the coords
    // Really sorry for the person who needs to maintain this code in the future
    if (isFirefox && svg.parentElement && svg.parentElement.parentElement && svg.parentElement.parentElement.parentElement) {
      const matrixString = svg.parentElement.parentElement.parentElement
        ? getComputedStyle(svg.parentElement.parentElement.parentElement).transform
        : 'none';
      const matrixValues = parseMatrixValues(matrixString);

      if (matrixValues && matrixValues.length === 6) {
        const scaleValue = matrixValues[0];
        const translateX = matrixValues[4];
        const translateY = matrixValues[5];
        if (!isNaN(scaleValue) && scaleValue !== 0) {
          transformedPoint.x -= translateX * 0.1;
          transformedPoint.y -= translateY * 0.1;
          transformedPoint.x = (transformedPoint.x - 50) / scaleValue + 50;
          transformedPoint.y = (transformedPoint.y - 50) / scaleValue + 50;
        }
      }
    }

    return transformedPoint;
  };

  const onKeyDown = useCallback((event: React.KeyboardEvent<SVGSVGElement>) => {
    if (event.key === 'Alt') {
      rectChanged(undefined, 'landing', false, false);
    }
  }, []);

  const onMouseDown = (event: React.MouseEvent<SVGSVGElement>) => {
    setIsMouseDown(true);
    const point = getSVGPoint(event);
    setStartPoint(point);
    const newRect = [
      point.x,
      point.y,
      point.x,
      point.y,
    ];
    rectChanged(newRect, event.altKey ? 'landing' : 'all', true, false);
  };

  const onMouseMove = (event: React.MouseEvent<SVGSVGElement>) => {
    if (!isMouseDown) return;

    setDragging(true);
    const point = getSVGPoint(event);
    const newRect = [
      Math.min(startPoint.x, point.x),
      Math.min(startPoint.y, point.y),
      Math.max(startPoint.x, point.x),
      Math.max(startPoint.y, point.y),
    ];

    rectChanged(newRect, event.altKey ? 'landing' : 'all', false, false);
  };

  const onMouseUp = (event: React.MouseEvent<SVGSVGElement>) => {
    if (!isMouseDown) return;
    setIsMouseDown(false);

    const point = getSVGPoint(event);
    const newRect = [
      Math.min(startPoint.x, point.x),
      Math.min(startPoint.y, point.y),
      Math.max(startPoint.x, point.x),
      Math.max(startPoint.y, point.y),
    ];

    const MIN_SIZE = 0.5;
    setDragging(false);

    // Calculate the size of the rectangle
    const width = Math.abs(newRect[2] - newRect[0]);
    const height = Math.abs(newRect[3] - newRect[1]);

    // Check if the rectangle is smaller than the minimum size
    if (width < MIN_SIZE || height < MIN_SIZE) {
      rectChanged(undefined, undefined, false, false); // Call rectChanged with undefined if too small
      event.stopPropagation();
    } else {
      rectChanged(newRect, event.altKey ? 'landing' : 'all', false, true);
    }
  };

  return (
    <>
      <svg ref={svgRef} onMouseDown={onMouseDown} onMouseMove={onMouseMove} onMouseUp={onMouseUp} onKeyDown={onKeyDown}>
        <rect
          x="0"
          y="0"
          width="100"
          height="100"
          fill="rgb(0,0,0,0)"
          style={{ cursor: 'crosshair', zIndex: 100 }}
        />
        {rect && dragging && <rect
          x={rect[0]}
          y={rect[1]}
          width={rect[2] - rect[0]}
          height={rect[3] - rect[1]}
          fill={dragging ? "rgb(160,255,160,0.065)" : "rgb(160,255,160,0.025)"}
          stroke={dragging ? "#aca" : "#888"}
          strokeWidth={0.15}
          strokeDasharray="0.5 0.5"
          style={{ cursor: 'crosshair' }}
        />}
        <style>
          {`
          .corner-rect:hover {
            fill: rgb(128, 128, 128);
          }
        `}
        </style>
      </svg>
    </>
  );
}

export default AnalyseRectGizmo;
