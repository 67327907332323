import { Col, Container, Row } from "react-bootstrap";
import SignInButton from "../components/SignInButton";
import styles from "./Checkout.module.css";

const StripeGumroadSuccess = () => {


  return (
    <div className={`container ${styles.checkoutContainer}`}>
      <Container fluid>
        <Row>
          <Col>
            <h1><i className="bi bi-check" style={{color: '#0d0'}}></i> Checkout complete?</h1>
          </Col>
        </Row>
        <hr />
        <Row className={styles.myrow}>
          <Col>
            <p>Once you've finished your payment, please sign in again to make sure your premium subcription still works and you are no longer requested to re-subscribe.</p>
          </Col>
        </Row>
        <Row className={styles.myrow}>
          <Col>
            <p>Don't hesitate to <a href="https://cs2lens.com/support">contact</a> us with any questions.</p>
          </Col>
        </Row>
        <Row className={styles.myrow}>
          <Col>
            <SignInButton onLogin={() => window.location.href = "/"} onForgotPassword={() => window.location.href = "/"} label={"Re-login"} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default StripeGumroadSuccess;