// import { mapInfo } from "../utils/maps";
// import { world_to_screen } from "../utils/math";
// import { KillData } from "../utils/types";


// const AnalyseSVGDeath = ({ data, currentTick, scaleFactor, mapName, upperView, invisible }: {
//   data: KillData,
//   currentTick: number,
//   scaleFactor: number,
//   mapName: string,
//   upperView: boolean,
//   invisible: boolean,
// }) => {

  
//   const position = world_to_screen([data.victimX, data.victimY, data.victimZ], mapName);
//   const mInfo = mapInfo[mapName as keyof typeof mapInfo];
//   const zOpacity = (data.victimZ - mInfo.minZ) / (mInfo.maxZ - mInfo.minZ);
//   const opacity = invisible ? 0.0 : Math.min(Math.max(upperView ? zOpacity : 1.0 - zOpacity, 0.05), 1.0);
//   let color = data.victimSide === "T" ? [255, 157, 16, opacity] : [17, 199, 256, opacity];

//   const r = 30.0 / scaleFactor;

//   return (
//     <>
//       <circle
//         transform={`translate(${position[0]}, ${position[1]})`}
//         r={r * 0.17}
//         strokeWidth={r * 0.04}
//         stroke={`rgb(${color[0]}, ${color[1]}, ${color[2]}, ${color[3]})`}
//         fill={invisible ? `rgb(${color[0]}, ${color[1]}, ${color[2]}, ${color[3]})` : 'none'}
//       />
//     </>
//   )
// };

// export default AnalyseSVGDeath;

import { useEffect, useRef, useState } from "react";
import { mapInfo } from "../utils/maps";
import { world_to_screen } from "../utils/math";
import { KillData } from "../utils/types";


const AnalyseSVGDeath = ({ data, currentTick, scaleFactor, mapName, hovered, playerName, upperView, invisible }: {
  data: KillData,
  currentTick: number,
  scaleFactor: number,
  mapName: string,
  hovered: boolean,
  playerName: string,
  upperView: boolean,
  invisible: boolean,
}) => {

  const [boxSize, setBoxSize] = useState({ width: 0, height: 0 });

  const textRef = useRef<SVGTextElement>(null);

  useEffect(() => {
    const computeBoundingBox = () => {
      if (textRef.current) {
        const bbox = textRef.current.getBBox();
        setBoxSize({ width: bbox.width + 10, height: bbox.height + 2 });
      }
    };
  
    // Use requestAnimationFrame to defer the computation
    // until after the next paint.
    const animationFrameId = requestAnimationFrame(computeBoundingBox);
  
    // Cleanup function to cancel the animation frame if it hasn't run yet
    return () => cancelAnimationFrame(animationFrameId);
  }, [hovered, playerName]); 
  
  const text = playerName;
  const position = world_to_screen([data.victimX, data.victimY, data.victimZ], mapName);
  const mInfo = mapInfo[mapName as keyof typeof mapInfo];
  const zOpacity = (data.victimZ - mInfo.minZ) / (mInfo.maxZ - mInfo.minZ);
  const opacity = invisible ? 0.0 : Math.min(Math.max(upperView ? zOpacity : 1.0 - zOpacity, 0.05), 1.0);
  let shadow = [0, 0, 0, 0.15 * opacity];
  let color = data.victimSide === "T" ? [255, 157, 16, opacity] : [17, 199, 256, opacity];
  let textColor = [0, 0, 0, 1];
  let bgColor = [color[0], color[1], color[2], 0.5];
  const r = 30.0 / scaleFactor;
  const size = 9.0 / scaleFactor;
  const borderRadius = size * 6;

  return (
    <>
       <circle
         transform={`translate(${position[0]}, ${position[1]})`}
         r={r * 0.17}
         strokeWidth={r * 0.04}
         stroke={`rgb(${color[0]}, ${color[1]}, ${color[2]}, ${color[3]})`}
         fill={invisible ? `rgb(${color[0]}, ${color[1]}, ${color[2]}, ${color[3]})` : 'none'}
       />
      {hovered &&
      <g transform={`translate(${position[0]}, ${position[1] - size * 2.6}) scale(${1 / scaleFactor})`}>
        <rect
          x={`${-boxSize.width / 2 + 3}`}
          y={`${-boxSize.height / 2 + 3}`}
          width={boxSize.width}
          height={boxSize.height}
          fill={`rgba(${shadow[0]}, ${shadow[1]}, ${shadow[2]}, ${shadow[3]})`}
          style={{ filter: "blur(2px)" }}
          rx={borderRadius}
          ry={borderRadius}
        />
        <rect
          x={`-${boxSize.width / 2}`}
          y={`-${boxSize.height / 2}`}
          width={boxSize.width}
          height={boxSize.height}
          fill={`rgba(${bgColor[0]}, ${bgColor[1]}, ${bgColor[2]}, ${bgColor[3]})`}
          rx={borderRadius}
          ry={borderRadius}
        />
        <text
          ref={textRef}
          dominantBaseline="middle"
          textAnchor="middle"
          fontSize={9.0}
          fill={`rgba(${textColor[0]}, ${textColor[1]}, ${textColor[2]}, ${textColor[3]})`}
          x={0}
          y={0.5}
        >
          {text}
        </text>
      </g>}
    </>
  )
};

export default AnalyseSVGDeath;