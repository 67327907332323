import { primaryWeaponsSvg, secondaryWeaponsSvg, utilityItemsSvg } from "../utils/weapons";
import { InventoryItem, PlayerFrameData, Side } from "../utils/types";

function compareInventory(a: InventoryItem, b: InventoryItem) {
  if (a.weaponName < b.weaponName) {
    return -1;
  }
  if (a.weaponName > b.weaponName) {
    return 1;
  }
  return 0;
}

const AnalysePlayer = ({ data, side }: {
  data: PlayerFrameData,
  side: Side,
}) => {
  const playerInfoDead = data.isAlive ? "" : "player-info-dead";
  const healthbarDead = data.isAlive ? "" : "healthbar-dead";
  const primaryWeapon = data.inventory ? data.inventory.find(item => item.weaponName in primaryWeaponsSvg)?.weaponName : undefined;
  const secondary = data.inventory ? data.inventory.find(item => item.weaponName in secondaryWeaponsSvg)?.weaponName : undefined;
  const weapon = primaryWeapon !== undefined ? primaryWeapon : secondary !== undefined ? secondary : undefined;
  const weaponSvg = weapon !== undefined ? (weapon in primaryWeaponsSvg ? primaryWeaponsSvg[weapon] : secondaryWeaponsSvg[weapon]) : undefined;
  const utilities = data.inventory ? data.inventory.sort(compareInventory).filter(item => item.weaponName in utilityItemsSvg) : [];

  return (
    <div className={`analyse-info player-info player-info-${side} ${playerInfoDead} rounded shadow`}>
      <div className="d-flex">
        <div className={'player-name flex-grow-1'}>{data.name}</div>
        <div className={data.isAlive ? `player-cash-${side}` : 'player-cash-dead'}>${data.cash}</div>
      </div>
      <div className={`healthbar healthbar-${side} ${healthbarDead}`}>
        <div className={`healthbar healthbar-bg-${side}`} style={{ width: `${data.hp}%` }}></div>
      </div>
      <div className={`armorbar healthbar-${side} ${healthbarDead}`}>
        <div className={`armorbar healthbar-bg-${side}`} style={{ width: `${data.armor}%` }}></div>
      </div>
      {/* <div className="d-flex">
        <div className={"player-primary-weapon flex-grow-1"}>{weaponSvg ?
          <img src={`${process.env.PUBLIC_URL}/items/${weaponSvg}`} alt={weapon} className={`weapon-icon weapon-icon-${side}`} /> :
          <div className={`weapon-icon weapon-icon-${side}`}></div>
        }
        </div>
        <div className={"flex-grow-1"}>
        </div>
        <div>
          {data.hasBomb ? <img src={`${process.env.PUBLIC_URL}/items/weapon_c4.svg`} alt={weapon} className={`weapon-icon weapon-icon-${side}`} /> : null}
          {data.hasDefuse ? <img src={`${process.env.PUBLIC_URL}/items/item_defuser.svg`} alt={weapon} className={`weapon-icon weapon-icon-${side}`} /> : null}
          {utilities.map((item, index) => {
            const totalAmmo = item.ammoInMagazine + item.ammoInReserve;
            const images = [];
            for (let i = 0; i < totalAmmo; i++) {
              images.push(
                <img
                  key={`${item.weaponName}-${i}`}
                  src={`${process.env.PUBLIC_URL}/items/${utilityItemsSvg[item.weaponName]}`}
                  alt={item.weaponName}
                  className={`weapon-icon weapon-icon-${side}`}
                />
              );
            }
            return images;
          })}
        </div>
      </div> */}
    </div>
  )
}

export default AnalysePlayer;